import { HiPencilSquare } from "react-icons/hi2";

export default function TableForSalesUser({ liquidations,setSelectedLiquidation }) {

  const handleShowSelectedLiquidation=(liquidation)=>{
      console.log('liquidation111',liquidation);
      console.log("liquidationStatus",liquidation?.status)
      setSelectedLiquidation(liquidation);
  }
  return (
    <div className="permissionsTable" style={{overflow:"auto"}}>
      <div className="header mt-2"style={{width:"100%", minWidth:"1000px"}}>  التصفيات السابقة</div>
      <table className='pt-2' style={{width:"100%", minWidth:"1000px"}}>
        <thead>
          <tr>
            <th> رقم التصفية </th>
            <th> تاريخ بداية التصفية </th>
            <th> تاريخ نهاية التصفية </th>
            <th> القيمة المستحقة </th>
            <th> الفائض </th>
            <th> استعراض </th>
          </tr>
        </thead>
        <tbody className="sacondBackground">
          {
            liquidations?.map((el, i) =>
              <tr key={i}>
                <td>{el?.liquidation_number} </td>
                <td>{el?.date_from?.split('T')[0]} </td>
                <td>{el?.date_to?.split('T')[0]} </td>
                <td>{el?.liquidation_balance_end <0 ? el?.liquidation_balance_end*-1 : "لا يوجد"}</td>
                <td>{el?.liquidation_balance_end >0 ? el?.liquidation_balance_end: "لا يوجد"}</td>
                <td>
                  <div
                  onClick={()=>handleShowSelectedLiquidation(el)} 
                  style={{ cursor: 'pointer', color: '#feaa00' }}
                  >
                    استعراض
                    <span>
                      <HiPencilSquare />{" "}
                    </span>
                  </div>

                </td>
              </tr>
            )
          }
        </tbody>

      </table>
    </div>
  )
}
