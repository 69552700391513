import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useInsertData } from "../../../hooks/useInsertData";
import { useUpdateData } from "../../../hooks/useUpdateData";

export const getAllBanks=createAsyncThunk(
    "/banksSlice/allbanks",
    async()=>{
        try {
            const response = await useGetDataToken("/api/v1/settings");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.message, "error");
        }
    }
);

export const addOneBank=createAsyncThunk(
    "/banksSlice/addbank",
    async({data})=>{
        try {
            const response = await useInsertData("/api/v1/settings", data);
            notify(" تم الاضافة بنجاح ", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.message, "error");
        }
    }
);

export const updateOneBank=createAsyncThunk(
    "/banksSlice/updatebank",
    async({id,data})=>{
        try {
            const response = await useUpdateData(`/api/v1/settings/${id}`, data);
            notify("  تم التعديل بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.message, "error");
        }
    }
);

export const getSmsLinkByID=createAsyncThunk(
    "/banksSlice/getSmsLinkByID",
    async(id)=>{
        try {
            const response=useGetDataToken(`/api/v1/settings/${id}`);

            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.message, "error");          
        }
    }
);