import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function ExportMemberShipModal({show,setShow}) {
    const{exportedMemberShip}=useSelector(state=>state.membersReducer);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Body>
        {/* {isLoading&&<Spinner />} */}
        <h5 className="h5ModalTitle">  تم تصدير العضوية بنجاح </h5>

        <p> رقم العضوية :
             {/* {exportedMemberShip?.membership_number}  */}
             {
                exportedMemberShip?.map((el,i)=><span key={i}>{el?.membership_number} {' , '}</span>)
             }
        </p>
          
    </Modal.Body>
    <Modal.Footer>
        <div className="w-100">
            <Button
                variant="primary"
                className="mx-auto d-flex"
                style={{ background: "rgb(0, 128, 251)" }}
                onClick={() => setShow(false)}
            >
                اغلاق
            </Button>
        </div>
    </Modal.Footer>
</Modal>
  )
}
