import { FaRegCalendar } from "react-icons/fa";
export default function PdfBox({color}) {
  const date=new Date();

  //console.log('dateeeeeeeeeeeee',date.toDateString());
  return (
    <div className="pdfBox" style={{color:color}}>
        {date.toISOString().split('T')[0]} <FaRegCalendar />
    </div>
  )
}
