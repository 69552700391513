import SideBar from "../../Components/Utilities/SideBar";
import EditLiquidationComponent from "../../Components/liquidation/edit/EditLiquidationComponent";

export default function EditLiquidationPage() {
  return (
    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-3 w-100 dashboard">
        <EditLiquidationComponent />
    </div>
  </div>
  )
}
