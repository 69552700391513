import { configureStore } from "@reduxjs/toolkit";

import usersSlice from "./slices/users/usersSlice";
import citiesSlice from "./slices/cities/citiesSlice";
import permissionsSlice from "./slices/permissions/permissionsSlice";
import offersSlice from "./slices/offers/offersSlice";
import banksSlice from "./slices/banks/banksSlice";
import ordersSlice from "./slices/orders/ordersSlice";
import membersSlice from "./slices/memberShips/membersSlice";
import depositesSlice from "./slices/deposites/depositesSlice";
import liquidationSlice from "./slices/liquidation/liquidationSlice";
import marketingSlice from "./slices/marketing/marketingSlice";
import screensSlice from "./slices/screens/screensSlice";
import walletSlice from "./slices/wallet/walletSlice";
import homeSlice from "./slices/home/homeSlice";
import darkModeReducer from "./slices/darkModeSlice";
import withDrawsSlice from "./slices/withDraws/withDrwasSlice";
import paymentSlice from "./slices/payment/paymentSlice";

// withDrawsSlice

export const store = configureStore({
  reducer: {
    usersReducer: usersSlice,
    citiesReducer: citiesSlice,
    permissionsReducer: permissionsSlice,
    offersReducer: offersSlice,
    banksReducer: banksSlice,
    ordersReducer: ordersSlice,
    membersReducer: membersSlice,
    depositesReducer: depositesSlice,
    liquidationReducer: liquidationSlice,
    marketingReducer: marketingSlice,
    screensReducer: screensSlice,
    walletReducer: walletSlice,
    homeReducer: homeSlice,
    darkMode: darkModeReducer,
    withDrawsReducer:withDrawsSlice,
    paymentReducer:paymentSlice
  },
});
