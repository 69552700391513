import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCities,getAllCountries } from "../../redux/slices/cities/thunk";
import { getAllOffers } from "../../redux/slices/offers/thunk";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import notify from "../useNotification";
import { addOneOrder,updateOrder } from "../../redux/slices/orders/thunk";

export default function AddOrEditOrderHook() {

    const dispatch=useDispatch();
    const location=useLocation();
    const navigate=useNavigate();
    const {id}=useParams();
    const [isSubmitting, setIsSubmitting] = useState(false); 


    console.log("location");
    console.log(location?.state);


    const [requestCardsArr, setRequestCardsArr] = useState(()=>id? location.state?.requestCard : []);
    const [selectedOffer,setSelectedOffer] = useState(
      ()=> id ? location.state?.offer  :0
    );
    const[selectedCity,setSelectedCity]=useState(()=>id ? location.state?.city?._id  :0);
    const[selectedCountry,setSelectedCountry]=useState(()=>id ? location.state?.country  :0);

    const user= JSON.parse(localStorage.getItem('user'));

    const oneUser=useSelector(state=>state.permissionsReducer.userScreens);


    const paymentType=[
        { _id:'cash',nameAr:'كاش' },
        {_id:'online',nameAr:'اون لاين'}
    ];

    useEffect(()=>{
        dispatch(getAllCountries());

        let query=``;
        if(user?.isAdmin) query+=`type=offer`;
        else{
            if(user?.userMarketer) query+=`type=commission&user=${user?.father_id}`;
            else
            query+=`type=commission&user=${user?._id}`;

        } 
  
        dispatch(getAllOffers(query,true));

        //dispatch(getAllOffers());
        console.log("location",location)
        if(location.state?.country){
            dispatch(getAllCities(location.state?.country?._id))
        }
    },[]);

    useEffect(()=>{
      if(oneUser?._id){
         dispatch(getAllCities(oneUser?.country?._id));
         setSelectedCountry(oneUser?.country?._id);
      }
    },[oneUser]);

    const{cities,countries}=useSelector(state=>state.citiesReducer);

    // const{offers}=useSelector(state=>state.offersReducer);
    let offers=[];
    const{offers : userOffers}=useSelector(state=>state.offersReducer);

    if(user?.isAdmin){
      offers=userOffers;
    }
    else{
      offers=userOffers?.map(el=>{
        return el?.offer
      });
    }

    console.log('userOffers',userOffers);
   // console.log('cities',cities);

    const initialValues={
        user: id? location.state?.user._id  :user?._id,
        user_take_comission: id ? location.state?.user_take_comission?._id :user?._id,
      //  country: id ? location.state?.country?._id  :0,
        city: id ? location.state?.city?._id  :0,
        address: id ? location.state?.address :'',
        payment_status: id ? location.state?.payment_status  :0
    };
    const requestCards = location?.state?.requestCard || [];

    const requestCard_id = requestCards.map(card => card?._id);
    console.log("location.state",requestCard_id)

    

    const formik = useFormik({
        initialValues
    });

    ///cities?country=663b4d30b0f36a4308a3ead4
    const handleCountryChange=async(e)=>{
         setSelectedCountry(e.target.value);
        await dispatch(getAllCities(e.target.value));
        //setSelectedCountry(e.target.value);
        //
      }

   
  //   console.log("offers",offers)
  // console.log("offers[0]?.credit_limit",creditLimit)
  // console.log("selectedOffer?.reducedAmount + offers[0]?.credit_limit",creditLimit + reducedAmount)
    
    const onSubmit=async()=>{
      
   

        for (let i = 0; i < requestCardsArr.length; i++) {
            if (requestCardsArr[i]?.mobile?.length != 10 && selectedCountry == "66c33878e67a6fe5f149fdec") {
              return notify("برجاء مراجعة رقم الجوال", "error");
            }
            console.log("xxxxxxxxxxxxxx",requestCardsArr[i]?.mobile?.length)
          }
        for (let i = 0; i < requestCardsArr.length; i++) {
            if (requestCardsArr[i]?.identity_number_passport?.length != 10 && selectedCountry == "66c33878e67a6fe5f149fdec") {
              return notify("يرجاء ادخال رقم الهوية او الجواز المكون من ١٠ ارقام  ","error");
            }
            console.log("identity_number_passport?.length",requestCardsArr[i]?.identity_number_passport?.length)
          }
        //console.log('xxxxxxxxxxxxxx');
        if(selectedOffer==0) return notify("من فضلك قم ب اختيار بطاقة","error");
        //if(requestCardsArr.length  !==selectedOffer?.numberOfCards) return notify('من فضلك ادخل بيانات البطاقات كاملة',"error");
        if(formik.values.payment_status==0) return notify("من فضلك قم ب اختيار طريقة الدفع","error");
        if(formik.values.city==0) return notify("من فضلك قم ب اختيار المدينة","error");
        let errorFlag=false;
        //validation to check that all cards are full
        requestCardsArr.map(el=>{
           let arr_keys= Object.keys(el);
            arr_keys.find((key)=>{
            if(key !== 'mobile2' && el[key]===''){
                 notify('من فضلك ادخل بيانات البطاقات كاملة',"error");
                    errorFlag=true;   
            } 
           })
        });

        if(errorFlag) return;
        let requestCardsArray=[
            ...requestCardsArr
        ];

        requestCardsArr[0]={...requestCardsArr[0],main_card:true};

        let offerToSend=[];

        if(user?.isAdmin){
          offerToSend=selectedOffer?._id;
        }
        else{
            offerToSend=userOffers?.find(el=>el?.offer?._id==selectedOffer?._id);
            offerToSend=offerToSend?._id;
        }

        console.log('offerToSend',offerToSend,offerToSend?._id);

        //return;
       
        const data={
            ...formik.values,
            requestCard:requestCardsArray,
            offer:offerToSend,
            country:selectedCountry
        }

        console.log("data",data)
        // return;

        setIsSubmitting(true);

        try {
          let response;
          // Check if you're updating or adding an order
          if (id) {
              response = await dispatch(updateOrder({ id, data }));
          } else {
              response = await dispatch(addOneOrder({ data }));
          }
  
          console.log("API response:", response); // Log the full response
  
          // Check if the request was fulfilled
          if (updateOrder.fulfilled.match(response) || addOneOrder.fulfilled.match(response)) {
              // Successful response handling
              setSelectedOffer(0);
              setSelectedCountry(0);
              setRequestCardsArr([]);
              formik.setValues(initialValues);
              navigate('/orders?requestStatus=new_request&page=1');
          } 
      } catch (error) {
          console.error('Failed to process order:', error);
          // notify(error.message || 'حدث خطأ حاول مرة اخرى', "error");
      } finally {
          setIsSubmitting(false); // Always reset submitting state
      }

       

    }

    console.log("selectedCountry",selectedCountry)
        
   // console.log("selectedOffer",selectedOffer);    

    return[
        paymentType,
        formik,
        countries,
        cities,
        offers,
        requestCardsArr,
        setRequestCardsArr,
        selectedOffer,
        setSelectedOffer,
        selectedCity,
        handleCountryChange,
        selectedCountry,
        setSelectedCountry,
        onSubmit,
        isSubmitting
    ];
}
