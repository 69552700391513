import OrdersHook from "../../hook/orders/OrdersHook";
import SearchSection from "./SearchSection";
import UsersHeader from "../Utilities/UsersHeader";
import OrdersTableComponent from "./OrdersTableComponent";
import ExportMemberShipModal from "./ExportMemberShipModal";
import Pagination from "../Utilities/Pagination";
import { useSearchParams } from "react-router-dom";
import { getAllOrdersWithPagination } from "../../redux/slices/orders/thunk";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";
import { getOneUser } from "../../redux/slices/users/thunk";
import { Spinner as BootstrapSpinner } from "react-bootstrap"; // Import Bootstrap Spinner

export default function AllOrdersComponent() {
  const [
    orders,
    transferMemberShip,
    showExportMemberShipModal,
    setShowExportMemberShipModal,
    pageCount,
    setPageCount,
    loading,
  ] = OrdersHook();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [pageCount, setPageCount] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  console.log("showExportMemberShipModal", showExportMemberShipModal);
  useEffect(() => {
    dispatch(getOneUser({ id: loggedUser?._id }));
  }, [showExportMemberShipModal]);

  const handlePrint = () => {
    // console.log("11111111111111");
    const table = document.getElementById("orderTable");
    if (table) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write(`<style>
              /* Add your CSS styles here */
              body {
                font-family: Arial, sans-serif;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
              }
              th {
                background-color: #f2f2f2;
                border: 1px solid #dddddd;
                padding: 8px;
                text-align: left;
              }
              td {
                border: 1px solid #dddddd;
                padding: 8px;
                text-align: left;
              }
              tr:nth-child(even) {
                background-color: #f9f9f9;
              }
            </style>`);
      printWindow.document.write("</head><body>");
      printWindow.document.write("<table>");
      printWindow.document.write(table.innerHTML);
      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };

  const handleDownload = () => {
    // Construct CSV content
    let csvContent =
      "رقم الطلب,اسم المندوب,العرض,حاله الدفع,حاله الدفع,تاريخ الطلب\n";
    orders["data"].forEach((deposite) => {
      csvContent += `${deposite?.serialNumber},${deposite?.user.fullname},${
        deposite?.offer?.offerNameAr
      },${deposite?.payment_status},${deposite?.request_status},${
        deposite?.createdAt?.split("T")[0]
      }\n`;
    });

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create an <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "order.csv";

    // Programmatically click the <a> element to trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  const nextPage = async (page) => {
    let next_page = page;
    if (page == 0) return;
    if (searchParams.get("page") == null) {
      //console.log('pppppppppppppppppp')
      searchParams.set("page", next_page);
      setSearchParams(searchParams);
    } else {
      //  page = searchParams.get('page');
      searchParams.set("page", next_page);
      setSearchParams(searchParams);
    }
    let query = `page=${page}`;
    //requestStatus=${searchParams.get('requestStatus')}
    query += `&requestStatus=${searchParams.get("requestStatus")}&`;
    if (user?.liquidation_user || user?.userMarketer)
      query += `user=${user?._id}`;

    if (searchParams.get("startDate") !== null)
      query += `&startDate=${searchParams.get("startDate")}`;
    if (searchParams.get("endDate") !== null)
      query += `&endDate=${searchParams.get("endDate")}`;

    // if(user?.userMarketer){
    //     query+=`user=${user?.father_id}`;
    //     // document.getElementsByClassName('addButton')[0].display='none';
    // }
    dispatch(getAllOrdersWithPagination(query));
  };

  const screenID = "665f0e4d70673dba321d1600";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let hasEdit = false;
  let hasAdd = false;
  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, "edit");
    hasAdd = CheckForPermissionTypes(screenID, "add");
  } else {
    hasEdit = true;
    hasAdd = true;
  }

  console.log("hasEdit", hasEdit);
  console.log("hasAdd", hasAdd);

  console.log("orders", orders);

  // if(!loggedUser?.isAdmin)CheckForPermission(screenID);

  // let access = false;
  // if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);

  return (
    <div>
      {showExportMemberShipModal && (
        <ExportMemberShipModal
          show={showExportMemberShipModal}
          setShow={setShowExportMemberShipModal}
        />
      )}
      <div className="my-3 px-1 py-3">
        {
          // hasAdd != undefined && !loggedUser?.isAdmin &&!loggedUser?.is_accountant &&
          <UsersHeader
            hasAdd={hasAdd}
            title={"اضافة طلب جديد"}
            currentRoute={"الطلبات"}
            navigateTo={"/orders/add"}
          />
        }
      </div>

      <div className="permissionsLayout mt-3">
        <SearchSection
          handleDownload={handleDownload}
          handlePrint={handlePrint}
        />
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <BootstrapSpinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </BootstrapSpinner>
          </div>
        ) : (
          <OrdersTableComponent
            orders={orders}
            transferMemberShip={transferMemberShip}
            hasEdit={hasEdit}
            hasAdd={hasAdd}
          />
        )}
        <div>
          <Pagination onPress={nextPage} pageCount={orders?.num_of_pages} />
        </div>
      </div>
    </div>
  );
}
