import SideBar from "../../Components/Utilities/SideBar";
import EditPermissionsComponent from "../../Components/permissions/EditPermissionsComponent";

export default function EditPermissionsPage() {
  return (
    <div className="d-flex justify-content-start">
        <div>
            <SideBar />
        </div>
        <div className="p-3 w-100 dashboard">
            <EditPermissionsComponent />
        </div>
    </div>
  )
}
