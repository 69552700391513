import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { renewMemberShip, resetVerifiedMemberShip, verifyMemberShip } from "../../../redux/slices/memberShips/thunk";
import { FaCaretDown } from 'react-icons/fa';  // Ensure you import FaCaretDown if used
import notify from "../../../hook/useNotification";

export default function RenewMembershipModal({ showModal, setShowModal }) {
    const [number, setNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const dispatch = useDispatch();

    const { verifiedMemberShip, isLoading } = useSelector(state => state.membersReducer);

    // useEffect(() => {
    //     return () => dispatch(resetVerifiedMemberShip());
    // }, [dispatch]);

    const handleCheckMembership = async () => {
        if (!number || !idNumber) {
            return notify("يجب ادخال رقم الاثبات و رقم العضوية", "error");
        }
        const data = {type:"renew", membership_number:number, identity_number_passport:idNumber }; 
        await dispatch(verifyMemberShip({ data }));
    }

        const onSubmit = ()=>{
            const renew = async () => {
                const data = {
                    membership_number: verifiedMemberShip?.membership_number,
                    identity_number_passport: verifiedMemberShip?.identity_number_passport
                };
                await dispatch(renewMemberShip({ data }));
                setShowModal(false);
            }
    
            if (verifiedMemberShip?._id && !isLoading) {
                renew();
            }
        }

    const main_card_info = verifiedMemberShip?.membershipCards?.find(el => el.main_card === true);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleCheckMembership();
        }
      };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Body>
                <h5 className="h5ModalTitle">تجديد العضوية</h5>

                <div className="label">ادخل رقم العضوية</div>
                <input
                    placeholder="ادخل رقم العضوية"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    className="w-100"
                    onKeyPress={handleKeyPress}

                />
                <div className="label">ادخل رقم الاثبات</div>
                <input
                    placeholder="ادخل رقم الاثبات"
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                    className="w-100"
                    onKeyPress={handleKeyPress}

                />

                {verifiedMemberShip && (
                    <div className="verify my-3">
                        <table className="verifiedTabel">
                            <thead>
                                <tr>
                                    <th>رقم العضوية</th>
                                    <th>العضوية الاساسية</th>
                                    <th>الهوية الاساسية</th>
                                    <th>البطاقات المرفقة</th>
                                    <th>تاريخ الانتهاء</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{verifiedMemberShip?.membership_number}</td>
                                    <td>{main_card_info?.name}</td>
                                    <td>{main_card_info?.identity_number_passport}</td>
                                    <td>{verifiedMemberShip?.membershipCards.length} <span className="mx-1" style={{ color: 'rgba(121, 121, 129, 1)' }}><FaCaretDown size={25} /></span></td>
                                    <td>{verifiedMemberShip?.end_date.split('T')[0]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-center border-0">
                
                {!verifiedMemberShip &&(
                    <Button
                    onClick={handleCheckMembership}
                    className="w-25"
                    variant="primary"
                    style={{ backgroundColor: "#0080FB" }}

                >
                    {isLoading ? <Spinner animation="border" size="sm" /> : 'تحقق'}
                </Button>
                )}
                    
                    {/* {verifiedMemberShip && !isLoading && (
                    <Button
                        onClick={() => onSubmit()}
                        className="w-25"
                        variant="success"
                        style={{ backgroundColor: "#28a745" }}
                    >
                        تجديد
                    </Button>
                )} */}
            </Modal.Footer>
        </Modal>
    );
}
