import { useNavigate, useSearchParams } from "react-router-dom";
import add from "../../../Images/add.png";
import add_red from "../../../Images/add_red.png";

export default function SelectBankOrDepositeBtn() {
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate=useNavigate();

    console.log('');
    
  return (
    <div  style={{ marginTop: '22px' }}>
        <div className="d-flex mt-3 responsiveMobileBankBtn" style={{ gap: '10px' }}>
          <button 
          onClick={()=>{
            searchParams.set('section','Bank');
            setSearchParams(searchParams);
         //window.location.href='/setting/banks?section=Bank';

          }}
          className={`add_bank_btn ${searchParams.get('section') == 'Bank' ? 'add_bank_btn_active' : ''}`} 
          >
            اضافة نوع بنك جديد

              <img
              src={ searchParams.get('section') == 'Bank' ?add :add_red}
              alt=""
              style={{ cursor: "pointer", marginRight: "8px", borderColor: 'black', marginTop: 'auto' }}
            />
          </button>
          <button
          onClick={()=>{
            searchParams.set('section','Deposite');
            setSearchParams(searchParams);
           // navigate('/setting/banks?section=Deposite');
           // window.location.href='/setting/banks?section=Deposite';
          }} 
          className={`add_bank_btn ${searchParams.get('section') == 'Deposite' ? 'add_bank_btn_active':''}`}>
            اضافة نوع عملية ايداع جديدة
            <img
              src={searchParams.get('section') == 'Deposite' ? add :add_red}
              alt=""
              style={{ cursor: "pointer", marginRight: "8px", borderColor: 'black', marginTop: 'auto' }}
            />
            </button>
        </div>
      </div>
  )
}
