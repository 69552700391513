import React from 'react';

const SendOrderSuccessfully = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '20px'
  };

  const messageBoxStyle = {
    width: '100%',
    maxWidth: '500px',
    backgroundColor: '#fff',
    fontFamily: "'Cairo', sans-serif",
    fontSize: '1rem',
    fontWeight: '700',
    height: '200px',
    border: '2px solid #000',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: '#333'
  };


  const headingStyle = {
    marginBottom: '10px'
  };

  const paragraphStyle = {
    marginTop: '0',
    color: '#666'
  };

  return (
    <div style={containerStyle}>
      <div style={messageBoxStyle}>
        <h1 style={headingStyle}>ناسف لم يتم ارسال الطلب </h1>
        <p style={paragraphStyle}>Sorry, the request was not sent</p>
      </div>
    </div>
  );
};

export default SendOrderSuccessfully;
