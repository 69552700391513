import { Navigate } from "react-router-dom";
import AddAndEditCountry from "../../Components/AddAndEditCountry/AddAndEditCountry";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";


export default function AddAndEditCountryPage() {

  // 66b9b5355bfa66cb94a1d3bb
  const screenID="66b9b5355bfa66cb94a1d3bb";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access = true;

  if (access == false)
    return <Navigate to="/setting/profile?section=Profile" />;

  
  return (
    <div className="d-flex justify-content-start">
        <div>
            <SideBar />
        </div>
        <div className="p-3 w-100 dashboard" style={{overflow:"hidden"}}>
          <AddAndEditCountry />
        </div>
    </div>
  )
}
