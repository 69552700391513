import { createSlice } from "@reduxjs/toolkit";
import { addBalanceToWallet, getUserWalletInfo, resetWallet } from "./thunk";

const initialState={
    wallet:[]
};

const walletSlice=createSlice({
    initialState,
    name:"walletSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getUserWalletInfo.fulfilled,(state,action)=>{
            state.wallet=action?.payload;
        });
        builder.addCase(resetWallet.fulfilled,(state,action)=>{
            state.wallet=[];
        });
        builder.addCase(addBalanceToWallet.fulfilled,(state,action)=>{
            state.wallet=[...state.wallet,action?.payload]
        });
    }
});

export default walletSlice.reducer;