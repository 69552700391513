
export default function Box1Component({item}) {
  return (
    <div className="d-flex gap-4">
    <div className="d-flex flex-column">
      <div className="liquidation-details blue"> مجموع العضويات المصدرة المستحقة </div>
      <div className="addButton mt-3"> {item?.num_of_cards} بطاقة </div>
    </div>

    <div className="d-flex flex-column">
      <div className="liquidation-details green"> مجموع المبالغ المستحقة علي العضويات </div>
      <div className="activeButton w-100 h-100 mt-3" style={{alignContent:'center'}}> {item?.money_for_company } ريال </div>
    </div>

    <div className="d-flex flex-column w-25">
    <div className="liquidation-details yellow mx-auto"> مجموع مبالغ الإيداعات </div>
    <div className="startSearch w-100 h-100 mt-3" style={{alignContent:'center',borderRadius:'8px'}}> {item?.money_for_deposits} ريال </div>
    </div>

    <div className="d-flex flex-column">
    <div className="liquidation-details pink mx-auto"> عمولة المندوب </div>
    <div className="pinkButton w-100 h-100 mt-3" style={{alignContent:'center',borderRadius:'8px'}}> {item?.sales_commission} ريال </div>
    </div>

    </div>
  )
}
