
export default function CircleCheckBox({gap,title,value,onChange,name , disabled=false}) {
    console.log("value",value);
    return (
        <div className="d-flex" style={{gap:gap,padding:'2%'}}>
            <div className="checkboxlabel">{title}</div>
            <div>
            <label className="checkboxContainer">
                <input type="checkbox" 
                name={name}
                onChange={onChange}
                value={value}
                checked={value}
                disabled={disabled}
                 />
                <span className="checkmark"></span>
            </label>
            </div>
            
        </div>

    )
}
