import SideBar from "../../Components/Utilities/SideBar";
import Page2Component from "../../Components/Page2/Page2Component";
import HeadSection from "../../Components/Utilities/HeadSection";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import { Navigate } from "react-router-dom";
export default function HomePage() {
  const screenID = "665f0dd470673dba321d15ef";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access = true;



  if (access == false)
    return <Navigate to="/setting/profile?section=Profile" />;

  return (
    <div className="d-flex justify-content-start">
        <div>
            <SideBar />
        </div>
        <div className={`p-3 w-100 dashboard`} style={{overflow:"hidden"}}>
        <HeadSection label={"ملخص الشهر"} />
        <Page2Component />
      
        </div>
    </div>
  );
}
