import { useState } from "react";
import { Spinner } from "react-bootstrap";

export default function BtnGroup({
  onSubmit,
  handleClose,
  hasAdd,
  hasEdit,
  isLoading,
}) {
  console.log("isLoading", isLoading);
  const [type, setType] = useState("");

  return (
    <div className="d-flex justify-content-center mt-3" style={{ gap: "64px" }}>
      {hasAdd && (
        <button
          className="customButton save"
          disabled={isLoading}
          onClick={() => {
            setType("add");
            onSubmit();
          }}
        >
          {" "}
          {isLoading && type == "add" && <Spinner />} حفظ
        </button>
      )}
      {hasEdit && (
        <button
          className="customButton edit"
          disabled={isLoading}
          onClick={() => {
            setType("edit");
            onSubmit(true);
          }}
        >
          {isLoading && type == "edit" && <Spinner />} تعديل{" "}
        </button>
      )}
      <button className="customButton close" onClick={() => handleClose()}>
        اغلاق
      </button>
    </div>
  );
}
