import SideBar from "../../../Components/Utilities/SideBar";
import PersonalProfileComponent from "../../../Components/settings/profile/PersonalProfileComponent";

export default function ProfilePage() {
  return (
    <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-3 w-100  dashboard" style={{overflow:'hidden'}}>
               <PersonalProfileComponent />
            </div>
        </div>
  )
}
