import { Button, Modal } from "react-bootstrap"

export default function AddOfferModal({showModal,setShowModal,formik,offers,selectedOffer,setSelectedOffer,comission,setComission,handleAddRow,offersPanned}) {
    let offersWithOutBlocked=[];

    // console.log("offersPanned",offersPanned);

    if(offersPanned.length==0) offersWithOutBlocked=offers;
    else
        offersWithOutBlocked=offers?.filter(
            el=> !offersPanned?.includes(el?._id)
        );
    
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} >
    <Modal.Body>
      <h5 className="h5ModalTitle">اسعار عمولة العروض للمستخدم</h5>
      <div className="label"> العروض</div>
      <select className="w-100" value={selectedOffer?._id ? selectedOffer?._id : 0} onChange={(e) => {
        setSelectedOffer(offers?.find(item => item?._id === e.target.value))

      }}>
        <option value="0">اختر العرض</option>
        {
          offersWithOutBlocked?.map((el, i) => <option key={i} value={el?._id}> {el?.offerNameAr} </option>)
        }

      </select>

      <div className="label"> العمولة</div>
      <input value={comission} onChange={(e) => setComission(e.target.value)} className="w-100" placeholder="اختار العمولة" />
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-center border-0">
      <Button onClick={() => handleAddRow()} variant="primary" style={{ backgroundColor: "#0080FB" }}>
        حفظ التغييرات
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
