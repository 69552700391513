import { FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllUsers, updateOneUser, getOneUser } from "../../redux/slices/users/thunk";
import MultiSelect from "./MultiSelect";
import CheckForPermission from "./CheckForPermission";
import notify from "../../hook/useNotification";
import { useEffect, useState } from "react";
import LoadingDots from '../Utilities/LoadingDots'


export default function TableItemComponent({ user, permissionGroups }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [detailedUser, setDetailedUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state



  console.log("detailedUser",detailedUser)

  const screenID = "665f0e6f70673dba321d1606";
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  
  console.log("user",user)

  if (!loggedUser?.isAdmin) CheckForPermission(screenID, "edit");

  let haveEdit = !loggedUser?.isAdmin
    ? CheckForPermission(screenID, "edit")
    : true;

  if (loggedUser?.userMarketer) haveEdit = false;
  //  (loggedUser.liquidation_user || loggedUser.isAdmin)

  let haveShowDetails = !loggedUser?.isAdmin
    ? CheckForPermission(screenID, "show details")
    : true;

  console.log("haveShowDetails", haveShowDetails);

  const handleUpdateStatus = async () => {
    try {
      await dispatch(
        updateOneUser({
          id: user?._id,
          data: {
            status: !user?.status,
          },
        })
      );

      // let query = '?page=1';
      // // مندوب
      // if (loggedUser.liquidation_user) query += `&father_id=${loggedUser._id}`;
      // // مسوق
      // if (loggedUser.userMarketer) query += `&father_id=${loggedUser.father_id}`;

      // searchParams.set('page', 1);
      // setSearchParams(searchParams);

      // await dispatch(getAllUsers(query));


    } catch (error) {
      console.error(error.message);
    }
  };


  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user?._id) {
        setLoading(true);
        const result = await dispatch(getOneUser({ id: user._id })); // Pass the ID correctly
        if (result.meta.requestStatus === 'fulfilled') { // Check if the action was fulfilled
          setDetailedUser(result.payload); // Assuming result.payload contains the user details
        }
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [dispatch, user?._id]);


  return (
    <div className="searchBar" style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
      <div
        className="d-flex flex-column"
        style={{ width: "85px", marginLeft: "16px", gap: "16px" }}
      >
        <div>رقم المعرف</div>
        <input
          // style={{ height: "40px" }}
          value={user?.serialNumber}
          placeholder="رقم المعرف"
          readOnly
          className="inputsReduisAndPadding2 font"
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{ width: "189px", marginLeft: "16px", gap: "16px" }}
      >
        <div>الاسم الثلاثي</div>
        <input
          // style={{ height: "40px" }}
          value={user?.fullname}
          placeholder="الاسم الثلاثي"
          className="inputsReduisAndPadding2 font"
          readOnly
          style={{borderRadius:"4px"}}
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{ width: "165px", marginLeft: "16px", gap: "16px" }}
      >
        <div>البريد الالكتروني</div>
        <input
          // style={{ height: "40px" }}
          value={user?.email}
          placeholder="البريد الالكتروني"
          className="inputsReduisAndPadding2 font"
          readOnly
          style={{borderRadius:"4px"}}
        />
      </div>
      <div className="d-flex flex-column" style={{ width: "200px", marginLeft: "16px", gap: "16px" }}>
        <div>الصلاحيات</div>
        {loading ? ( // Conditional rendering for loading state
          <div style={{ display: "flex", alignItems: "center" }}>
            <LoadingDots />
          </div>
        ) : (
          <input
            value={detailedUser?.permissions?.length > 0 
              ? detailedUser.permissions.map(permission => permission?.nameAr).join(', ') 
              : "لا يوجد"}
            placeholder="الصلاحيات"
            className="inputsReduisAndPadding2 font"
            readOnly
            style={{ borderRadius: "4px", overflow: "auto" }}
          />
        )}
      </div>
{/* 
      <div
        className="d-flex flex-column"
        style={{ width: "auto", marginLeft: "16px", gap: "16px", overflow:"auto", minWidth:"270px" }}
      >
        <MultiSelect
          labelForOption={'nameAr'}
          label={"الصلاحيات"}
          selectedOptionsIDS={user?.permissions}
          width="auto"
          options={permissionGroups}
          disabled={true}
          className={"inputsReduisAndPadding2 font"}
          />
          {          console.log("user?.permissions",user?.permissions)}
      </div> */}

      <div style={{ width: "130px", marginLeft: "16px", gap: "16px" }}>
        <div>حالة الحساب</div>
        {
          <button
            onClick={() => handleUpdateStatus()}
            className={`activeButton ${user?.status ? "" : "blocked"} membersBtnsWithHeight2 font`}
            disabled={!haveEdit}
            
          >
            {user?.status ? "نشط" : "غير نشط"}
          </button>
        }
      </div>
      {
        (loggedUser.liquidation_user || loggedUser.isAdmin) && <div style={{ width: "85px", gap: "16px" }}>
          <div className="text-center">استعراض</div>
          <div
            className="d-flex justify-content-center"
            style={{ height: "47%", marginTop: "15px", cursor: "pointer" }}
          >
            <FaEye
              onClick={() => {
                if (!haveShowDetails)
                  return notify(
                    "هذا المستخدم لا يملك الصلاحية لدخول هذه الصفحة",
                    "error"
                  );

                //console.log('jjjjjjjjjjjjjjjj');
                //console.log(user);
                navigate(`edit/${user?._id}`, { state: user });
              }}
              color="rgba(249, 32, 122, 1)"
              size={35}
            />
          </div>
        </div>
      }

    </div>
  );
}
