import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";

//get countries
export const getHomeStatistics = createAsyncThunk(
  "/homeSlice/home",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/home?${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
      else return error;
    }
  }
);

export const getHomeStatisticsOffers = createAsyncThunk(
  "/homeSlice/homeOffers",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/home/salestoday?${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
      else return error;
    }
  }
);

export const getHomeStatisticsChart = createAsyncThunk(
  "/homeSlice/homeCharts",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/home/charts?${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
      else   return notify(error.message, "error");
    }
  }
);
