import { useDispatch, useSelector } from "react-redux";
import CommissionrepoBtns from "../CommisonBtns/CommissionrepoBtns";
import CommissionrepoSearch from "../CommissionRepoSearch/CommissionrepoSearch";
import HeadSection from "../Utilities/HeadSection";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { useEffect, useState } from "react";
import { getMarketerReport } from "../../redux/slices/marketing/thunk";
import { useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default function ReportPageComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  //  /api/v1/reports?marketing_link_number=58354208
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  let label = loggedInUser ? "تقرير العمولة للمستخدم" : "تقرير العمولة للمسوق";

  useEffect(() => {
    const query = `marketing_link_number=${searchParams.get(
      "marketing_link_number"
    )}`;
    dispatch(getMarketerReport(query));
  }, []);

  const { report } = useSelector((state) => state.marketingReducer);

  console.log("report", report);
  const handleDownload = () => {
    // Prepare data as an array of objects
    const data = [
      {
        "اسم المسوق": report?.marketer_name,
        "كود التسويق": report?.marketing_link_number,
        "الطلبات المصدره": report?.requests_became_membership,
        "مجموع المبيعات": report?.sum_of_sales,
        "اجمالي العمولة": report?.due_commission,
      },
    ];

    // Create a new workbook and add the data as a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "reports");

    // Generate Excel file and save
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array", // Change to "array" for correct buffer type
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "reports.xlsx");
  };

  return (
    <div>
      <HeadSection label={label} />
      <div className="d-flex  justify-content-end firstContainer  my-4">
        <PrintAndDownload onDownload={handleDownload} />
      </div>
      <CommissionrepoBtns />
      <div className="p-4 w-100 dashboard" style={{ overflow: "hidden" }}>
        <CommissionrepoSearch report={report} />
      </div>
    </div>
  );
}
