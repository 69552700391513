import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { editOneLiquidation } from "../../redux/slices/liquidation/thunk";
import { useDispatch } from "react-redux";
import notify from "../../hook/useNotification";


export default function EditDateModal({ editItem,setEditItem, showModal, setShowModal }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const dispatch=useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));

    const handleUpdateDate=async()=>{
        if(startDate=='' || endDate=='') return notify('من فضلك ادخل تاريخ البداية والنهاية',"error");
        const data={
            date_from: startDate,
            date_to: endDate,
            user_id:user?._id
        };
        console.log(editItem?._id);
        await dispatch(editOneLiquidation({
            id:editItem?._id,
            data
        }));
        setStartDate('');
        setEndDate('');
        setEditItem(null);
        setShowModal(false);
    }

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Body>
                <div className="label text-center d-block"> تعديل </div>
                <div className="d-flex flex-column">
                    <div className="title my-3">تاريخ بدء البحث</div>
                    <input
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="title"
                        type="date"
                        style={{ color: 'rgba(121, 121, 129, 1)' }} />
                </div>

                <div className="d-flex flex-column">
                    <div className="title my-3">تاريخ نهاية البحث</div>
                    <input
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="title"
                        type="date"
                        style={{ color: 'rgba(121, 121, 129, 1)' }} />
                </div>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-center border-0">
                <Button
                    onClick={() => handleUpdateDate()}
                    className="w-25"
                    variant="primary"
                    style={{ backgroundColor: "#0080FB" }}
                >
                    تم
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
