import { useLocation, useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
//import { useHistory } from "react-router-dom";

export default function CheckForPermission(screenID,type='') {
 // const navigate=useNavigate();
  //let history = useHistory();
    const user=JSON.parse(localStorage.getItem("user"));
    const userPermissions=useSelector(state=>state.permissionsReducer.userScreens);

    console.log('userPermissions',userPermissions);

    let pageGroup=userPermissions?.permissions?.find(el=>el?._id==screenID);
    console.log('pageGroupsssssssssssss',pageGroup);

    if(!userPermissions?._id) return;

    if(user?.isAdmin) return true;
  
    if(!pageGroup) {
      notify('هذا المستخدم لا يملك الصلاحية لدخول هذه الصفحة',"error");
      
     return false
    } 


    if(type=='') return true;

    const permission=pageGroup?.permissionTypes?.find(el=>el?.nameEn==type);

    if(permission?._id) return true
    else{
      return false;
    }


  //console.log('add',add);

    //return pageGroup;
}
