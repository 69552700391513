import React, { useState } from 'react'
import SearchComponentHeader from './SearchComponentHeader';
import SearchComponentFooter from "./SearchComponentFotter";
import { useLocation } from 'react-router-dom';

export default function SearchComponent({deposites,pageType}) {
    const location=useLocation();
   let archive_page;
   if(location.pathname == '/archive') archive_page=true;
   else archive_page=false;
    console.log('adeposites',deposites);
    
    //let archeived_deposites=deposites?.filter(el=>el.status_of_deposit==='archeived');

    
    return (
        <div className='mx-3'>

             <div>
                <SearchComponentHeader archive_page={archive_page} pageType={pageType} />
                <SearchComponentFooter archive_page={archive_page} deposites={deposites} />
            </div>
        </div>
    )
}
