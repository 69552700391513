import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { getAllUsers, getUserSummary } from "../../redux/slices/users/thunk";
import style from "./usersummary.module.css";
import notify from "../../hook/useNotification";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Spinner } from "react-bootstrap";
import { resetUserSummary } from "../../redux/slices/users/usersSlice";

const UserSummary = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUser, setSelectedUser] = useState("0");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  let query = `user_id=${loggedUser._id}`;

  const userSummary = useSelector((state) => state.usersReducer.usersummary);
  //console.log('loggedUser', loggedUser._id)
  useEffect(() => {
    if (!loggedUser.isAdmin) dispatch(getUserSummary(query));
    dispatch(getAllUsers());
  }, []);

  const handleSearch = async () => {
    if (loggedUser?.isAdmin || loggedUser?.is_accountant) {
      if (selectedUser == "0") return notify("من فضلك اختر المستخدم", "error");
    }
    if (!startDate) return notify("من فضلك اختر تاريخ البدايه", "error");
    if (!endDate) return notify("من فضلك اختر تاريخ النهايه", "error");

    //let query = `user_id=${loggedUser._id}`;
    let query = ``;
    if (loggedUser?.isAdmin || loggedUser?.is_accountant)
      query += `user_id=${selectedUser}`;
    else query += `user_id=${loggedUser._id}`;

    query += `&date_from=${startDate}&date_to=${endDate}`;
    setIsLoading(true);
    await dispatch(getUserSummary(query));
    setIsLoading(false);
    //console.log('query', query)
  };
  const cancelSearch = async () => {
    setStartDate("");
    setEndDate("");
    setSelectedUser("0");
    dispatch(resetUserSummary()); // Reset usersummary to zero values
  };

  const {
    sumOfCards,
    money_for_company,
    priceOfDeposits,
    sumOfSalesCommission,
  } = userSummary;
  //console.log(sumOfCards);
  let users = useSelector((state) => state.usersReducer.users);

  console.log("users", users);

  users = users?.filter((el) => el.liquidation_user && !el.isAdmin);

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");

    const safePrint = (value) => (isNaN(value) || value === null ? 0 : value);

    printWindow.document.write(`
      <html>
        <head>
          <title>Print User Summary</title>
          <style>
            body {
              direction: rtl;
              font-family: 'Arial', sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
            }
            table, th, td {
              border: 1px solid black;
            }
            th, td {
              padding: 8px;
              text-align: center;
            }
            th {
              background-color: #f2f2f2;
            }
            h1 {
              text-align: center;
            }
          </style>
        </head>
        <body>
          <h1>ملخص المستخدم</h1>
          <table>
            <tr>
              <th>مجموع العضويات المصدرة المستحقة</th>
              <th>مجموع المبالغ المستحقة على العضويات</th>
              <th>مجموع مبالغ الإيداعات</th>
              <th>عمولة المندوب على العضويات المصدرة خلال الفترة</th>
            </tr>
            <tr>
              <td>${safePrint(sumOfCards)} بطاقة</td>
              <td>${safePrint(money_for_company)} ريال</td>
              <td>${safePrint(priceOfDeposits)} ريال</td>
              <td>${safePrint(sumOfSalesCommission)} ريال</td>
            </tr>
          </table>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };
  const handleDownload = () => {
    const safePrint = (value) => (isNaN(value) || value === null ? 0 : value);

    // Retrieve the user's full name from the loggedUser object
    const userFullName =
      selectedUser != "0" ? selectedUser.fullname : loggedUser?.fullname;

    // Create a container with the table to download
    const tableHTML = `
      <div>
        <h1 style="text-align: center;">ملخص المستخدم</h1>
        ${
          userFullName
            ? `<h2 style="text-align: center;">اسم المستخدم: ${userFullName}</h2>`
            : ""
        }
        <table style="width: 100%; border-collapse: collapse; margin: 20px 0; direction: rtl; font-family: 'Arial', sans-serif;">
          <thead>
            <tr>
              <th style="border: 1px solid black; padding: 8px; text-align: center; background-color: #f2f2f2;">مجموع العضويات المصدرة المستحقة</th>
              <th style="border: 1px solid black; padding: 8px; text-align: center; background-color: #f2f2f2;">مجموع المبالغ المستحقة على العضويات</th>
              <th style="border: 1px solid black; padding: 8px; text-align: center; background-color: #f2f2f2;">مجموع مبالغ الإيداعات</th>
              <th style="border: 1px solid black; padding: 8px; text-align: center; background-color: #f2f2f2;">عمولة المندوب على العضويات المصدرة خلال الفترة</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid black; padding: 8px; text-align: center;">${safePrint(
                sumOfCards
              )} بطاقة</td>
              <td style="border: 1px solid black; padding: 8px; text-align: center;">${safePrint(
                money_for_company
              )} ريال</td>
              <td style="border: 1px solid black; padding: 8px; text-align: center;">${safePrint(
                priceOfDeposits
              )} ريال</td>
              <td style="border: 1px solid black; padding: 8px; text-align: center;">${safePrint(
                sumOfSalesCommission
              )} ريال</td>
            </tr>
          </tbody>
        </table>
      </div>
    `;

    // Create a temporary DOM element to render the table for canvas conversion
    const container = document.createElement("div");
    container.innerHTML = tableHTML;
    document.body.appendChild(container);

    // Use html2canvas to convert the table to canvas
    html2canvas(container)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const marginLeft = 15; // Left margin
        const marginTop = 20; // Top margin
        const imgWidth = 210 - marginLeft * 2; // A4 width (210mm) minus left and right margins
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio of the image

        // Add the image to the PDF with margins
        pdf.addImage(
          imgData,
          "PNG",
          marginLeft,
          marginTop,
          imgWidth,
          imgHeight
        );
        pdf.save("user-summary.pdf"); // Save the PDF as 'user-summary.pdf'

        // Remove the temporary container
        document.body.removeChild(container);
      })
      .catch((error) => {
        console.error("Error generating download: ", error);
      });
  };

  return (
    <div id="summary-container" className={style.container}>
      <div className="d-flex justify-content-end my-4">
        <PrintAndDownload onPrint={handlePrint} onDownload={handleDownload} />
      </div>

      <div className={style.box2}>
        {(loggedUser?.isAdmin || loggedUser?.is_accountant) && (
          <div className={style.box2Child2}>
            <div className={`${style.text2} mx-1`}> اسم المستخدم </div>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              className="inputsReduisAndPadding2 custom-select"
            >
              <option value={"0"}> اختر المستخدم </option>
              {users?.map((el, i) => (
                <option key={i} value={el?._id}>
                  {" "}
                  {el?.fullname}{" "}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className={style.box2Child2}>
          <div className={`${style.text2} mx-1`}>من تاريخ</div>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={`inputsReduisAndPadding2 ${style.btn2}`}
          />
        </div>

        <div className={style.box2Child2}>
          <div className={`${style.text2} mx-1`}>إلى تاريخ</div>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={`inputsReduisAndPadding2 ${style.btn2}`}
          />
        </div>
        <div
          className={style.submit}
          onClick={() => handleSearch()}
          disabled={isLoading}
        >
          {" "}
          {isLoading && <Spinner />} فلترة
        </div>
        <div className={style.submit} onClick={() => cancelSearch()}>
          الغاء الفلترة
        </div>
      </div>

      <div className={style.line}></div>

      <div className={style.box4}>
        <div className={style.box4container}>
          <div className={style.box4Child}>
            <div className={style.box4Child1Prag}>
              مجموع العضويات المصدرة المستحقة
            </div>
            <div className={style.box4Child1Card}>
              <span>{sumOfCards ? sumOfCards : "0"}</span> <span>بطاقة</span>
            </div>
          </div>

          <div className={style.box4Child}>
            <div className={style.box4Child2Prag}>
              مجموع المبالغ المستحقة على العضويات
            </div>
            <div className={style.box4Child2Card}>
              <span>{money_for_company ? money_for_company : "0"}</span>{" "}
              <span>ريال</span>
            </div>
          </div>
        </div>
        <div className={style.box4container}>
          <div className={style.box4Child}>
            <div className={style.box4Child3Prag}>مجموع مبالغ الإيداعات</div>
            <div className={style.box4Child3Card}>
              <span>{priceOfDeposits ? priceOfDeposits : "0"}</span>{" "}
              <span>ريال</span>
            </div>
          </div>

          <div className={style.box4Child}>
            <div className={style.box4Child4Prag}>
              عمولة المندوب على العضويات المصدرة خلال الفترة
            </div>
            <div className={style.box4Child4Card}>
              <span>{sumOfSalesCommission ? sumOfSalesCommission : "0"}</span>{" "}
              <span>ريال</span>
            </div>
          </div>
        </div>
      </div>

      <div className={style.line}></div>
    </div>
  );
};

export default UserSummary;
