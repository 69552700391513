import { useDispatch, useSelector } from "react-redux";
import {
  renewMemberShip,
  resetVerifiedMemberShip,
} from "../../redux/slices/memberShips/thunk";
import { FaCaretDown } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "../../Components/Utilities/Select";
import Input from "../../Components/Utilities/Input";
import notify from "../../hook/useNotification";
import { Spinner } from "react-bootstrap";


export default function RenewMemberShipComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const { verifiedMemberShip, isLoading } = useSelector(
    (state) => state.membersReducer
  );
  const oneUser=useSelector(state=>state.permissionsReducer.userScreens);
  const oneUser2 = useSelector((state)=>state.usersReducer.oneUser)
  console.log("oneUser2",oneUser2)
  const user = JSON.parse(localStorage.getItem("user"));


  useEffect(() => {
    return () => dispatch(resetVerifiedMemberShip());
  }, [dispatch]);

  const reducedAmount = Number(verifiedMemberShip?.offer?.reducedAmount) || 0;
  const commissionAmount = Number(verifiedMemberShip?.offer?.commission) || 0;
  const creditLimit = Number(oneUser2?.father_id?.credit_limit ? oneUser2?.father_id?.credit_limit : oneUser2?.credit_limit) || 0;
  const deposits = Number(oneUser2?.father_id?.deposits ? oneUser2?.father_id?.deposits : oneUser2?.deposits) || 0;




  const onSubmit = () => {

//     if (isSubmitting) return; 
//     setIsSubmitting(true); 
  
//     let counter = 1;
//     if (counter > 1) {
//       setIsSubmitting(false);
//       return;
//     }
//     counter++;
// console.log("counter",counter)
// console.log("counterState",isSubmitting)
    

    const isUserAdmin = user?.isAdmin;
    const walletCreditLimit = oneUser2?.father_id ? oneUser2?.father_id?.wallet_credit_limit : oneUser2?.wallet_credit_limit;
    const totalAmount = reducedAmount - commissionAmount + creditLimit - deposits;
    console.log("trywalletCreditLimit",walletCreditLimit)
    if (!isUserAdmin && walletCreditLimit < totalAmount) {
      return notify("لا يمكن إتمام العملية لأنك وصلت إلى أقصى حد للائتمان المتاح لك", "error");
    }
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0'); // Add seconds

    
    const currentTime = `${hours}:${minutes}:${seconds}`; // Include seconds in the time format
    console.log(`Current Time: ${currentTime}`); // You can log or use this time as needed

    const renew = async () => {
      const data = {
        membership_number: main_card_info?.serial_number,
        identity_number_passport: verifiedMemberShip?.identity_number_passport,
        user:user?._id,
        time:currentTime,
        isRenew:true
      };
      setIsSubmitting(true)
      await dispatch(renewMemberShip({ data }));
      setIsSubmitting(false)

      //  setShowModal(false);
    };

    if (verifiedMemberShip?._id && !isLoading) {
      renew();

      setTimeout(() => {
        navigate("/members");
      }, [2000]);
    }
  };

  const main_card_info = verifiedMemberShip?.membershipCards?.find(
    (el) => el.main_card === true
  );
  console.log("verifiedMemberShip",verifiedMemberShip?.offer)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {verifiedMemberShip && (
        <div
          className="firstContainer my-3"
          style={{
            padding: "20px",
            background: "white",
            boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            // margin:"20px"
          }}
        >
          <div className="verify my-3" style={{ width: "100%" }}>
            {/* <table className="verifiedTabel">
            <thead>
              <tr>
                <th>رقم العضوية</th>
                <th>العضوية الاساسية</th>
                <th>الهوية الاساسية</th>
                <th>البطاقات المرفقة</th>
                <th>تاريخ الانتهاء</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{verifiedMemberShip?.membership_number}</td>
                <td>{main_card_info?.name}</td>
                <td>{main_card_info?.identity_number_passport}</td>
                <td>{verifiedMemberShip?.membershipCards.length} <span className="mx-1" style={{ color: 'rgba(121, 121, 129, 1)' }}><FaCaretDown size={25} /></span></td>
                <td>{verifiedMemberShip?.end_date.split('T')[0]}</td>
              </tr>
            </tbody>
          </table> */}
            <div
              className="my-3"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="label d-flex justify-content-start"
                style={{ width: "100%" }}
              >
                <label className="">
                  رقم العضويه : {verifiedMemberShip?.membership_number}
                </label>
              </div>
              <div className="d-flex label p-0 mx-auto">نوع العرض</div>
              <Input
                name={"offer"}
                classnam={"inputsReduisAndPadding2"}
                value={verifiedMemberShip?.offer?.offerNameAr}
                width={"100%"}
                labelSelect="offerNameAr"
                border={"1px solid rgba(132, 203, 0, 1)"}
              />
            </div>

            <div className="label d-flex justify-content-center">
              <label className="">
                رقم البطاقة الرئيسية : {main_card_info?.serial_number}
              </label>
            </div>

            <div
              className="inputContainer2"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className={"inputResponiveWidth"}>
                {" "}
                <Input
                  value={verifiedMemberShip?.name}
                  name={"name"}
                  label={"اسم صاحب الطلب"}
                  classnam={"inputsReduisAndPadding2"}
                  disabled
                />
              </div>
              <div className={"inputResponiveWidth"}>
                <Input
                  value={verifiedMemberShip?.identity_number_passport}
                  name={"identity_number_passport"}
                  label={"رقم الهوية او الجواز"}
                  classnam={"inputsReduisAndPadding2"}
                  disabled
                />
              </div>
            </div>

            <div
              className="inputContainer2"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className={"inputResponiveWidth"}>
                <Input
                  type="number"
                  value={verifiedMemberShip.mobile}
                  name={"mobile"}
                  label={"رقم الجوال"}
                  classnam={"inputsReduisAndPadding2"}
                  disabled

                  // width={"42%"}
                  // error={mobileError}
                />
              </div>
              <div className={"inputResponiveWidth"}>
                {/* <Select
              value={sendFrom}
              onChange={(e) => setSendFrom(e.target.value)}
              label={"مرسلة من"}
              options={sendFromUsers}
              defaultOption={"مرسلة من"}
              labelSelect="fullname"
              classnam={"inputsReduisAndPadding2"}

              // width={"42%"}
            /> */}

                <Input
                  type="text"
                  value={verifiedMemberShip?.sent_by}
                  name={"sentBy"}
                  label={"مرسلة من"}
                  classnam={"inputsReduisAndPadding2"}
                  disabled

                  // width={"42%"}
                  // error={mobileError}
                />
              </div>
            </div>
            <div
              className=" my-3"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "right",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <span className="d-flex label justify-content-end align-items-end p-0 mx-auto">
                {""}
                &nbsp;&nbsp;&nbsp;&nbsp; البلد
                {""}
              </span>

              <Input
                name={"country"}
                value={verifiedMemberShip?.city?.country?.nameAr}
                width={"100%"}
                labelSelect="nameAr"
                defaultOption="من فضلك قم ب اختيار البلد"
                border={"1px solid rgba(132, 203, 0, 1)"}
                classnam={"inputsReduisAndPadding2"}
                disabled
              />
            </div>

            <>
              <div
                className="my-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "right",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div className="d-flex label justify-content-end align-items-end p-0 mx-auto">
                  {" "}
                  المدينة{" "}
                </div>

                <Input
                  name={"city"}
                  value={verifiedMemberShip?.city?.nameAr}
                  width={"100%"}
                  labelSelect="nameAr"
                  defaultOption="من فضلك قم ب اختيار المدينة"
                  border={"1px solid rgba(132, 203, 0, 1)"}
                  classnam={"inputsReduisAndPadding2"}
                  disabled
                />
              </div>
            </>
          </div>
        </div>
      )}
      {verifiedMemberShip?._id && (
        <Button
          onClick={() => onSubmit()}
          className="w-25"
          variant="success"
          style={{ backgroundColor: "#28a745" }}
          disabled={isSubmitting}
        >
{isSubmitting &&  <Spinner />}
          تجديد
        </Button>
      )}
    </div>
  );
}
