
export default function SquareCheckBox({marginTop='-10px',marginLeft='12px',checked,onChange}) {
  return (
    <label className="container" style={{ marginTop: marginTop }}>
    <input
      //checked={selectedOptions?.includes(el?.nameAr)}
      //onChange={(e) => handleSelectedOption(e)}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      //value={el?._id}
      style={{ marginLeft: marginLeft }}
    />
    <span className="checkmark3 checkmark_orders mx-1"></span>
  </label> 
  )
}
