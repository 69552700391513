export default function BtnGroup({ handleDownload, handlePrint, main, data }) {
  console.log("data", data);
  console.log("---------", main);
  return (
    <div
      className="d-flex justify-content-between my-3 mx-3"
      style={{ flexWrap: "wrap", gap: "20px" }}
    >
      <button
        className="orders_search_btn orders_search_btn_active font"
        style={{ width: "175px" }}
      >
        {data?.main_card ? "العضوية الأساسية" : "العضوية الإضافية"}
      </button>
      <button className="addButton font" style={{ width: "175px" }}>
        {" "}
        حفظ{" "}
      </button>
      <button
        className="orders_search_btn orders_search_btn_active font"
        style={{ width: "175px" }}
        onClick={() =>
          handlePrint({
            name: data?.name,
            idNo: data?.identity_number_passport,
            date: main?.start_date.split("T")[0],
            membershipNo: data?.serial_number,
            expDate: main?.end_date.split("T")[0],
          })
        }
      >
        {" "}
        طباعة{" "}
      </button>
      <button
        className="activeButton font"
        style={{ height: "auto", width: "175px", marginTop: "0" }}
        onClick={() =>
          handleDownload({
            name: data?.name,
            idNo: data?.identity_number_passport,
            date: main?.start_date.split("T")[0],
            membershipNo: data?.serial_number,
            expDate: main?.end_date.split("T")[0],
          })
        }
      >
        {" "}
        تحميل{" "}
      </button>
    </div>
  );
}
