import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useUpdateData } from "../../../hooks/useUpdateData";
import { useInsertData } from "../../../hooks/useInsertData";

//***************permission groups actions */
//get all permission groups
export const getAllPermissionGroups=createAsyncThunk(
    "/permissionsSlice/allgroups",
    async()=>{
        try {
            const response=await useGetDataToken("/api/v1/groups");

            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//update permission group
export const updateOnePermissionGroup=createAsyncThunk(
    "/permissionsSlice/updateone",
    async({ id, data }, { rejectWithValue })=>{
        try {
            const response=await useUpdateData(`/api/v1/groups/${id}`,data);
            notify("تم التعديل بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//add permission group
export const addOnePermissionGroup=createAsyncThunk(
    "/permissionsSlice/addone",
    async({data})=>{
        try {
            const response=await useInsertData('/api/v1/groups', data);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);
//********************************************** */

//****************screens actions */
//get all screens
export const getAllScreens=createAsyncThunk(
    "/permissionsSlice/getallscreens",
    async()=>{
        try {
            const response=await useGetDataToken("/api/v1/screens");

            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//get all permissionTypes
export const getAllPermissionTypes=createAsyncThunk(
    "/permissionsSlice/getAllPermissionTypes",
    async()=>{
        try {
            const response=await useGetDataToken("/api/v1/permissionTypes");

            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//add group of permissions on screen (post)
export const addPermissionsToScreen=createAsyncThunk(
    "/permissionsSlice/addPermissionsToScreen",
    async({data})=>{
        try {
            const response=await useInsertData('/api/v1/groupPerm',data);
            notify("تم التعديل بنجاح", "success");

            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//
//get screens for one group
export const getScreensForOneGroup=createAsyncThunk(
    "/permissionsSlice/getScreensForOneGroup",
    async({id})=>{
        try {
            const response=await useGetDataToken(`/api/v1/groupPerm/group/${id}`);
            
            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

// get user screens
//  permissions/show/:id
// /api/v1/users/permissions/show/
export const getUserScreens=createAsyncThunk(
"/permissionsSlice/getUserScreens",
async(userID)=>{
    try {
        const response=await useGetDataToken(`/api/v1/users/permissions/show/${userID}`);
        return response;
    } catch (error) {
        if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
    }
}
);
