
export default function Box2Component({item}) {
  return (
    <div className="d-flex gap-5">
    <div className="d-flex flex-column">
      <p className="multislect_p"> مجموع مبلغ البطاقات المصدره </p>
      <p className="multislect_p text-center mx-auto w-100 d-block"> مجموع مبلغ الإيداعات </p>
    </div>

    <div className="d-flex flex-column">
    <p className="multislect_p d-block" style={{width:'160px'}}> {item?.money_for_company+item?.sales_commission} </p>
    <p className="multislect_p d-block" style={{width:'160px'}}> {item?.money_for_deposits} </p>
    </div>

    <div>
    <p className="multislect_p" style={{height:'124px'}}> مجموع المستحق علي اليوزر </p>
    </div>

    <div style={{width:'193px'}}>
    <p className="multislect_p d-block" style={{height:'124px',alignContent:'center'}}> {item?.liquidation_balance_end} ريال </p>
    </div>

  </div>

  )
}
