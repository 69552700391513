// darkModeSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Get initial state from local storage or default to false
const getInitialState = () => {
  const savedState = localStorage.getItem('darkMode');
  return savedState ? JSON.parse(savedState) : { isEnabled: false };
};

export const darkModeSlice = createSlice({
  name: "darkMode",
  initialState: getInitialState(),
  reducers: {
    toggleDarkMode: (state) => {
      state.isEnabled = !state.isEnabled;
      localStorage.setItem('darkMode', JSON.stringify(state)); // Persist state to local storage
    },
  },
});

export const { toggleDarkMode } = darkModeSlice.actions;
export default darkModeSlice.reducer;
