
export default function MemberDetailsCardItem({ label, data }) {
    return (
        <>
            <div className="d-flex my-2 mx-3 font" style={{ gap: '30px' }}>
                <div className="member-details-title font">{label}</div>
                <div className="member-details-title font">{data}</div>
            </div>

            <hr className="hr_line" />
            
        </>

    )
}

