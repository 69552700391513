import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useInsertData } from "../../../hooks/useInsertData";

export const getUserWalletInfo=createAsyncThunk(
    "/walletSlice/getUserWalletInfo",
    async(query)=>{
        try {
            const response=await useGetDataToken(`/api/v1/wallets?${query}`);

            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
                else
                return notify(error.response.data,"error");
        }
    }
);

export const addBalanceToWallet=createAsyncThunk(
    "/walletSlice/addBalanceToWallet",
    async({data})=>{
        try {
            const response=await useInsertData('/api/v1/wallets',data);
            notify(" تمت العملية بنجاح ","success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
                else
                return notify(error.response.data,"error");
        }
    }
);

export const resetWallet=createAsyncThunk(
    "/walletSlice/resetWallet",
    async()=>{
        try {
            const response=[];

            return response;
        } catch (error) {
            
        }
    }
);

// طلب سحب من المحفظة
export const sendWithdrawalRequest=createAsyncThunk(
    "/walletSlice/sendWithdrawalRequest",
    async({data})=>{
        try {
            console.log("dataXxxxxx",data)
            const response=await useInsertData("/api/v1/wallets/withdrawalRequest",data);

            notify("تم ارسال الطلب بنجاح","success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
                else if(error.response){

                    return notify(error.response.data.message,"error");          
                }
                else
                return notify("لم يتم ارسال الطلب حاول مرة اخري","error");          
        }
    }
);