import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getAllUsersWithPagination } from "../../redux/slices/users/thunk";
import { Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

export default function SearchBarComponent({
  selectedPermission, setSelectedPermission,
  fullName, setFullName,
  email, setEmail,
  status, setStatus,
  serialNumber, setSerialNumber,
  isLoading, setIsLoading
}) {
  // const [selectedPermission, setSelectedPermission] = useState(0);
  // const [fullName, setFullName] = useState('');
  // const [email, setEmail] = useState('');
  // const [status, setStatus] = useState(0);
  // const [serialNumber, setSerialNumber] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem('user'));

  const permissionGroups = useSelector(state => state.permissionsReducer.permissionGroups);

  //console.log('permissionGroups', permissionGroups);

  const search = async () => {
    let query = '?page=1';
    searchParams.set('page', 1);
    setSearchParams(searchParams);

    if (selectedPermission) query += `&permissions=${selectedPermission}`;
    if (fullName) query += `&fullname=${fullName}`;
    if (email) query += `&email=${email}`;
    if (status) query += `&status=${status}`;
    if (serialNumber) query += `&serialNumber=${serialNumber}`

    // مندوب
    if (loggedUser.liquidation_user) query += `&father_id=${loggedUser._id}`;
    // مسوق
    if (loggedUser.userMarketer) query += `&father_id=${loggedUser.father_id}`;

   // let page = 1;
   


    setIsLoading(true);
    await dispatch(getAllUsersWithPagination(query));
    setIsLoading(false);
  }

  const canclelFilter = async () => {
    setSelectedPermission(0);
    setFullName('');
    setEmail('');
    setStatus(0);
    setSerialNumber('');

    setIsLoading(true);
    let query = '?page=1';
    // مندوب
    if (loggedUser.liquidation_user) query += `&father_id=${loggedUser._id}`;
    // مسوق
    if (loggedUser.userMarketer) query += `&father_id=${loggedUser.father_id}`;

    searchParams.set('page', 1);
    setSearchParams(searchParams);

    await dispatch(getAllUsersWithPagination(query));

    setIsLoading(false);

  }
  console.log("selectedPermission",selectedPermission)

  return (
    <div className="searchBar" style={{display:"flex", justifyContent:"space-between"}}>
      {isLoading && <Spinner />}
      <div
        className="d-flex flex-column"
        style={{ marginLeft: "7px", gap: "7px" }}
      >
        <div className="font">رقم المعرف</div>
        <input value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} className="inputsReduisAndPadding2" placeholder="رقم المعرف" />
      </div>
      <div
        className="d-flex flex-column"
        style={{  marginLeft: "7px", gap: "7px" }}
      >
        <div className="font">الاسم الثلاثي</div>
        <input value={fullName} onChange={(e) => setFullName(e.target.value)} className="inputsReduisAndPadding2" placeholder="الاسم الثلاثي" />
      </div>
      <div
        className="d-flex flex-column"
        style={{  marginLeft: "7px", gap: "7px" }}
      >
        <div className="font">البريد الالكتروني</div>
        <input value={email} onChange={(e) => setEmail(e.target.value)} className="inputsReduisAndPadding2" placeholder="البريد الالكتروني" />
      </div>
      <div
        className="d-flex flex-column"
        style={{  marginLeft: "7px", gap: "7px" }}
      >
        <div className="font">الصلاحية</div>
        <select value={selectedPermission} onChange={(e) => setSelectedPermission(e.target.value)} className="inputsReduisAndPadding2">
          <option value={0}>  اختر الصلاحية </option>
          {permissionGroups?.map((el, i) =>
            <option key={i} value={el?._id}>{el?.nameAr} </option>
          )}
        </select>
      </div>
<div>
     <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      flexWrap: "wrap",
     }}>
     <div
        className="d-flex flex-column"
        style={{  marginLeft: "7px", gap: "7px" }}
      >
        <div className="font">حالة الحساب</div>
        <select value={status} onChange={(e) => setStatus(e.target.value)} style={{width:"120px"}} className="activeSearch inputsReduisAndPadding2">
          <option value={0}> الحالة </option>
          <option value={true}>نشط</option>
          <option value={false}>غير نشط</option>
        </select>
      </div>

      <div
        // style={{
        //   width:"120px",
        //   marginLeft: "10px",
        //   gap: "7px",
        //   height: "40px",
        // }}
      >
        <div className="hideTitle" style={{height:"41px"}}>...</div>
        <button onClick={() => search()} className="startSearch inputsReduisAndPadding2 addButton"
        style={{width:"120px"}} 
          // style={{
          //       width: "100px",
          //       height: "40px",
          // }}
          >بدء البحث</button>
      </div>
      <div className="d-flex flex-column gap-1" >
        <div className="hideTitle" style={{height:"41px"}}>...</div>
        <button onClick={() => canclelFilter()}
          className="addButton inputsReduisAndPadding2"  
          style={{
            // width: "100px",
            // height: "40px",
            marginLeft:"10px",
            width:"120px"
      }}
          > الغاء الفلتر </button>
      </div>

     </div>
    </div>
    </div>
  );
}
