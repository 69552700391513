import { useEffect, useState } from "react";
import style from "./form.module.css";

export default function RequestCardComponent({
  setRequestCardsArr,
  requestCardsArr,
  id,
  selectedOffer,
  address,
  setAddress,
}) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobile2, setMobile2] = useState("");
  const [passNum, setPassNum] = useState("");
  const [addMobile, setAddMobile] = useState(false); 

  //const [prevIterator, setPrevIterator] = useState(iteratorArr.length);

  // const[uniqueKey,setUniqueKey]=useState(id);
  function updateFieldsValues() {
    let obj = {
      name: name,
      identity_number_passport: passNum,
      mobile: mobile,
      mobile2: mobile2,
      uniqueKey: id,
    };

    //عشان الكارت الرئيسي
    if (id == 1000) obj.main_card = true;
    // console.log('uniqueKey',id);
    console.log("obj", obj);
    // let new_Arr=requestCardsArr.push(obj);
    // console.log('new_Arr',new_Arr);
    // setRequestCardsArr(new_Arr);
    let prev = requestCardsArr.find((el) => el.uniqueKey == id);
    if (prev) {
      console.log("prev");
      setRequestCardsArr((p) =>
        p.filter((el) => (el.uniqueKey == id ? "" : p))
      );
    }

    setRequestCardsArr((p) => [...p, obj]);
  }

  useEffect(() => {
    updateFieldsValues();
  }, [name, mobile, mobile2, passNum]);

  useEffect(() => {
    //  console.log('selectedOffer use effect');
    setName("");
    setMobile("");
    setMobile2("");
    setPassNum("");
    // setRequestCardsArr([]);
  }, [selectedOffer]);
  const AddAnotherMobile = () => {
    setAddMobile(true); // Update state when the button is clicked
  };


  return (
    requestCardsArr && (
      <>
        <div className="text-center">
          <label className={style.ItemLabel}>معلومات البطاقة  {id - 999}</label>
        </div>
        <div className={style.FormCardAdd}>
          <div className={style.ItemsContainer}>
            <label className={style.ItemLabel} htmlFor="name">
              الاسم
            </label>
            <input
              className={`inputsReduisAndPadding2 ${style.ItemInput}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              name="name"
              placeholder="ضع اسمك الثلاثي هنا"
            />
          </div>

        { id==1000 && <div className={style.ItemsContainer}>
            <label className={style.ItemLabel} htmlFor="id">
              رقم الهوية او الجواز او الاقامة
            </label>
            <input
              className={`inputsReduisAndPadding2 ${style.ItemInput}`}
              value={passNum}
              onChange={(e) => setPassNum(e.target.value)}
              type="number"
              name="id"
              placeholder="ضع رقم الهوية او الاقامة او جواز السفر"
            />
          </div>}
        </div>
        <div className={style.FormCardAdd}>
          {/* --------------------- */}
          <div className={style.FormCardAdd}>
            <div className={style.ItemsContainer}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
              <label className={style.ItemLabel} htmlFor="tel">
                رقم الجوال
              </label>
              <span
               style={{color:"#0080FB", textDecoration: "underline", cursor:"pointer", display:"flex", alignItems:"center"}}
               onClick={AddAnotherMobile}
              >
               إضافة جوال +
              </span>
              </div>
              <input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                className={`inputsReduisAndPadding2 ${style.ItemInput}`}
                type="number"
                name="tel"
                placeholder="اضف رقم جوالك الحالي"
              />
            </div>
            {/* ---------------- */}
          

          {id==1000 && <div className={style.ItemsContainer}>
             <label className={style.ItemLabel} htmlFor="address">
               العنوان
             </label>
             <input
               value={address}
               onChange={(e) => setAddress(e.target.value)}
               className={`inputsReduisAndPadding2 ${style.ItemInput}`}
               type="text"
               name="adress"
               placeholder="اضف عنوانك الحالي"
             />
           </div>}
           

           { id!=1000 && <div className={style.ItemsContainer}>
           <label className={style.ItemLabel} htmlFor="id">
             رقم الهوية او الجواز
           </label>
           <input
             className={`inputsReduisAndPadding2 ${style.ItemInput}`}
             value={passNum}
             onChange={(e) => setPassNum(e.target.value)}
             type="number"
             name="id"
             placeholder="ضع رقم الهوية او الاقامة او جواز السفر"
           />
         </div>}
         </div>

{/*  */}

          { addMobile && <div className={style.FormCardAdd}>

            <div className={style.ItemsContainer}>
              <label className={style.ItemLabel} htmlFor="tel">
                رقم الجوال
              </label>
              <input
                value={mobile2}
                onChange={(e) => setMobile2(e.target.value)}
                className={`inputsReduisAndPadding2 ${style.ItemInput}`}
                type="number"
                name="tel"
                maxLength={"10"}
                minLength={"8"}
                placeholder="اضف رقم جوالك الحالي"
              />
            </div>
            </div>}
            
         
        </div>
      </>
    )
  );
}
