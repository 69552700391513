import SideBar from "../../Components/Utilities/SideBar";
import AddAndEditOrderComponent from "../../Components/orders/AddAndEditOrderComponent";

export default function EditOrderPage() {
  return (
    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-3 w-100 dashboard">
        <AddAndEditOrderComponent />
    </div>

  </div>
  )
}
