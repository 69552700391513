import { useLocation, useSearchParams } from "react-router-dom";
import { getAllLiquidation } from "../../redux/slices/liquidation/thunk";
import { useDispatch } from "react-redux";

export default function LiquidationBtns() {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch=useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));

    
    const handleSearchParams = async (type) => {
        searchParams.set('liquidation-type', type);
        setSearchParams(searchParams);

        let query=``;
        // محاسب
        if(!user?.isAdmin&&!user?.liquidation_user) query+=`accountant_id=${user?._id}`;
        // مندوب
        else if(user?.liquidation_user) query+=`sales_id=${user?._id}`;

        if(user?.isAdmin) query='';
        
        dispatch(getAllLiquidation(query));
    }


  return (
    <div className="d-flex justify-content-center gap-3" style={{marginTop:"10px"}}>
    <button
         onClick={() => handleSearchParams('new')}
        className={`add_desposite_btn w-auto ${searchParams.get('liquidation-type')=='new'  ? 'add_bank_btn_active' : ''} `}
    >
        ارسال موعد التصفية
    </button>
    <button
         onClick={() => handleSearchParams('send')}
        className={`add_desposite_btn w-auto ${searchParams.get('liquidation-type')=='send'  ? 'add_bank_btn_active' : ''} `}
    >
        التصفيات الواردة
    </button>
</div>
  )
}
