import { createSlice } from "@reduxjs/toolkit";
import { addAgentToUser, getAllMarketers, getMarketerReport, getUserAgents, updateAgentUser } from "./thunk";

const initialState={
    userAgents:[],
    userAgentInfo:{},
    report:{}
};

const marketingSlice=createSlice({
    initialState,
    name:'marketingSlice',
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(addAgentToUser.fulfilled,(state,action)=>{
           if(action?.payload) state.userAgents=[...state.userAgents,action?.payload]
        });
        builder.addCase(getUserAgents.fulfilled,(state,action)=>{
            state.userAgents=action?.payload
        });
        builder.addCase(updateAgentUser.fulfilled,(state,action)=>{
            state.userAgents=state.userAgents?.map(el=>
                el?._id.toString()===action?.payload?._id.toString() ?
                {...el,...action.payload}
                :
                el
            )
        });
        builder.addCase(getAllMarketers.fulfilled,(state,action)=>{
            state.userAgentInfo=action.payload
        });
        builder.addCase(getMarketerReport.fulfilled,(state,action)=>{
            state.report=action?.payload;
        });
    }
});

export default marketingSlice.reducer;