import { FaEyeSlash } from "react-icons/fa";
import { IoEye } from "react-icons/io5";

export default function EyeButton({ type, setType , circle=false }) {
  return (
    <div className="d-flex flex-column" style={{borderRadius:"4px"}}>
      <div className="hideTitle label">...</div>
      <button
      style={{height:"45px"}}
      type="button"
        onClick={() => setType((p) => (p == "text" ? "password" : "text"))}
        className={`eyeButton ${circle ? 'circle' : ''}`}
      >
        {type == "password" ? (
          <FaEyeSlash color="rgba(121, 121, 129, 1)" className="eyeMobileWidth" />
        ) : (
          <IoEye color="rgba(121, 121, 129, 1)" className="eyeMobileWidth" />
        )}
      </button>
    </div>
  );
}
