import React, { useRef, useEffect, useState } from "react";
import { Chart } from "chart.js/auto";
import "chartjs-plugin-datalabels";
import styles from "./ChartComponent.module.css";

const ChartComponent = ({ statistics_offers }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (statistics_offers) {
      const labels = statistics_offers.slice(0, 4).map((item) => item.labelAr); // Only take first 4 items
      const data = statistics_offers
        .slice(0, 4)
        .map((item) => item.num_of_cards); // Only take first 4 items

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "البطاقات",
            data: data,
            backgroundColor: ["#2AAA8A", "#0088FF", "#FFCE56", "#FF6384"],
            borderWidth: 2,
          },
        ],
      });
    }
  }, [statistics_offers]);

  useEffect(() => {
    if (chartRef.current && chartData) {
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }

      const ctx = chartRef.current.getContext("2d");

      chartRef.current.chart = new Chart(ctx, {
        type: "doughnut",
        data: chartData,
        options: {
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
              color: "white",
              formatter: (value) => `${value}`,
              font: {
                family: "Cairo",
                weight: "bold",
              },
            },
          },
          cutout: "80%",
        },
      });
    }
  }, [chartData]);

  const summation = () => {
    if (!statistics_offers) return 0;
    return statistics_offers.reduce((sum, item) => sum + item.num_of_cards, 0); // Sum of num_of_paid
  };

  const legend = (
    <div className={styles.legend}>
      {statistics_offers.slice(0, 4).map((item, index) => (
        <div key={index} className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{
              backgroundColor: chartData
                ? chartData.datasets[0].backgroundColor[index]
                : "",
            }}
          ></span>
          <span>{item.labelAr}</span>
          <span className={styles.legendValue}>{item.num_of_paid}</span>{" "}
          {/* Display num_of_paid */}
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.ChartComponentMaster}>
      <div className={styles.ChartComponentContainer}>
        {legend}
        <div className={styles.ChartComponentTitle}>مبيعات اليوم حسب العرض</div>
        <div className={styles.canvas}>
          <canvas ref={chartRef}></canvas>
          <div className={styles.ChartComponentSummation}>
            {summation()}
            <div>بطاقة</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
