
import { useState } from "react";
import { Spinner } from "react-bootstrap";

export default function AddEditCloseBtnGroup({ onSubmit, resetValues, hasAdd=false
    , hasEdit=false, isSubmitting }) {

        const[type,setType]=useState('');

        console.log("isSubmitting",isSubmitting)
    return (
        <div className="d-flex justify-content-center mt-4" style={{ gap: '10px', marginTop: '30px' }} >
            
            {hasAdd != undefined && hasAdd && <button disabled={isSubmitting} onClick={() =>{
                setType('add');
                onSubmit();
            } } className="customButton save"> {isSubmitting&&type=='add' && <Spinner />} حفظ </button>}
            {hasEdit != undefined && hasEdit && <button disabled={isSubmitting} onClick={() =>{
                setType('edit');
                onSubmit();
            }} className="customButton edit"> {isSubmitting && type=='edit' && <Spinner />} تعديل</button>}
            <button onClick={() => resetValues()} disabled={isSubmitting}  className="customButton close">اغلاق</button>
        </div>
    )
}
