import { useSearchParams } from "react-router-dom";

export default function Filter({ types, gap , onClickHandler }) {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("searchparams");
  console.log(searchParams.get("filter"));
  const filter =
    searchParams.get("filter") == null
      ? types[0].value
      : searchParams.get("filter");

  console.log(filter);

  return (
    <div className="filter" style={{ gap: gap,}}>
      {types.map((el, index) => (
        <button
          key={index}
          onClick={() => {
            searchParams.set("filter", el.value);
            setSearchParams(searchParams);

            onClickHandler && onClickHandler(el.value); 
          }}
          className={`filtericon ${el.value === filter ? "activeFilter" : ""}`}
        >
          {el.text}
        </button>
      ))}
    </div>
  );
}
