import { Navigate, useSearchParams } from "react-router-dom";
import CheckForPermission from "../../../Components/Utilities/CheckForPermission";
import SideBar from "../../../Components/Utilities/SideBar";
import BanksComponent from "../../../Components/settings/banks/BanksOrDepositesComponent";
import { useEffect } from "react";

export default function BanksPage() {
    const [searchParams, setSearchParams] = useSearchParams();

    //البنك
    const bankScreenID = "665f0fb670673dba321d163a";
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    // الايداع
    const depositesScreenID="665f0fc870673dba321d163d";

    // useEffect(()=>{

    // },[]);

    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaav');

    let access = false;
    if (!loggedUser?.isAdmin){
        if(searchParams.get('section') == 'Bank') access = CheckForPermission(bankScreenID);
        if (searchParams.get("section") == "Deposite") access = CheckForPermission(depositesScreenID);
    } 
    else access=true;


    if (access == false) return <Navigate to='/setting/profile?section=Profile' />
    
    return (
        <div className="d-flex justify-content-start" style={{overflow:"hidden"}}>
            <div>
                <SideBar />
            </div>
            <div className="p-3 w-100 dashboard">
                <BanksComponent />
            </div>
        </div>
    )
}
