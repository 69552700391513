import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import AllDepositesComponent from "../../Components/deposites/AllDepositesComponent";

export default function AllDepositesPage() {
  const user = JSON.parse(localStorage.getItem('user'));
  let type='';
  if(!user?.isAdmin && !user?.liquidation_user) type='accountant';
  if(user?.isAdmin) type='accountant';
  
   console.log('type',type);

   const screenID="665f0e9370673dba321d160d";
   const loggedUser = JSON.parse(localStorage.getItem('user'));

   let access = false;
   if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
   else access=true;

   if (access == false) return <Navigate to='/setting/profile?section=Profile' />

   //CheckForPermission(screenID);
  
  return (
    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-3 w-100 dashboard" style={{overflow: "hidden"}}>
    {/* {user?.isAdmin ? <AllDepositesComponent type={type} /> : (!user?.isAdmin && !user?.liquidation_user && <AllDepositesComponent type={type} />)} */}

      <AllDepositesComponent type={type} />
    </div>
  </div>
  )
}
