import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";

export default function Pagination({ pageCount, onPress }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageClick = (data) => {
    // localStorage.setItem('page',data.selected+1);
    onPress(data.selected + 1);
  };

  let currentPageOnLoad = Number(searchParams.get('page') - 1);
  if (currentPageOnLoad == null) currentPageOnLoad = 0;
  let initialPage = Number(currentPageOnLoad);

  return (
    <div style={{ direction: 'rtl' }}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={'التالي'}
        onPageChange={handlePageClick}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={'رجوع'}
        containerClassName={"pagination justify-content-center p-3"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"pagee-item"}
        nextClassName={"pagee-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        forcePage={initialPage}
        selectedPageRel={initialPage}
        initialPage={initialPage}
      // pageClassName={(page) => `page-item ${currentPage === data.selected ? 'active' : ''}`}
      />
    </div>

  )
}
