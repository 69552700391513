import React from 'react'
import styles from './CommissionrepoBtns.module.css'

const CommissionrepoBtns = () => {
  return (
    <div>
      <div className={`${styles.CommissionrepoBtnsContainer} `}>
        <div className={styles.CommissionrepoStartBtn}>منذ البداية</div>
        <select className={styles.CommissionrepoSelect}>
          <option>يومي</option>
        </select>
        <select className={styles.CommissionrepoSelect}>
          <option>اسبوعي</option>
        </select>
        <select className={styles.CommissionrepoSelect}>
          <option>شهري</option>
        </select>
        <select className={styles.CommissionrepoSelect}>
          <option>سنوي</option>
        </select>
      </div>
    </div>
  )
}

export default CommissionrepoBtns;
