import {   useState } from "react";

export default function PermissionsCheckBox({obj,onChange,checked}) {
      const[ischecked,setIsChecked]=useState(checked);

    
    return (
        <div className="d-flex  align-items-center">

            <label className="container">
                <div className="px-1">{obj?.nameAr}</div>
                <input type="checkbox" 
                onChange={(e)=>{
                     setIsChecked(e.target.checked);
                     console.log('e.target.checked',e.target.checked);
                    //isCheckedRef.current=e.target.checked;
                    onChange(e,obj);
                }}
                checked={ischecked}
                 />
                <span className="checkmark3"></span>
            </label>
        </div>
    )
}
