import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaCaretDown, FaEye } from "react-icons/fa";
import SquareCheckBox from "../Utilities/SquareCheckBox";
import pill_img from "../../Images/pill_img.png";
import print_icon from "../../Images/print_icon.png";
// import { getReceipt } from "../../redux/slices/memberShips/thunk";
import download_icon from "../../Images/download_icon.png";
//import card22 from "../../Images/card_22.png";
import notify from "../../hook/useNotification";
import {
  renewMemberShip,
  updateMemberShip,
} from "../../redux/slices/memberShips/thunk";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { Modal, Button } from "react-bootstrap";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";

export default function TableMembersComponent({ members, hasEdit }) {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showStatusAcceptedModal, setShowStatusAcceoptedModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedMember2, setSelectedMember2] = useState(null);
  const [control, setControl] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState({});
  const [showCardSelect, setShowCardSelect] = useState(false);
const [selectedCard, setSelectedCard] = useState(null);
const [selectedMembership, setSelectedMembership] = useState(null); // To keep track of the current member
const [showCardOptions, setShowCardOptions] = useState(false);
const [selectedCards, setSelectedCards] = useState([]);
const [showPrintModal, setShowPrintModal] = useState(false);
const [showDownloadModal, setShowDownloadModal] = useState(false);



console.log("selectedMembership",selectedMembership)

  // const { id } = useParams();

  const dispatch = useDispatch();
  // const oneUser=useSelector(state=>state.permissionsReducer.userScreens);

  const loggedUser = JSON.parse(localStorage.getItem("user"));

  // useEffect(() => {
  //   dispatch(getReceipt(id));
  // }, [dispatch]);

  // const receipt = useSelector((state) => state.membersReducer.getReceipt);

  const { oneUser } = useSelector((state) => state.usersReducer);
  // console.log("receipt?.serial_number----------------",receipt?.serial_number)

  useEffect(() => {
    if (members?.data) {
      setSelectedMembers(
        members.data.reduce((acc, member) => {
          acc[member._id] = selectAll;
          return acc;
        }, {})
      );
    }
  }, [selectAll, members?.data]);

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
  };

  const handleCheckboxChange = (id) => {
    setSelectedMembers((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };




  const handleDownload = (card, selectedMembership) => {
    console.log("Download button clicked", card); // Add logging
  
    const canvas = canvasRef.current;
    canvas.width = 800; // Set canvas width
    canvas.height = 500; // Set canvas height
    const ctx = canvas.getContext("2d");
  
    // Set background color
    ctx.fillStyle = "white"; // Background color
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas with the background color
  
    ctx.font = "bold 28px Cairo"; // Set font family and size
    ctx.fillStyle = "black"; // Change text color to black
  
    // Define positions for each text item using bottom offsets
    const positions = {
      name: { left: 128.94, bottom: 166.57 }, // 50px from the bottom
      idNo: { left: 128.94, bottom: 117.8 }, // 150px from the bottom
      date: { left: 193.64, bottom: 68.32 }, // 200px from the bottom
      membershipNo: { right: 96.79, bottom: 117.8 }, // 150px from the bottom
      expDate: { right: 167, bottom: 68.32 }, // 200px from the bottom
    };
  
    // Draw left-aligned text
    ctx.textAlign = "left";
    ctx.fillText(
      `${card.name}`,
      positions.name.left,
      canvas.height - positions.name.bottom
    );
    ctx.fillText(
      `${card.identity_number_passport}`, // Updated property name
      positions.idNo.left,
      canvas.height - positions.idNo.bottom
    );
    ctx.fillText(
      `${new Date(card.createdAt).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the created date
      // `${new Date(card.createdAt).toLocaleDateString()}`, // Updated to display formatted date
      positions.date.left,
      canvas.height - positions.date.bottom
    );
  
    // Draw right-aligned text
    ctx.textAlign = "right";
    ctx.fillText(
      `${card.serial_number}`, // Updated property name
      canvas.width - positions.membershipNo.right,
      canvas.height - positions.membershipNo.bottom
    );
    ctx.fillText(
      `${new Date(selectedMembership?.end_date).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the created date
      // `${new Date(selectedMembership?.end_date).toLocaleDateString()}`, // Updated to display formatted expiry date
      canvas.width - positions.expDate.right,
      canvas.height - positions.expDate.bottom
    );
  
    // Download the canvas as an image
    const link = document.createElement("a");
    link.download = "card.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };
  




  
  

  const handlePrint = (card, selectedMembership) => {
    console.log("Print button clicked", card); // Add logging
  
    console.log("new Date(card.createdAt).toLocaleDateString('en-GB')",new Date(card.createdAt).toLocaleDateString('en-GB'))
    const printWindow = window.open("", "_blank");
  
    const canvas = document.createElement("canvas");
    canvas.width = 800; // Set canvas width
    canvas.height = 500; // Set canvas height
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
  
    // Clear the canvas to ensure it's empty and transparent
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    ctx.font = "bold 28px Cairo"; // Set font family and size
    ctx.fillStyle = "black"; // Change text color to black
  
    // Define positions for each text item
    const positions = {
      name: { left: 128.94, bottom: 166.57 }, // 50px from the bottom
      idNo: { left: 128.94, bottom: 117.8 }, // 150px from the bottom
      date: { left: 193.64, bottom: 68.32 }, // 200px from the bottom
      membershipNo: { right: 96.79, bottom: 117.8 }, // 150px from the bottom
      expDate: { right: 167, bottom: 68.32 }, // 200px from the bottom
    };
  
    // Draw left-aligned text
    // Name
    ctx.textAlign = "left";
    ctx.fillText(
      `${card.name}`,
      positions.name.left,
      canvas.height - positions.name.bottom
    );
    // ID
    ctx.fillText(
      `${card.identity_number_passport}`, // Updated to use the correct property
      positions.idNo.left,
      canvas.height - positions.idNo.bottom
    );

    ctx.fillText(
      `${new Date(card.createdAt).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the created date
      positions.date.left,
      canvas.height - positions.date.bottom
    );
  
    // Draw right-aligned text
    ctx.textAlign = "right";
    ctx.fillText(
      `${card.serial_number}`, // Updated to use the correct property
      canvas.width - positions.membershipNo.right,
      canvas.height - positions.membershipNo.bottom
    );

    ctx.fillText(
      `${new Date(selectedMembership.end_date).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the updated date
      canvas.width - positions.expDate.right,
      canvas.height - positions.expDate.bottom
    );
  
    // Add the canvas element to the print window
    printWindow.document.body.appendChild(canvas);
  
    // Optionally, add some styling to the print window
    printWindow.document.body.style.margin = "0"; // Remove default margins
    printWindow.document.body.style.display = "flex"; // Center the canvas
    printWindow.document.body.style.justifyContent = "center";
    printWindow.document.body.style.alignItems = "center";
    printWindow.document.body.style.height = "100vh"; // Full height
  
    // Print the window
    printWindow.print();
  
    // Optionally, close the print window after printing (can be commented out if not desired)
    printWindow.close();
  };
  



  const handleRenewMemberShip = async (item, type) => {
    //console.log("item", item);
    //console.log("type", type);

    if (type == "received") {
      const data = {
        membership_number: item?.membership_number,
        identity_number_passport: item?.identity_number_passport,
        membership_status: "received",
        user: loggedUser,

      };
      await dispatch(renewMemberShip({ data }));
    }

    if (type == "not_received") {
      const data = {
        membership_status: "not_received",
        user: loggedUser,

      };

      await dispatch(
        updateMemberShip({
          id: item?._id,
          data,
        })
      );
    }
  };

  const handle_edit = async (
    id,
    status,
    membership_status_updated,
    isDisabled
  ) => {
    // الادمن والمحاسب
    // if (!hasEdit)
    //   return notify(
    //     " هذا المستخدم ليس لديه صلاحية التعديل علي الحالة ",
    //     "error"
    //   );

    // if (loggedUser?.isAdmin || loggedUser?.is_accountant  || isDisabled==false) {
    // if (loggedUser?.isAdmin || loggedUser?.is_accountant) {
    if (!loggedUser?.isAdmin && !loggedUser?.is_accountant && membership_status_updated == true)
      return notify(" هذه العضويه تم تعديلها من قبل ", "error");
    else {
      const data = {
        membership_status: status,
        membership_status_updated: true,
        user: loggedUser,
      };

      // console.log(id, status);

      await dispatch(
        updateMemberShip({
          id: id,
          data,
        })
      );
    }
    // }
  };

  let Showreceipt = true;
  if (!loggedUser.isAdmin) {
    if (oneUser?.receipt_user) {
      Showreceipt = true;
    } else Showreceipt = false;
  }

  let showPhoneNumber = true;
  //  لو محاسب شوف عنده الصلاحية دي ولا لا
  if (!loggedUser.isAdmin) {
    if (oneUser?.show_mobile_in_users_card_table) {
      showPhoneNumber = true;
    } else showPhoneNumber = false;
  }
  const handle_exported = async (id) => {
    const data = {
      receipt_exported: true,
    };

    await dispatch(
      updateMemberShip({
        id: id,
        data,
      })
    );
    setShowModal(false);
    setTimeout(() => {
      navigate(`pill/${selectedMember?._id}`);
    }, 2000);
  };

  // let isDisabled=true;

  // if(loggedUser?.userMarketer || loggedUser?.liquidation_user){
  //   console.log('oooooooooooooooooooo')
  //   if(hasEdit==true) isDisabled= false;

  //   if(hasEdit==false) isDisabled=true;
  // }
  // else{
  //   isDisabled= false;
  // }

  console.log("hasEdit", hasEdit);
  // console.log('isDisabled',isDisabled);

  let showPrint = true;
  //  لو محاسب شوف عنده الصلاحية دي ولا لا
  if (!loggedUser.isAdmin) {
    if (oneUser?.printing_possibility) {
      showPrint = true;
    } else showPrint = false;
  }
  //==================================================================
  // const screenID = "66b9b5355bfa66cb94a1d3bb";

  //   let hasEdit = false;
  //   let hasAdd = false;
  //   if (!loggedUser?.isAdmin) {
  //     hasEdit = CheckForPermissionTypes(screenID, 'edit');
  //     hasAdd = CheckForPermissionTypes(screenID, 'add');

  //   }
  //   else {
  //     hasEdit = true;
  //     hasAdd = true;
  //   }

  //==================================================================
  const isDisabled = loggedUser?.is_accountant && !hasEdit;



  const handlePrintClick = (el) => {
    // Set the selected membership and show the modal
    setSelectedMembership(el); // Assume this state holds the specific `el`
    setShowPrintModal(true);
  };
  // Function to handle card click for printing
  const handleCardClick = (card, selectedMembership) => {
    handlePrint(card, selectedMembership); // Call the print function when a card is clicked
    setShowPrintModal(false); // Close the modal after selecting a card
  };



  const handleDownloadClick = (membershipCards, el) => {
    setSelectedMembership(el); // Store the selected membership
    setShowDownloadModal(true); // Show the download modal
  };
  


  const handleCardDownload = (card, selectedMembership) => {
    console.log("Selected Card for Download:", card); // Log the card details
    handleDownload(card, selectedMembership); // Call the download function with the selected card
    setShowDownloadModal(false); // Close the modal after selecting a card
  };
  




  
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [currentRowIndex, setCurrentRowIndex] = useState(-1);
  const [expandedRows, setExpandedRows] = useState({});

  
    // const toggleDetails = () => {
    //   setShowDetails((prev) => !prev);
    //   console.log("selectedRow",selectedRow)
    //   console.log("filteredCards",filteredCards)
    // };

    const toggleDetails = (id) => {
      setExpandedRows((prev) => ({
        ...prev,
        [id]: !prev[id], // Toggle the expanded state for the specific ID
      }));
    };
    
  
    const filteredCards = selectedRow?.membershipCards.filter(
      (card) => !card.main_card
    );




  return (
    <div style={{ overflow: "auto" }}>
      <div className="header" style={{ width: "100%", minWidth: "1200px" }}>
        <div>قائمة العضويات</div>
        <div>عدد العضويات : {members?.data?.length}</div>
      </div>

      <div
        className="permissionsTable"
        style={{ width: "100%", minWidth: "1200px" }}
      >
        <table style={{ width: "100%", minWidth: "1200px" }}>
          <thead>
            <tr>
              <th>
                <SquareCheckBox
                  marginTop="-9px"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th>الرقم التسلسلي</th>
              <th>الاسم</th>
              <th>الاسم المرفق</th>
              <th>رقم العضوية</th>
              <th>رقم الهوية</th>
              {showPhoneNumber && <th>الهاتف</th>}
              <th>السعر</th>
              <th>مرسله من</th>
              {Showreceipt && <th>اصدار فاتورة</th>}
              <th>البداية</th>
              <th>النهاية</th>
              <th>البطاقات المرفقة</th>
              <th>الحالة</th>
              <th>استعراض</th>
              {!loggedUser?.userMarketer && <th>تعديل</th>}
              <th>
                <span> تحميل </span>
                {showPrint && <span> و طباعة </span>}
              </th>
              {!loggedUser?.userMarketer && loggedUser?.isAdmin && (
                <th>تحكم</th>
              )}
            </tr>
          </thead>
          { members?.data?.length > 0 ? (<tbody>
  {members?.data?.map((el, i) => {
    const mainCard = el?.membershipCards?.find((card) => card.main_card);
    const secCard = el?.membershipCards?.filter((card) => !card.main_card);
    const isExpanded = expandedRows[el._id]; // Check if this row is expanded

    return (
      <React.Fragment key={i}>

                <tr>
                  <td>
                    <SquareCheckBox
                      marginTop="-9px"
                      checked={!!selectedMembers[el._id]}
                      onChange={() => handleCheckboxChange(el._id)}
                    />
                  </td>
                  <td>{el?.membership_number}</td>
                  <td>{mainCard?.name}</td>
                  <td>{secCard[0] ? secCard[0]?.name : "لا يوجد"}</td>
                  <td>{mainCard?.serial_number}</td>
                  <td>{mainCard?.identity_number_passport || "0"}</td>
                  {showPhoneNumber && <td>{mainCard?.mobile}</td>}
                  <td>{el?.price}</td>
                  <td>{el?.sent_by != null ? el?.sent_by : "-----"  }</td>
                  {Showreceipt && (
                    <td style={{ cursor: "pointer" }}>
                      {!el?.receipt_exported ? (
                        <img
                          onClick={() => {
                            let access = false;
                            console.log("loggedUser", loggedUser);
                            if (loggedUser?.isAdmin) {
                              access = true;
                            } else {
                              console.log("receipt_exported");
                              oneUser?.receipt_user
                                ? (access = true)
                                : (access = false);
                            }

                            if (access && el.membership_status === "received") {
                              setShowModal(true);
                              setSelectedMember(el);
                            } else notify("لا يمكن اصدار فاتورة ", "error");
                          }}
                          src={pill_img}
                          alt=""
                        />
                      ) : (
                        <div
                          className="pinkReceiptNumber"
                          onClick={() => {
                            navigate(`pill/${el?._id}`);
                          }}
                        >
                          {el?.receipt_number}
                          <span className="printColor">
                            <MdOutlineLocalPrintshop />
                          </span>
                        </div>
                      )}
                    </td>
                  )}
                  <td>{el?.start_date.split("T")[0]}</td>
                  <td>{el?.end_date.split("T")[0]}</td>
                  <td>
          {el?.membershipCards.length}
          {el?.membershipCards.length > 1 && (
            <span
              className="mx-1"
              style={{ color: "rgba(121, 121, 129, 1)", cursor: "pointer" }}
              // onClick={()=>{
              //   setSelectedRow(el);
              //   toggleDetails();
              // }}
              onClick={() => toggleDetails(el._id)} // Toggle expansion

            >
              <FaCaretDown size={25} />
            </span>
          )}
        </td>
                  <td>
                    {el?.membership_status === "received" ? (
                      <button
                        className="green-filter-btn"
                        onClick={() =>
                         {
                             setSelectedMember2(el)
                             setShowStatusModal(true)
                          }
                          }
                        style={{
                          borderRadius: "4px",
                          height: "auto",
                          width: "100px",
                          backgroundColor: isDisabled ? "gray" : "rgba(132, 203, 0, 1)",
                          cursor: isDisabled ? "not-allowed" : "pointer", // Change cursor if disabled
                        }}
                        disabled={isDisabled}
                      >
                        مستلمة
                      </button>
                    ) : (
                      <button
                        className="green-filter-btn yellow-btn"
                        onClick={() =>
                          {
                            setShowStatusAcceoptedModal(true)
                            setSelectedMember2(el)}
                        }
                        style={{
                          borderRadius: "4px",
                          height: "auto",
                          width: "100px",
                          backgroundColor: isDisabled ? "gray" : "rgb(254, 170, 0)",
                          cursor: isDisabled ? "not-allowed" : "pointer", // Change cursor if disabled
                        }}
                        disabled={isDisabled}
                      >
                        غير مستلمة
                      </button>
                    )}
                  </td>
                  <td style={{ cursor: "pointer" }}>
                    {
                      // el?.
                    }
                    <FaEye
                      onClick={() => {
                        // if (el.membership_status === "not_received")
                        //   return notify("لا يمكن اصدار فاتورة ", "error");

                        navigate(`details/${el?._id}`, {
                          state: el,
                        });
                      }}
                      color="rgba(249, 32, 122, 1)"
                      size={35}
                    />
                  </td>
                  {!loggedUser?.userMarketer && (
                    <td>
                      {" "}
                      <button
                        onClick={() =>
                          navigate("/new-member", {
                            state: { ...el },
                          })
                        }
                        // disabled={isDisabled}
                        className="green-filter-btn yellow-btn"
                        style={{
                          color: "#fff",
                          borderRadius: "4px",
                          height: "auto",
                          width: "auto",
                          padding: "0px 5px",
                        }}
                      >
                        {" "}
                        تعديل{" "}
                      </button>{" "}
                    </td>
                  )}
                  <td>
                    {showPrint && (
                <>
                <img
                  src={print_icon}
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={() => handlePrintClick(el)} // Pass the entire `el` to the handler
                />
              
                {/* Modal for displaying the cards of the selected membership */}
               
              </>
                    )}
          <img   
  src={download_icon}
  className="mx-1"
  style={{ cursor: "pointer" }}
  onClick={() => handleDownloadClick(el?.membershipCards, el)} // Pass the membership cards and element
  alt=""
/>
                  </td>
                  {/* {!loggedUser?.userMarketer && (
                    <td>
                      <div
                        style={{
                          width: "65px",
                          height: "30px",
                          color: "white",
                          fontSize: "12px",
                          backgroundColor:
                            el.membership_status === "received"
                              ? "#0080FB"
                              : el.membership_status === "not_received"
                              ? "#f0142f"
                              : "#ccc",
                          border: `1px solid ${
                            el.membership_status === "received"
                              ? "#0080FB"
                              : el.membership_status === "not_received"
                              ? "#f0142f"
                              : "#ccc"
                          }`,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          cursor: "default",
                        }}
                      >
                        {el.membership_status === "0" && "تحكم"}
                        {el.membership_status === "received" && "تجديد"}
                        {el.membership_status === "not_received" && "استرجاع"}
                        {!el.membership_status && "تحكم"}
                      </div>
                    </td>
                  )} */}
                  {!loggedUser?.userMarketer && loggedUser?.isAdmin && (
                    <>
                      <td>
                        <select
                          // value={control[el?._id] || "تجديد"}
                          // onChange={(e) =>
                          //   handleControlChange(el?._id, e.target.value)
                          // }
                          onChange={(e) =>
                            handleRenewMemberShip(el, e.target.value)
                          }
                          value={"0"}
                          name="control"
                          id="control"
                          style={{
                            width: "65px",
                            height: "30px",
                            color: "background-color: var(--input-color)",
                            fontSize: "12px",
                          }}
                        >
                          <option value={"0"}> تحكم </option>
                          {/* <option value="received">تجديد</option> */}
                          {!loggedUser?.liquidation_user && (
                            <option value="not_received">استرجاع</option>
                          )}
                        </select>
                      </td>
                    </>
                  )}
                  {/* {!loggedUser?.userMarketer && loggedUser?.isAdmin &&  <td>
                    {el?.membership_status === "received" ? (
                      <button
                        className="green-filter-btn"
                        onClick={() => 
                          handle_edit(
                            el?._id,
                            "not_received",
                            el?.membership_status_updated,
                            isDisabled
                          )
                        }
                        style={{
                          background: "#0080FB",
                          borderRadius: "4px",
                          height: "auto",
                          width: "70px",
                        }}
                        disabled={isDisabled}
                      >
                        تحكم
                      </button>
                    ) : (
                      <button
                        className="green-filter-btn yellow-btn "
                        onClick={() =>
                          handle_edit(
                            el?._id,
                            "0",
                            el?.membership_status_updated,
                            isDisabled
                          )
                        }
                        style={{
                          background: "#f0142f",
                          borderRadius: "4px",
                          height: "auto",
                          width: "70px",
                        }}
                        disabled={isDisabled}
                      >
                          استرجاع
                      </button>
                    )}
                  </td>} */}
                </tr>
                {isExpanded &&
el?.membershipCards?.filter((card) => !card.main_card).map((card, index) => (
  <tr key={`${el._id}-${index}`} className="expanded-row">
              <td>
                <SquareCheckBox
                  marginTop="-9px"
                  checked={!!selectedMembers[card._id]}
                  onChange={() => handleCheckboxChange(card._id)}
                />
              </td>
              <td>{el?.membership_number}</td>
              <td>{card?.name}</td>
              <td></td>
              <td>{card?.serial_number}</td>
              <td>{card?.identity_number_passport || "0"}</td>
              {showPhoneNumber && <td>{card?.mobile}</td>}
              <td>مجانية</td>
              <td></td>
              {Showreceipt && <td></td>}
              <td>{el?.start_date.split("T")[0]}</td>
              <td>{el?.end_date.split("T")[0]}</td>
              <td></td>
              <td></td>
              {!loggedUser?.userMarketer && <td></td>}
              <td></td>
              {!loggedUser?.userMarketer && loggedUser?.isAdmin && <td></td>}
            </tr>
          ))}
      </React.Fragment>
    );
  })}
</tbody> ): (
    <tbody>
      <tr>
        <td colSpan="100%" style={{ textAlign: "center", padding: "20px", fontSize: "18px", color: "#888" }}>
          لا يوجد بيانات للعرض
        </td>
      </tr>
    </tbody>
  )}
  
        </table>
        <canvas
          ref={canvasRef}
          width={400}
          height={250}
          style={{ display: "none" }}
        ></canvas>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <h5 className="h5ModalTitle">اصدار فاتورة </h5>

          <div
            className="label"
            style={{ display: "flex", justifyContent: "center" }}
          >
            هل تريد اصدار فاتورة إلى هيئة الزكاة الآن
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center border-0">
          <Button
            onClick={() => {
              handle_exported(selectedMember?._id);
            }}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#0080FB" }}
          >
            إصدار
          </Button>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#F0142F", border: "#F0142F" }}
          >
            إلغاء
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
        <Modal.Body>
          <h5 className="h5ModalTitle"> حالةالعضوية </h5>

          <div
            className="label"
            style={{ display: "flex", justifyContent: "center" }}
          >
             هل تريد تغيير حالة العضوية الي غير مستلمة
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center border-0">
          <Button
                onClick={() =>
                {
                 
                  handle_edit(
                     selectedMember2?._id,
                     "not_received",
                     selectedMember2?.membership_status_updated
                     // isDisabled
                     
                   )
                   console.log("selectedMember2?._id",selectedMember2?._id)

                   console.log("tmam2")

                   setShowStatusModal(false)
                } 
                }
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#0080FB" }}
          >
           موافق
          </Button>
          <Button
            onClick={() => {
              setShowStatusModal(false);
            }}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#F0142F", border: "#F0142F" }}
          >
            إلغاء
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showStatusAcceptedModal} onHide={() => setShowStatusAcceoptedModal(false)}>
        <Modal.Body>
          <h5 className="h5ModalTitle"> حالة العضوية </h5>

          <div
            className="label"
            style={{ display: "flex", justifyContent: "center" }}
          >
             هل تريد تغيير حالة العضوية الي مستلمة
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center border-0">
          <Button
                onClick={() =>
                 {
                  
                   handle_edit(
                      selectedMember2?._id,
                      "received",
                      selectedMember2?.membership_status_updated
                      // isDisabled
                    ) 
                    console.log("selectedMember2",selectedMember2)
                    console.log("selectedMember2?._idNOTRESIVED:-",selectedMember2?._id)

                    console.log("tmam")
                    setShowStatusAcceoptedModal(false)
                 } 
                }
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#0080FB" }}
          >
           موافق
          </Button>
          <Button
            onClick={() => {
              setShowStatusAcceoptedModal(false);
            }}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#F0142F", border: "#F0142F" }}
          >
            إلغاء
          </Button>
        </Modal.Footer>
      </Modal>




      <Modal show={showPrintModal} onHide={() => setShowPrintModal(false)}>
    <Modal.Body>
      <h5 className="h5ModalTitle">اختيار الكارت للطباعة</h5>

      <div className="card-options">
        {selectedMembership?.membershipCards?.map((card, index) => (
          <div
            key={index} // Use the index as the key
            onClick={() => handleCardClick(card, selectedMembership)} // Call the print function for the clicked card
            style={{
              cursor: "pointer",
              padding: "5px",
              border: "1px solid #ccc",
              margin: "20px 0",
            }}
          >
            {`الكارت رقم ${index + 1}`} {/* Display the card option */}
          </div>
        ))}
      </div>
    </Modal.Body>

    <Modal.Footer className="d-flex justify-content-center border-0">
      <Button
        onClick={() => setShowPrintModal(false)}
        className="w-25"
        variant="primary"
        style={{ backgroundColor: "#F0142F" }}
      >
        إلغاء
      </Button>
    </Modal.Footer>
  </Modal>




  <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
  <Modal.Body>
    <h5 className="h5ModalTitle">اختر بطاقة للتنزيل</h5>
    <div className="card-options">
      {selectedMembership?.membershipCards && selectedMembership.membershipCards.map((card, index) => (
        <div
          key={index}
          onClick={() => handleCardDownload(card, selectedMembership)} // Call download on card click
          style={{ cursor: "pointer", padding: "5px", border: "1px solid #ccc", margin: "20px 0" }}
        >
          {`الكارت رقم ${index + 1}`} {/* Display the card option */}
        </div>
      ))}
    </div>
  </Modal.Body>

  <Modal.Footer className="d-flex justify-content-center border-0">
      <Button
        onClick={() => setShowDownloadModal(false)}
        className="w-25"
        variant="primary"
        style={{ backgroundColor: "#F0142F" }}
      >
        إلغاء
      </Button>
    </Modal.Footer>

</Modal>



    </div>
  );
}
