import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { ProtectedRouteHook } from './hook/auth/ProtectedRouteHook';
import { Suspense, useEffect } from 'react';
import NavBarLogin from './Components/Utilities/NavBarLogin';
import LoginPage from './Pages/Auth/LoginPage';
import AllUsersPage from './Pages/users/AllUsersPage';
import AddUserPage from './Pages/users/AddUserPage';
import EditUserPage from './Pages/users/EditUserPage';
import AllPermissionsGroupsPage from './Pages/permissions/AllPermissionsGroupsPage';
import EditPermissionsPage from './Pages/permissions/EditPermissionsPage';
import AddMaketingUserPage from './Pages/marketing/AddMaketingUserPage';
import { ToastContainer } from 'react-toastify';
import HomePage from './Pages/home/HomePage';
import { ProtectedRoute } from './Components/Utilities/ProtectedRoute';
import { getAllPermissionTypes, getUserScreens } from './redux/slices/permissions/thunk';
import { useDispatch } from "react-redux";
import AllOffersPage from './Pages/offers/AllOffersPage';
import SettingsPage from './Pages/settings/SettingsPage';
import BanksPage from './Pages/settings/banks/BanksPage';
import AllOrdersPage from './Pages/orders/AllOrdersPage';
import NewOrderPage from './Pages/orders/NewOrderPage';
import ProfilePage from './Pages/settings/profile/ProfilePage';
import AllMembersPage from './Pages/members/AllMembersPage';
import AddOrderPage from './Pages/orders/AddOrderPage';
import EditOrderPage from './Pages/orders/EditOrderPage';
import MedicalSystemPage from './Pages/Medical-system/MedicalSystemPage';
import CheckMemberShipPage from './Pages/members/CheckMemberShipPage';
import AddMemberPage from './Pages/members/AddMemberPage';
import MemberDetailsPage from './Pages/members/MemberDetailsPage';
import AllDepositesPage from './Pages/deposites/AllDepositesPage';
import ArchivesDepositesPage from './Pages/deposites/ArchivesDepositesPage';
import AddDepositePage from './Pages/deposites/AddDepositePage';
import AllLiquidationPage from './Pages/liquidation/AllLiquidationPage';
import { getAllUsers, getLiquidationUsers } from './redux/slices/users/thunk';
import EditLiquidationPage from './Pages/liquidation/EditLiquidationPage';
import WalletPage from './Pages/wallet/WalletPage';
import ReportPage from './Pages/marketing/ReportPage';
import PillMemberPage from './Pages/members/PillMemberPage';
import UserSummaryPage from './Pages/user-summary/UserSummaryPage';
import AddAndEditCountryPage from './Pages/AddAndEditCountryPage/AddAndEditCountryPage';
import Cities from './Pages/Cities/Cities';
import AccountantWithDrawPage from './Pages/withDraws/AccountantWithDrawPage';
import SalesRebort from './Components/members/Sales-Report/SalesReport';
import SendOrderSuccessfully from './Components/SendOrderSuccessfully/SendOrderSuccessfully';
import FailedToSendOrder from './Components/SendOrderSuccessfully/FailedToSendOrder';


function App() {
  const [isAuth] = ProtectedRouteHook();
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem('user'));


  useEffect(() => {
    const get = async () => {
      let query = ``;
      if (loggedUser && !loggedUser.isAdmin) {
        query = `?father_id=${loggedUser._id}`;
        //محاسب
        if (!loggedUser.liquidation_user && !loggedUser.isAdmin) query = ``;
      }

      // مسوق
      if (loggedUser?.userMarketer && !loggedUser.liquidation_user && !loggedUser.isAdmin) {
        query = `?father_id=${loggedUser.father_id}`;
      }

      if (loggedUser?._id) {
        //console.log('ppppppppppppppppppppppppp')
        dispatch(getUserScreens(loggedUser?._id));
        dispatch(getAllPermissionTypes());
        // dispatch(getAllUsers(query));
        dispatch(getLiquidationUsers());
      }
    }

    get();
    // console.log('ppppppppppppppppppppppppp')
    // dispatch(getUserScreens(loggedUser?._id));


  }, []);


  // if(loggedUser==null) return window.location.href='/';

  return (
    <div className="font main-BG-Color">
      <BrowserRouter>
        <NavBarLogin />

        <Suspense fallback="loading..." >

        <Routes>
        <Route index element={<LoginPage />} />

           {/* الصفحة الخارجية لتسجيل طلب جديدة */}
        <Route path='/orders/new' element={<NewOrderPage />} />

          {/* صفحة التقرير هنا */}
          <Route path='/marketing/report' element={<ReportPage />} />
          <Route path='/check-member' element={<CheckMemberShipPage />} />

          {/* التسجيل */}
          <Route path='/sendOrderSuccessfully' element={<SendOrderSuccessfully />} />
          <Route path='/failedToSendOrder' element={<FailedToSendOrder />} />

          <Route element={<ProtectedRoute auth={isAuth} />} >
            
              <Route path='/home' element={<HomePage />} />

              <Route path='/users'>
                <Route index element={<AllUsersPage />} />
                <Route path='add' element={<AddUserPage />} />
                <Route path='edit/:id' element={<EditUserPage />} />
              </Route>

              <Route path='/permissions'  >
                <Route index element={<AllPermissionsGroupsPage />} />
                <Route path='edit/:id' element={<EditPermissionsPage />} />
              </Route>

              <Route path='/offers'>
                <Route index element={<AllOffersPage />} />
              </Route>

              <Route path='/orders'>
                <Route index element={<AllOrdersPage />} />
                <Route path='add' element={<AddOrderPage />} />
                <Route path='edit/:id' element={<EditOrderPage />} />
              </Route>

          
              <Route path='/marketing'>
                <Route index element={<AddMaketingUserPage />} />
              </Route>

              <Route path='/setting'>
                <Route path='' element={<SettingsPage />} />
                <Route path='banks' element={<BanksPage />} />
                <Route path='profile' element={<ProfilePage />} />
              </Route>

              <Route path='/members'>
                <Route index element={<AllMembersPage />} />
                <Route path='details/:id' element={<MemberDetailsPage />} />
                <Route path='pill/:id' element={<PillMemberPage />} />
                <Route path='salesRebort' element={<SalesRebort />} />
              </Route>

              <Route path='/new-member' element={<AddMemberPage />} />


              <Route path='/medical-system'>
                <Route index element={<MedicalSystemPage />} />
              </Route>

              <Route path='/deposites'>
                <Route index element={<AllDepositesPage />} />
                <Route path='add' element={<AddDepositePage />} />
              </Route>
              {/* deposites-archive */}

              {/* ارشيف الايداعات */}
              <Route path='/archive' element={<ArchivesDepositesPage />} />

              <Route path='/liquidation'>
                <Route index element={<AllLiquidationPage />} />
                <Route path='show/:id' element={<EditLiquidationPage />} />
              </Route>

              <Route path='/wallet'>
                <Route index element={<WalletPage />} />
              </Route>

              <Route path='/usersummary'>
                <Route index element={<UserSummaryPage />} />
              </Route>
              {/* currentTime */}
              <Route path='/countries'>
                <Route index element={<AddAndEditCountryPage />} />
                <Route path='cities/:id' element={<Cities />} />
              </Route>

              {/* طلبات السحب للمحاسب */}
              <Route path='/withDrawRequests' element={<AccountantWithDrawPage />} />
             

           
          </Route>

          </Routes>
        </Suspense>
      </BrowserRouter>

      <ToastContainer />
    </div>
  );
}

export default App;
