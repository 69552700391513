import { useEffect, useState } from "react";
import CircleCheckBoxWithIcon from "../Utilities/CircleCheckBoxWithIcon";
import BtnGroup from "./BtnGroup";
import styles from "./CreateBoxComponent.module.css";
import TableComponent from "./TableComponent";
import { useDispatch, useSelector } from "react-redux";
import { addAgentToUser, getUserAgents, updateAgentUser } from "../../redux/slices/marketing/thunk";
import notify from "../../hook/useNotification";
import Select from "../Utilities/Select";
import Select3 from "../Utilities/Select3";
import { getAllCities } from "../../redux/slices/cities/thunk";
import { Spinner } from "react-bootstrap";



const CreateBoxComponent = ({hasEdit,hasAdd, hasDelete, formik, onSubmit, initialValues, mobileError, isLoading, userAgents, handleClose}) => {

  const loggedUser = JSON.parse(localStorage.getItem('user'));
  // const [sales_manager_name, set_sales_manager_name] = useState(loggedUser?.fullname);


  const dispatch = useDispatch();

  console.log("hasDelete12",hasDelete)

 // if(loggedUser?.isAdmin) window.history.back();
  
  useEffect(() => {
    let query='';
    if(loggedUser?.liquidation_user) query=`sales_id=${loggedUser?._id}`;
   // if(loggedUser?.isAdmin) query='';

    dispatch(getUserAgents(query));
  }, []);


  const { cities, countries } = useSelector(state => state.citiesReducer);

  useEffect(() => {


    dispatch(getAllCities(loggedUser?.country?._id));
  }, []);








  const handleShowSales = () => {

    formik.setValues({
      ...formik.values,
      show_all_sales: !formik.values.show_all_sales
    })
  }




  console.log('initialValues', formik.values);

  console.log('userAgents', userAgents);

  console.log('formik?.values?._id',formik?.values?._id)

 
  return (
    <div className="p-4 sacondBackground">
      
     { 
     loggedUser.liquidation_user &&
     <div>
     <div className={`${styles.CreateBoxComponentPerantMaster} mt-4`}>
          <div className={styles.CreateBoxComponentPerant}>
            <div className={styles.CreateBoxComponent1}>
              <label className={styles.CreateBoxComponent1Label}>البريد الالكتروني</label>
              <input
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                name="email"
                className={`inputsReduisAndPadding2 borderBlack ${styles.CreateBoxComponentInput}`}
                placeholder="البريد الالكتروني"
              />
            </div>
            <div className={styles.CreateBoxComponent1} style={{marginTop:"20px"}}>
              <label className={styles.CreateBoxComponent1Label}>رقم الجوال</label>
              <input
                type="number"
                name="phone_number"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                className={` borderBlack ${styles.CreateBoxComponentInput}`}
                placeholder="رقم الجوال"
                error={mobileError} 

              />
            </div>
            <div className={styles.CreateBoxComponent1} style={{marginTop:"20px"}}>
              <label className={styles.CreateBoxComponent1Label}>اسم مدير المبيعات</label>
              <input
                type="text"
                value={formik.values.sales_manager_name}
                onChange={formik.handleChange}
                // setValue={set_sales_manager_name}
                className={` borderBlack ${styles.CreateBoxComponentInput}`}
                placeholder="اسم مدير المبيعات"
                name="sales_manager_name"
                disabled
              />
            </div>
          </div>

          <div className={styles.CreateBoxComponentPerant}>
            <div className={styles.CreateBoxComponent1}>
              <label className={styles.CreateBoxComponent1Label}>اسم المسوق</label>
              <input
                type="text"
                value={formik.values.marketer_name}
                onChange={formik.handleChange}
                className={` borderBlack ${styles.CreateBoxComponentInput}`}
                placeholder="اسم المسوق"
                name="marketer_name"
              />
            </div>
            <div className="inputContainer" >
            <Select3 defaultOption={'من فضلك قم ب اختيار المدينة'}
            //  disabled={!hasEdit} 
             value={formik.values.city}
              name={'city'} 
              label={'المدينة'} 
              onChange={formik.handleChange} 
              options={cities} 
              height={'50px'}
              width={'100%'} 
              classnam={"inputsReduisAndPadding2 borderBlack font"} />
          </div>
            <div className={styles.CreateBoxComponent1} style={{marginTop:"20px"}}>
              <label className={styles.CreateBoxComponent1Label}>مبلغ العمولة</label>
              <input
                value={formik.values.amount_of_commission}
                onChange={formik.handleChange}
                type="number"
                className={` borderBlack ${styles.CreateBoxComponentInput}`}
                placeholder="مبلغ العمولة"
                name="amount_of_commission"
              />
            </div>
          </div>

        </div>

        <div className={styles.CreateBoxComponentDate}>
          <div className={styles.datewidth}>
            <label className={`${styles.CreateBoxComponent1Label} my-2`}> تاريخ البداية </label>
            <input type="date" value={formik.values.start_date} onChange={formik.handleChange} name="start_date" className={` borderBlack ${styles.CreateBoxComponentStartEndDate}`} />
          </div>

          <div className={styles.datewidth}>
            <label className={`${styles.CreateBoxComponent1Label} my-2`}> تاريخ النهاية </label>
            <input type="date" value={formik.values.end_date} onChange={formik.handleChange} name="end_date" className={` borderBlack ${styles.CreateBoxComponentStartEndDate}`} />
          </div>
        </div>

        <div className={styles.handelToggel} >
          <div className={styles.CreateBoxComponentShow}>إظهار اجمالي المبيعات في صفحة المسوق</div>
          <div className={styles.toggel}>
            <CircleCheckBoxWithIcon
              onClick={() => handleShowSales()}
              check={formik.values.show_all_sales}
            />
       
          </div>
        </div>
        <BtnGroup hasAdd={hasAdd} hasEdit={hasEdit} onSubmit={onSubmit} isLoading={isLoading} handleClose={handleClose} />
        </div>
        }

        <TableComponent hasEdit={hasEdit} userAgents={userAgents} formik={formik} hasDelete={hasDelete} />
    </div>
  );
};

export default CreateBoxComponent;
