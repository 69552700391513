import { Navigate, useSearchParams } from "react-router-dom";
import AllUsersComponent from "../../Components/users/AllUsersComponent";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import CheckForPermissionTypes from "../../Components/Utilities/CheckForPermissionTypes";



export default function AllUsersPage() {
  const screenID="665f0e6f70673dba321d1606";
  const loggedUser = JSON.parse(localStorage.getItem('user'));





  //console.log(';;;;;;;;;;;;;;;;',searchParams.get('page'))

  // if(searchParams.get('page')==null){
  //   console.log('pppppppppppppppppp')
  //   searchParams.set('page',1);
  //   setSearchParams(searchParams);
  // }
  
  let access = false;
    if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
    else access=true;

    let hasEdit = false;
  let hasAdd = false;
  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');

  }
  else {
    hasEdit = true;
    hasAdd = true;
  }

  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);

    if (access == false) return <Navigate to='/setting/profile?section=Profile' />
  return (
    <div className="d-flex justify-content-start" style={{overflow:"hidden"}}>
 <div >
          <SideBar />
        </div>
        <div className="p-3 w-100 dashboard">
        <AllUsersComponent hasAdd={hasAdd} />
        </div>
        
    </div>
  )
}
