import { useDispatch } from "react-redux";
import Filter from "./Filter";
import PdfBox from "./PdfBox";
import {
  getHomeStatistics,
  getHomeStatisticsChart,
  getHomeStatisticsOffers,
} from "../../redux/slices/home/thunk";

export default function HeadSection({ label }) {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const filterFunction = async (type) => {
    console.log("pppppppppppppp", type);
    let query = `type=${type}`;

    if (!loggedUser?.isAdmin) {
      query += `&user_id=${loggedUser?._id}`;
    }

    dispatch(getHomeStatistics(query));
    dispatch(getHomeStatisticsOffers(query));
    dispatch(getHomeStatisticsChart(query));
  };
  return (
    <div
      className="d-flex justify-content-between firstContainer"
      style={{ margin: "10px" }}
    >
      <h5 className="h5Title">{label}</h5>
      <div className="d-flex" style={{ gap: "20px", flexWrap: "wrap" }}>
        <Filter
          gap={"10"}
          types={[
            { text: "اليوم", value: "day" },
            { text: "الاسبوع", value: "week" },
            { text: "الشهر", value: "month" },
          ]}
          onClickHandler={filterFunction}
        />

        <PdfBox />
      </div>
    </div>
  );
}
