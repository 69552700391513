import AddButton from "./AddButton";
import Filter from "./Filter";
import PdfBox from "./PdfBox";



export default function UsersHeader({ title, navigateTo = '', currentRoute, title2, backgroundColorBtn = '#0d6efd',hasAdd = true }) {

  // console.log('currentRoute',currentRoute);
  // const userPermissions=JSON.parse(localStorage.getItem("user"))?.availableRoutesArr;
  // const haveAdd=userPermissions.find(el=>el.nameEn==currentRoute)
  // ?.group_permissions.find(el=>el.nameEn=='إضافة')
  // ? true :false;
  //${haveAdd ? 'justify-content-between' : 'justify-content-end'}
  //console.log('haveAdd',haveAdd);
  let justify_class=hasAdd ? 'justify-content-between' : 'justify-content-end';

  return (
    <div className={`d-flex ${justify_class} firstContainer`} style={{ flexWrab: "wrab" }}>

      {
        hasAdd && <AddButton title={title} navigateTo={navigateTo} backgroundColor={backgroundColorBtn} color={'white'}/>
      }
      {
        hasAdd && title2 && <AddButton title={title2} navigateTo={navigateTo} backgroundColor={'#0d6efd'} color={'white'} />
      }

      <div className="d-flex justify-content-between" style={{ gap: '20px', flexWrap: 'wrap' }}>
        <Filter types={[
          { text: 'اليوم', value: 'day' },
          { text: 'الاسبوع', value: 'week' },
          { text: 'الشهر', value: 'month' },
        ]} />

        <PdfBox />

      </div>

    </div>
  )
}
