import { useParams } from "react-router-dom";

export default function Select4({ label, onChange, width, options, value,onClick ,disabled=false,name,lableClass,labelSelect='nameAr',defaultOption,border,flexGrow, classnam = null , className = null,height}) {
     console.log('value..........',value);
     const {id}=useParams();

     console.log('options',options);

    return (
        <div className={`d-flex flex-column ${className}`} style={{ width: width,flexGrow:flexGrow }}>
            <div className={`label ${lableClass}`}>{label}</div>
            <select name={name} value={value} onChange={onChange} onClick={onClick} className={`w-100 ${classnam}`}       
            style={{ border: border, height: height, WebkitAppearance: 'none', appearance: 'none' ,menuPlacement:"auto", menuPosition:"fixed"  }}
            disabled={disabled}>
                {defaultOption&&<option value={0}>{defaultOption}</option>}
                {
                    options && options?.map((el, i) =>
                        <option key={el?._id} value={el?._id}>{el?.[labelSelect]} </option>
                    )
                }
            </select>
        </div>

    )
}
