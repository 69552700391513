import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { addOneOffer, getAllOffers ,updateOneOffer, getAllOffersforMandob } from "../../redux/slices/offers/thunk";
import { useEffect,useState } from "react";
import notify from "../useNotification";



export default function AllOffersHook() {
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false); 

    const user=JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        let query=`type=offer&`;
        if(user?.liquidation_user){
            query=`type=commission&user=${user?._id}`;
            dispatch(getAllOffersforMandob(query));
        } 
        else{
            dispatch(getAllOffers(query));
        }
    }, []);

    const { offers } = useSelector(state => state.offersReducer);

    //console.log('offers',offers);
    const loggedUser = JSON.parse(localStorage.getItem('user'));

    const periodTypeOptions = [
        { nameAr: 'سنة', _id: 2 },
        { nameAr: 'شهر', _id: 1 }
    ];

    const periodsOptions=[
        {_id:1,nameAr:'1'},
        {_id:2,nameAr:'2'},
        {_id:3,nameAr:'3'},
        {_id:4,nameAr:'4'},
        {_id:5,nameAr:'5'},
        {_id:6,nameAr:'6'},
        {_id:7,nameAr:'7'},
        {_id:8,nameAr:'8'},
        {_id:9,nameAr:'9'},
        {_id:10,nameAr:'10'},
        {_id:11,nameAr:'11'},
        {_id:12,nameAr:'12'},
      ];

    let initialValues = {
        _id: "",
        user: loggedUser._id,
        offerNameAr: "",
        offerNameEn: "",
        periodType: "1",
        validityPeriod: "1",
        originalAmount: "",
        reducedAmount: "",
        numberOfCards: "",
        salesCommission: "",
        status: true,

    };
    
    const resetValues=()=>{
        formik.setValues(initialValues);
    }

   
    const handleUpdateStatus=async(offer)=>{
        await dispatch(updateOneOffer({
            id:offer._id,
            data:{status:!offer?.status}
        }));
    }



    //console.log('initialValues',initialValues);

    const onSubmit = async () => {

    //     if (isSubmitting) return; 
    //     setIsSubmitting(true); 
      
    //     let counter = 1;
    //     if (counter > 1) {
    //       setIsSubmitting(false);
    //       return;
    //     }
    //     counter++;
    // console.log("counter",counter)
    // console.log("counterState",isSubmitting)

        console.log('formik add', formik.values);

        //validation
        for (let key in formik.values) {
            //console.log('key', key);
            if (key !== "_id"){
                if (formik.values[key] === "") return notify("من فضلك قم ب ادخال جميع الحقول", "error");
            } 
        }

        //edit
        if (formik.values._id !== "") {
            try {
                setIsSubmitting(true);
                await dispatch(updateOneOffer({
                    id: formik.values._id,
                    data: { ...formik.values }
                }));
            } catch (error) {
                console.error("Error updating offer:", error);
            } finally {
                setIsSubmitting(false);
                formik.setValues(initialValues);

            }
        }
        
        //add
        else {
            //console.log('formik.values on submit',formik.values);
            
            try {
                setIsSubmitting(true);
                await dispatch(addOneOffer({ data: formik.values }));
            } catch (error) {
                console.error("Error adding offer:", error);
                // You can also handle the error here, e.g., show a notification
            } finally {
                setIsSubmitting(false);
                formik.setValues(initialValues);
            }
            
        }

    };

    const formik = useFormik({
        initialValues,
        // validationSchema,
        enableReinitialize: true,
        onSubmit,
    });

    const showItem = (offer) => {
        const periodType = [
            { nameAr: 'سنة', _id: 2 },
            { nameAr: 'شهر', _id: 1 }
        ];
        let offerObj = { ...offer };
        //"user": loggedUser._id,
        //عشان يحافظ علي اليوزر القديم
        offerObj.user = offer.user;

        if (offer.periodType === 1) offerObj.periodTypeObj = periodType[0];
        else offerObj.periodTypeObj = periodType[1];



        formik.setValues(offerObj);
    }



    return [
        formik,
        offers,
        showItem,
        periodTypeOptions,
        resetValues,
        handleUpdateStatus,
        periodsOptions,
        onSubmit,
        isSubmitting
    ];
}
