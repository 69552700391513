import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import AllOffersComponent from "../../Components/offers/AllOffersComponent";

export default function AllOffersPage() {
  const screenID="665f0e3e70673dba321d15fd";
  const loggedUser = JSON.parse(localStorage.getItem('user'));
  //if(!loggedUser?.isAdmin)CheckForPermission(screenID);

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access=true;

  if (access == false) return <Navigate to='/setting/profile?section=Profile' />

  //CheckForPermission(screenID);
  return (
    <div className="d-flex justify-content-start" >
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard" style={{overflow:"hidden"}}>
        <AllOffersComponent />
      </div>

    </div>
  )
}
