import { Button, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { addBalanceToWallet } from "../../redux/slices/wallet/thunk";
import { addNewDeposite } from '../../redux/slices/deposites/thunk';
import { useState } from "react";
import { Spinner } from "react-bootstrap";


export default function SendLiquidationModal({ showModal, setShowModal, latestLiquidation, HandleSendLiquidation }) {

    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));

    const [isLoading,setIsLoading]=useState(false)
    const [isNoLoading,setIsNoLoading]=useState(false)
    const handleAddRecordToWallet = async () => {
        try {
            setIsLoading(true);
            const data = {
                "type": "add",
                "deposits": latestLiquidation?.liquidation_balance_end,
                "sales_id": user?._id
            };
            await dispatch(addBalanceToWallet({ data }));

            const depositeData = {
                user_id: user?._id,
                price: latestLiquidation?.liquidation_balance_end ? Number(latestLiquidation.liquidation_balance_end) * -1 : 0,
                deposit_date: new Date(latestLiquidation?.date_from.split('T')[0]),
                operation_date: new Date(),
                operation_type: null,
                adapter_name: "Mandoub",
                type: 'liquidation',
                status_of_deposit : "accepted",
                is_wallet:true
            };
            if(depositeData.price < 0){
                await dispatch(addNewDeposite({ data: depositeData }));
            }
    
            let liquidationData = {
                status: 'send',
                user_id: user?._id,
                liquidation_balance_end: 0,
                wallet_Data:latestLiquidation?.liquidation_balance_end
            }
    
            HandleSendLiquidation(liquidationData);   
        } catch (error) {
            console.error(error.message)
        }finally {
            setIsLoading(false);
        }
    }


    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} >

            <Modal.Body>
                <div className="label d-block">
                    هل تريد ايداع
                    للمحفظة الخاصة بك
                    <br /> <h5 className="d-flex justify-content-center"> ({latestLiquidation?.liquidation_balance_end}) </h5>
                </div>

                <div className="d-flex justify-content-between">
                    <Button onClick={() => handleAddRecordToWallet()} disabled={isLoading} variant="primary" style={{ backgroundColor: "#0080FB" }}>
                      {isLoading ? <Spinner/> :  "موافق"}
                    </Button>

                    <Button onClick={async () => {
                        try{
                            setIsNoLoading(true)
                            let liquidationData = {
                                status: 'send',
                                user_id: user?._id,
    
                            }
                            await HandleSendLiquidation(liquidationData);
                        } catch(error){
                            console.log("error :", error)
                        } finally{
                            setShowModal(false)
                            setIsNoLoading(false)
                        }
                       
                    }} variant="primary" style={{ backgroundColor: "rgba(246, 149, 33, 1)", border: 'none', width: '16%' }}  disabled={isNoLoading}>
                        {isNoLoading? <Spinner />:"لا"}
                    </Button>

                    <Button onClick={() => setShowModal(false)} variant="danger">
                        الغاء
                    </Button>
                </div>

            </Modal.Body>

        </Modal>
    )
}
