import { useDispatch, useSelector } from "react-redux";
import { updateWithDraw } from "../../redux/slices/withDraws/thunk";
import { addBalanceToWallet } from "../../redux/slices/wallet/thunk";
import { useEffect } from "react";

export default function WithDrawsTable({ withDraws }) {
  const dispatch = useDispatch();

  const { updatedWithDraw  } = useSelector((state) => state.withDrawsReducer);

  useEffect(() => {
    const updateWalletBalance = async () => {
      await dispatch(
        addBalanceToWallet({
          data: {
            type: "sub",
            Withdrawals: updatedWithDraw?.amount_money_withdraw,
            "sales_id": updatedWithDraw?.user_id?._id
          },
        })
      );
    };

    if (updatedWithDraw?.money_withdraw==true && updatedWithDraw?.withdrawal_request=="accepted") {
      updateWalletBalance();
    }
    //console.log("updateWalletBalance")
    
    console.log("updatedWithDraw",updatedWithDraw);
  }, [updatedWithDraw]);

  const handleUpdateStatus = async (status, id) => {
    console.log("id", id, "status", status);
    await dispatch(
      updateWithDraw({
        id,
        data: {
          withdrawal_request: status,
        },
      })
    );

    
  };
  return (
    <div className="permissionsTable mt-3 w-100">
      <table className="w-100">
        <thead>
          <tr>
            <th>اسم المندوب</th>
            <th> المبلغ </th>
            <th> الحالة </th>
            <th>تعديل</th>
          </tr>
        </thead>
        <tbody>
          {withDraws?.map((p, i) => (
            <tr key={i}>
              <td>{p?.user_id?.fullname}</td>
              <td>{p?.amount_money_withdraw}</td>
              <td>
                {(p?.withdrawal_request == "pending" && (
                  <p> في انتظار الموافقة </p>
                )) ||
                  (p?.withdrawal_request == "refused" && <p> مرفوض </p>) ||
                  (p?.withdrawal_request == "accepted" && <p> تم الموافقة </p>)}
              </td>

              <td style={{ color: "#FEAA00", cursor: "pointer" }}>
                <div>
                  <span>
                    <select
                      onChange={(e) =>
                        handleUpdateStatus(e.target.value, p?._id)
                      }
                      disabled={p?.money_withdraw==true && p?.withdrawal_request!=="pending"}
                    >
                      <option value={0}> اختر الحالة </option>
                      <option value={"pending"}> في انتظار الموافقة </option>
                      <option value={"accepted"}> تم الموافقة </option>
                      <option value={"refused"}> ام الرفض </option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
