import add from "../../Images/add.png";
import { useNavigate } from "react-router-dom";
import HeadSection from "../Utilities/HeadSection";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSmsLinkByID, updateOneBank } from "../../redux/slices/banks/thunk";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";
import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";

//getSmsLinkByID
export default function SettingsComponent() {
    const smsID='66c59bde3aa099aa9c6f986b';

    useEffect(()=>{
        dispatch(getSmsLinkByID(smsID));
    },[]);

    
    const{smsLink:sms}=useSelector(state=>state.banksReducer);
    const [showSmsModal, setShowSmsModal] = useState(false);

    const[smsLink,setSmsLink]=useState('');
    const dispatch=useDispatch();
    const navigate = useNavigate();
    
    

    useEffect(()=>{
        setSmsLink(sms?.nameAr);
    },[sms]);

    const handleUpdateSmsLink=async()=>{
           const data={
            nameAr:smsLink
        }
        await dispatch(updateOneBank({data,id:smsID}));
        
        setShowSmsModal(false);
    }

    //console.log('smsLink',smsLink)
    

    const links = [
        { name: 'اضافة نوع بنك', to: '/setting/banks?section=Bank', icon: true },
        { name: 'اضافة نوع عملية ايداع', to: '/setting/banks?section=Deposite', icon: true },
        { name: 'الصفحة الشخصية', to: '/setting/profile?section=Profile', icon: false },
        // {name:'رابط sms',to:'/setting/profile',icon:false},

    ];
    const screenID = "665f102170673dba321d1643";
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    // let access = false;
    // if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
    // else access=true;

    

   

  let hasEdit = false;
  let hasAdd = false;
  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');

  }
  else {
    hasEdit = true;
    hasAdd = true;
  }
  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);
 


    return (
        <div>
            <HeadSection label={'الاعدادات'} />

            <Modal show={showSmsModal} onHide={() => setShowSmsModal(false)} >
                <Modal.Body>                  
                    <div className="label"> رابط sms </div>
                    <input value={smsLink} onChange={(e)=>setSmsLink(e.target.value)}  className="w-100" placeholder="رابط sms" />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center border-0">
                    <Button onClick={()=>handleUpdateSmsLink()} variant="primary" style={{ backgroundColor: "#0080FB" }}>
                        حفظ التغييرات
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="permissionsLayout" style={{ marginTop: '30px' }}>
                {
                    links?.map((el, i) =>
                        <div key={i} className="d-flex justify-content-center mt-4 font">
                            <button
                                onClick={() => navigate(el?.to)}
                                className="setting_btn font"
                            >
                                {el?.name}
                                {
                                    el?.icon &&
                                    <img
                                        src={add}
                                        alt=""
                                        style={{ cursor: "pointer", marginRight: "8px", borderColor: 'black', marginTop: 'auto' }}
                                    />
                                }

                            </button>
                        </div>
                    )}

                <div className="d-flex justify-content-center mt-4 font">
                   {hasEdit !=undefined && hasEdit && <button
                        onClick={ ()=> setShowSmsModal(true)}
                      
                        className="setting_btn font"
                    >
                        
                        رابط sms
                    </button>}
                </div>

            </div>
        </div>
    )
}
