import { createSlice } from "@reduxjs/toolkit";
import {
  addOneMemberShip,
  getAllMembers,
  verifyMemberShip,
  renewMemberShip,
  getReceipt,
  getReport,
  resetVerifiedMemberShip,
  updateMemberShip,
  exportMemberShip,
  getAllMembersWithPagination,
  resetNewMambership
} from "./thunk";

const initialState = {
  members: [],
  getReceipt: {},
  getReport: [],
  mainCard: null,
  verifiedMemberShip: null,
  renewedMemberShip: null,
  isLoading: false,
  exportedMemberShip:[],
  allMembersWithPagination:[]
};

const membersSlice = createSlice({
  initialState,
  name: "membersSlice",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addOneMemberShip.fulfilled, (state, action) => {
      if (action.payload?.main_card == true) {
        console.log("action.payload", action.payload);
        state.mainCard = action?.payload;
      }
      state.isLoading = false;
    });

    builder.addCase(addOneMemberShip.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getAllMembers.fulfilled, (state, action) => {
      state.members = action?.payload;
    });

    builder.addCase(getReceipt.fulfilled, (state, action) => {
      state.getReceipt = action?.payload;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      console.log("action.payload", action?.payload);
      state.getReport = action?.payload;
    });

    builder.addCase(verifyMemberShip.fulfilled, (state, action) => {
      state.verifiedMemberShip = action?.payload;
      state.isLoading = false;
    });

    builder.addCase(verifyMemberShip.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(renewMemberShip.fulfilled, (state, action) => {
      state.renewedMemberShip = action.payload;
      state.isLoading = false;
    });

    builder.addCase(renewMemberShip.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(resetVerifiedMemberShip.fulfilled,(state,action)=>{
      state.verifiedMemberShip=null;
    });

    builder.addCase(updateMemberShip.fulfilled,(state,action)=>{
      state.allMembersWithPagination.data=state.allMembersWithPagination.data.map(el=>
        el?._id?.toString()===action?.payload?._id?.toString() ?
        action?.payload : el
      )      
    });

    builder.addCase(exportMemberShip.fulfilled,(state,action)=>{
     console.log('exportMemberShip action?.payload',action?.payload);

      state.exportedMemberShip=action?.payload;
    });

    builder.addCase(getAllMembersWithPagination.fulfilled,(state,action)=>{
      if(action?.payload?.data){
        state.allMembersWithPagination=action?.payload;
      }
    })
    builder.addCase(resetNewMambership.fulfilled,(state,action)=>{
        state.exportedMemberShip=[];
      
    })
  },
});

export default membersSlice.reducer;
