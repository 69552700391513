import { HiPencilSquare } from "react-icons/hi2";
import AllPermisionsHook from "../../hook/permissions/AllPermisionsHook";
import AddButton from "../Utilities/AddButton";
import Filter from "../Utilities/Filter";
import PdfBox from "../Utilities/PdfBox";
import key from "../../Images/key.png";
import CircleCheckBoxWithIcon from "../Utilities/CircleCheckBoxWithIcon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";


export default function AllPermissionsGroupsComponent() {
  const [
    permissionGroups,
    showModal,
    setShowModal,
    selectedNameGroup,
    setSelectedNameGroup,
    updateID,
    updateField,
    handleAddOrUpdate,
    handleUpdateStatus,
    isLoading
  ] = AllPermisionsHook();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  console.log("isLoading",isLoading)
  console.log("permissions .......", permissionGroups);

  //const userPermissions = JSON.parse(localStorage.getItem("user"))?.availableRoutesArr;
  // let haveAdd = userPermissions.find(el => el.nameEn == 'الصلاحيات')
  //   ?.group_permissions.find(el => el.nameEn == 'إضافة')
  //   ? true : false;

  //if (!haveAdd) window.history.back();

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <h5 className="h5ModalTitle">
            {searchParams.get("add") == "true" ? "اضافة مجموعة" : "تعديل "}
          </h5>

          <div className="label"> اسم المجموعة</div>
          <input
            value={selectedNameGroup}
            onChange={(e) => setSelectedNameGroup(e.target.value)}
            className="w-100"
          />
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center border-0">
          <Button
            onClick={() => handleAddOrUpdate()}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#0080FB" }}
            disabled={isLoading}
          >
             {isLoading && <Spinner />}
            تم
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between firstContainer">
        <h5 className="h5Title">الصلاحيات</h5>
        <div className="d-flex justify-content-between" style={{ gap: "20px", display:"flex", flexWrap:"wrap", marginBottom:"10px"}}>
          <Filter
            gap={"10"}
            types={[
              { text: "اليوم", value: "day" },
              { text: "الشهر", value: "month" },
              { text: "الاسبوع", value: "week" },
            ]}
          />

          <PdfBox />
        </div>
      </div>

      <div className="permissionsLayout">
        <div>
          {
            <AddButton
              backgroundColor={"rgba(254, 170, 0, 1)"}
              color={"white"}
              title={"اضافة مجموعة"}
              onClick={() => {
                searchParams.set("add", true);
                searchParams.set("edit", false);
                setSearchParams(searchParams);
                setSelectedNameGroup("");
                setShowModal(true);
              }}
            />
          }

        </div>
        <div className="permissionsTableHeader">الصلاحيات</div>

        <div className="permissionsTable">
          <table style={{width:"100%"}}>
            <thead>
              <tr>
                <th>اسم المجموعة</th>
                <th>الصلاحيات</th>
                <th>تعديل</th>
                <th>حالة المجموعة</th>
              </tr>
            </thead>
            <tbody>
              {permissionGroups?.map((p, i) => (
                <tr key={i}>
                  <td>{p?.nameAr}</td>
                  <td>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/permissions/edit/${p?._id}`, {
                          state: p,
                        })
                      }
                      src={key}
                      alt=""
                    />
                  </td>

                  <td style={{ color: "#FEAA00", cursor: "pointer" }}>
                    <div
                      onClick={() => {
                        setShowModal(true);
                        setSelectedNameGroup(p?.nameAr);
                        searchParams.set("add", false);
                        searchParams.set("edit", true);
                        updateID.current = p?._id;
                        updateField.current = "name";

                        setSearchParams(searchParams);
                      }}
                    >
                      تعديل{" "}
                      <span>
                        <HiPencilSquare />{" "}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <CircleCheckBoxWithIcon onClick={() => handleUpdateStatus(p)} check={p?.status} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
