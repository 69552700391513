import { useLocation } from "react-router-dom";
import HeadSection from "../../Utilities/HeadSection";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import Box1Component from "./Box1Component";
import Box2Component from "./Box2Component";
import Box3Component from "./Box3Component";

export default function EditLiquidationComponent() {
  const location = useLocation();
  const oneLiquidation = location.state;
  console.log('EditLiquidationComponent',location.state);


  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=800,width=1200');
    printWindow.document.write('<html><head><title>تقرير التصفية</title><style>');
    printWindow.document.write(`
      body { font-family: Arial, sans-serif; font-size: 14px; direction: rtl; margin: 20px; }
      table { width: 100%; border-collapse: collapse; }
      th, td { padding: 10px; text-align: right; border: 1px solid #ddd; }
      .section { margin-bottom: 20px; }
      .title { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
    `);
    printWindow.document.write('</style></head><body>');

    // Add content to the print window
    printWindow.document.write('<div class="section">');
    printWindow.document.write(`<h2 class="title">تصفية رقم (${oneLiquidation?.liquidation_number})</h2>`);
    printWindow.document.write(`<h2 class="title">تصفية  (${oneLiquidation?.sales_id?.fullname})</h2>`);
    printWindow.document.write('</div>');

    printWindow.document.write('<div class="section">');
    printWindow.document.write('<h3 class="title">تفاصيل التصفية</h3>');
    printWindow.document.write(`
      <table>
        <tr><td>مجموع العضويات المصدرة المستحقة</td><td>${oneLiquidation?.num_of_cards} بطاقة</td></tr>
        <tr><td>مجموع المبالغ المستحقة على العضويات</td><td>${oneLiquidation?.money_for_company} ريال</td></tr>
        <tr><td>مجموع مبالغ الإيداعات</td><td>${oneLiquidation?.money_for_deposits} ريال</td></tr>
        <tr><td>عمولة المندوب</td><td>${oneLiquidation?.sales_commission} ريال</td></tr>
        <tr><td>مجموع مبلغ البطاقات المصدرة</td><td>${oneLiquidation?.money_for_company + oneLiquidation?.sales_commission} ريال</td></tr>
        <tr><td>مجموع مبلغ الإيداعات</td><td>${oneLiquidation?.money_for_deposits} ريال</td></tr>
        <tr><td>مجموع المستحق على المستخدم</td><td>${oneLiquidation?.liquidation_balance_end} ريال</td></tr>
      </table>
    `);


    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownloadCSV = () => {
    // Prepare the rows and headers for the CSV
    const headers = [
      "الوصف",
      "القيمة"
    ];
  
    const rows = [
      ["مجموع العضويات المصدرة المستحقة", `${oneLiquidation?.num_of_cards} بطاقة`],
      ["مجموع المبالغ المستحقة على العضويات", `${oneLiquidation?.money_for_company} ريال`],
      ["مجموع مبالغ الإيداعات", `${oneLiquidation?.money_for_deposits} ريال`],
      ["عمولة المندوب", `${oneLiquidation?.sales_commission} ريال`],
      ["مجموع مبلغ البطاقات المصدرة", `${oneLiquidation?.money_for_company + oneLiquidation?.sales_commission} ريال`],
      ["مجموع مبلغ الإيداعات", `${oneLiquidation?.money_for_deposits} ريال`],
      ["مجموع المستحق على المستخدم", `${oneLiquidation?.liquidation_balance_end} ريال`],
    ];
  
    // Convert to CSV format
    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.join(","))
    ].join("\n");
  
    // Create a link element
    const link = document.createElement("a");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
  
    // Set link attributes
    link.setAttribute("href", url);
    link.setAttribute("download", `تقرير_التصفية_${oneLiquidation?.liquidation_number}.csv`);
    
    // Trigger the download
    link.click();
  };

  console.log("oneLiquidation",oneLiquidation)

  return (
    <div className="firstContainer mx-2">
      <HeadSection label={"استعراض التصفية"} />
      <div className='d-flex  justify-content-end  my-4'>
        <PrintAndDownload onPrint={handlePrint} onDownload={handleDownloadCSV}/>
      </div>

      <div className='permissionsLayout w-auto px-4'>
        <div className="d-flex gap-5 mt-2">
          <div className="liquidation-details-p"> تصفية رقم ({oneLiquidation?.liquidation_number}) </div>
          <div className="liquidation-details-p"> تصفية  ( {oneLiquidation?.sales_id?.fullname} ) </div>
          {/* <div className="liquidation-details-p"> الموظف </div> */}
          {/* <div className="liquidation-details-p"> بشار العجاج </div> */}
        </div>

        <hr className="" style={{ color: 'rgba(217, 217, 217, 1)', border: '2px solid' }} />

        <Box1Component item={oneLiquidation} />

        <hr className="" style={{ color: 'rgba(217, 217, 217, 1)', border: '2px solid' }} />
        
        <Box2Component item={oneLiquidation} />

        <Box3Component item={oneLiquidation} />
      

      </div>
    </div>
  )
}
