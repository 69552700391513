import { useEffect, useState } from "react";

export const ProtectedRouteHook= (screenID)=>{
 // const[isAuth,setIsAuth]=useState(false);

 let isAuth=false;

  const user=JSON.parse(localStorage.getItem("user"));

  // useEffect(()=>{
    // let isAuth=false;
     
     console.log('user in protected',user?.permissions);
 
     if(user?._id) isAuth=true;
  // },[]);

  // const user=JSON.parse(localStorage.getItem("user"));
    // let isAuth=false;
     
  //    console.log('user in protected',user?.permissions);

  //    let pageGroup=user?.permissions.find(el=>el?._id==screenID);
  // console.log('pageGroup',pageGroup);
   
  //  console.log('isAuth',isAuth);
    return[isAuth];
    }