import { createSlice } from "@reduxjs/toolkit";
import { getAllSystemScreens } from "./thunk";

const initialState={
    screens:[]
};

const screensSlice=createSlice({
    initialState,
    name:"screensSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllSystemScreens.fulfilled,(state,action)=>{
            state.screens=action?.payload;
        });
    }
});

export default screensSlice.reducer;