import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ChangeStatusModal = ({show,setShow,selectedOrder,updateRequest}) => {
    //console.log("selectedOrder",selectedOrder);
    const[selectedStatus,setSelectedStatus]=useState(null);

    // new_request , paid_request , not_paid_request , exported , archived
    const statusArr=[
        {label_string:'طلب جديد', value:'new_request'},
        {label_string:'طلب مدفوع', value:'paid_request'},
        {label_string:'طلب غير مدفوع', value:'not_paid_request'},
       // {label_string:'تصدير الطلب', value:'exported'},
        {label_string:'ارشفة الطلب', value:'archived'},
    ];

    const handleUpdateStatus=()=>{
        //console.log(e.target.value);
        const data={
            request_status:selectedStatus,
        }

         //console.log("selectedOrder",selectedOrder);

        updateRequest({
            id:selectedOrder,
            data
        });
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
            {/* {isLoading&&<Spinner />} */}
            <h5 className="h5ModalTitle"> تعديل الطلب </h5>
            {
                statusArr?.map((el,i)=><div key={i} className="d-flex gap-2">
                <input type="radio" name="status_radio"
                onChange={(e)=>setSelectedStatus(e.target.value)}
                id={el.value}  value={el.value} />
                <label className="label" htmlFor={el.value}> {el.label_string} </label>
                </div>)
            }
              
        </Modal.Body>
        <Modal.Footer>
            <div className="w-100">
                <Button
                    variant="primary"
                    className="mx-auto d-flex"
                    style={{ background: "rgb(0, 128, 251)" }}
                    onClick={()=>handleUpdateStatus()}
                >
                    تعديل
                </Button>
            </div>
        </Modal.Footer>
    </Modal>
    );
};


export default ChangeStatusModal;