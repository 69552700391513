import { createSlice } from "@reduxjs/toolkit";
import { addPayment, getPaymentMethod, paymentStatus } from "./thunk";

const initialState={
    PaymentResponse:null,
    paymentMethods:[],
    paymentStatusResponse:null
};

const paymentSlice=createSlice({
    initialState,
    name:"paymentSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(addPayment.fulfilled,(state,action)=>{
          state.PaymentResponse=action.payload ;
        });
        builder.addCase(paymentStatus.fulfilled,(state,action)=>{
          state.paymentStatusResponse=action.payload ;
        });
       
        builder.addCase(getPaymentMethod.fulfilled,(state,action)=>{
          state.paymentMethods=action.payload ;
        //   console.log("state.paymentMethod",state.paymentMethod)
        });
       
      
    }
});

export default paymentSlice.reducer;