import { useSelector } from "react-redux";
import PermissionsCheckBox from "../Utilities/PermissionsCheckBox";
import { useParams } from "react-router-dom";

export default function ScreenBoxPermissionComponent({permissionTypes,permArray,setPermArray,screen,groupScreens}) {
    const{id}=useParams();
    const {isLoadingScreen}=useSelector(state=>state.permissionsReducer);

   
    function onChange(e,permissionType){
        console.log('e.target.checked');
        console.log(e.target.checked);

        console.log(screen?.nameAr);
        
        if(e.target.checked){
            console.log('screen',screen?._id);
            console.log('permissionType',permissionType?._id);
            console.log('groupid',id);
            let screenObj={
                group_id:id,
                permissionType_id:permissionType?._id,
                screen_id:screen?._id
            }

            setPermArray(prev=>[...prev,screenObj]);

        }
        else{
            console.log('not checked');
            console.log(screen?.nameAr);
            const filter=permArray.filter(el=>{
                if(el?.screen_id== screen?._id)
                {
                     
                    if(el?.permissionType_id !== permissionType?._id)
                    {
                        return el;
                    }
                }
                else{
                    return el;
                }
            }
            );

            console.log('filter',filter);
            setPermArray(filter);
            
        }
        
    }

    if(isLoadingScreen) return;
    
  return (
    <div className="screenBoxPermission">
        {permissionTypes?.map(el=>(
                 <PermissionsCheckBox 
                 onChange={onChange}
                 key={el?._id} 
                 obj={el}
                 checked={
                    groupScreens?.find(k=>((k?.screen_id===screen?._id) && (k.permissionType_id===el?._id))) 
                     ? true :false
                 }
                  />
        ))}
    </div>
  )
}
