// NotificationMenu.js
import React from "react";
import { Dropdown, NavDropdown } from "react-bootstrap";
import { FaBell, FaDotCircle } from "react-icons/fa";
import "./NotificationMenu.css"; // Optional: for custom styling
import { useNavigate } from "react-router-dom";
import { useUpdateData } from "../../hooks/useUpdateData";
import baseURL from "../../Api/baseURL";

const NotificationMenu = ({
  notifications,
  handleItemClick,
  onToggle,
  dropdownOpen,
}) => {
  const navigate = useNavigate();

  // Calculate total unread notifications
  const unreadCount = notifications.filter(
    (notification) => !notification.seen
  ).length;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateString)
    );
  };

  return (
    <Dropdown style={{ fontFamily: "Cairo" }} onToggle={onToggle}>
      <Dropdown.Toggle
        variant="light"
        id="dropdown-notifications"
        className="bell-toggle"
      >
        <FaBell
          className="bell-icon"
          color={dropdownOpen ? "#0866ff" : "white"}
        />
        {unreadCount > 0 && (
          <span className="notification-counter">{unreadCount}</span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          background: "var(--background-color)",
          color: "var(--text-color)",
        }}
      >
        <Dropdown.Header
          style={{ textAlign: "center", background: "var(--background-color)" }}
        >
          الاشعارات
        </Dropdown.Header>
        {notifications.map((notification, i) => (
          <Dropdown.Item
            key={i}
            onClick={() => handleItemClick(notification)}
            style={{
              fontWeight: notification.seen == true ? null : "bold",
              background: "var(--background-white2)",
            }}
          >
            <div className="notification-item">
              <div style={{ marginRight: "1px", width: "20px" }}>
                {notification.seen == false && <FaDotCircle color="blue" />}
              </div>
              <div className="notification-content">
                <p
                  style={{ direction: "rtl", color: "var(--text-color)" }}
                >{`${notification?.user_id?.fullname} - ${notification.message}`}</p>
                <small style={{ color: "var(--text-color2)" }}>
                  {formatDate(notification.createdAt)}
                </small>
              </div>
              <img
                src={"https://via.placeholder.com/150"}
                alt="Notification"
                className="notification-image"
              />
            </div>
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        {/* <Dropdown.Item href="#/action-1">View All Notifications</Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationMenu;
