import { useEffect, useRef, useState } from "react";
import Input from "../Utilities/Input";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";


export default function RequestCardsComponent({ setRequestCardsArr, requestCardsArr, id, selectedOffer,main_card , isDisabled }) {
  const { id: _id } = useParams();
  const location=useLocation();


  const [name, setName] = useState(()=>_id ? requestCardsArr[id - 999]?.name : '');
  const [mobile, setMobile] = useState(()=>_id ? requestCardsArr[id - 999]?.mobile : '');
  const [mobile2, setMobile2] = useState(()=>_id ? requestCardsArr[id - 999]?.mobile2 : '');
  const [passNum, setPassNum] = useState(()=>_id ? requestCardsArr[id - 999]?.identity_number_passport : '');
  const user = JSON.parse(localStorage.getItem("user"));
  const { oneUser } = useSelector((state) => state.usersReducer);


  const firstRenderRef = useRef(true);

  console.log("mobile",mobile)
  

  function updateFieldsValues() {

    //console.log('update',name);
    let obj = {
      name: name,
      identity_number_passport: passNum,
      mobile: mobile,
      mobile2: mobile2,
      uniqueKey: id,
      _id: requestCardsArr[id - 999]?._id

    };
    
    if(main_card) obj.main_card=true;


  
    if(_id){
      //edit
      console.log('edit obj', obj);
      const cards=requestCardsArr;

      //  for (let index = 0; index < cards.length; index++) {
      //   //const element = array[index];
      //    delete cards[index]?._id
      // }

     
      const j=id-999;
      cards[j]=obj;

      //cards[0]={...cards[0],main_card:true};
      console.log('cards before set',cards);
      // obj._id=requestCardsArr[id - 999]?._id 

      setRequestCardsArr(cards);
     
      // setRequestCardsArr(prev=>{
      //   prev.map((el,i)=>{
      //     const j=id-999;

      //     if(i==j) return obj
      //     else return el
      //   })
      // });

    //   let prev = requestCardsArr.find(el => el?.uniqueKey == id);
    //   if (prev) {
    //     console.log('prev');
    //     setRequestCardsArr(p => p.filter(el => el?.uniqueKey == id ? '' : p));
    //   }
    //   // else{
    //     setRequestCardsArr(p => [...p, obj]);
    //  // }

    }
    else{
      //add
      console.log('obj', obj);
      let prev = requestCardsArr.find(el => el?.uniqueKey == id);
      if (prev) {
        console.log('prev');
        setRequestCardsArr(p => p.filter(el => el?.uniqueKey == id ? '' : p));
      }
      // else{
        setRequestCardsArr(p => [...p, obj]);
     // }
    }

   

  }

  useEffect(() => {
    // console.log('mmmmmmmmmmmmm', firstRenderRef);
     if (firstRenderRef.current == true) return;
    // console.log('name use effect',name);
    // console.log('mobile use effect',mobile);
    // console.log('passNum use effect',passNum);

    updateFieldsValues();
  }, [name, mobile, mobile2, passNum]);

  useEffect(() => {

    if (firstRenderRef.current === true) {
      firstRenderRef.current = false;
      return;
    }
    console.log('selectedOffer use effect');
    setName('');
    setMobile('');
    setMobile2('');
    setPassNum('');
    //setRequestCardsArr([]);

  }, [selectedOffer]);

  let showPhoneNumber = true;
  //  لو محاسب شوف عنده الصلاحية دي ولا لا
  if (!user.liquidation_user && !user.isAdmin) {
    if (oneUser?.show_mobile_in_users_card_table) {
      showPhoneNumber = true;
    } else showPhoneNumber = false;
  }


  // console.log('passNum', passNum);

  return (
    <div>
      <div className="label text-center d-block"> {id > 999 && `البطاقة ${id - 1000 + 1}`}   </div>
      <div className="inputContainer justify-content-between">
        <Input value={name} 
        setValue={setName}
        label={'اسم صاحب الطلب'} 
        width={'45%'}
        classnam={"inputsReduisAndPadding2"} 
        disabled={isDisabled}
       // disabled={user?.userMarketer ? true : false}
        />
        <Input type="number" 
        value={passNum} 
        setValue={setPassNum}
       // disabled={user?.userMarketer ? true : false}
        label={'رقم الهوية او الجواز'} width={'45%'}
        disabled={isDisabled}
        classnam={"inputsReduisAndPadding2"} />
      </div>

     <div className="inputContainer">
      <Input type="number" 
      value={mobile}
      setValue={setMobile} 
      disabled={isDisabled}
      // disabled={user?.userMarketer ? true : false}
      label={'رقم الجوال'} width={'100%'} classnam={"inputsReduisAndPadding2"}/>
    </div>
    {mobile2 != "" && <div className="inputContainer">
      <Input type="number" 
      value={mobile2}
      setValue={setMobile2} 
      disabled={isDisabled}
      // disabled={user?.userMarketer ? true : false}
      label={' رقم الجوال'} width={'100%'} classnam={"inputsReduisAndPadding2"}/>
    </div>}
    </div>
  )
}
