import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import AllLiquidationComponent from "../../Components/liquidation/AllLiquidationComponent";
import SalesLiuidationComponent from "../../Components/liquidation/salesLiquidation/SalesLiuidationComponent";

export default function AllLiquidationPage() {
  const user = JSON.parse(localStorage.getItem("user"));
  const screenID = "665f0f0a70673dba321d1622";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access = true;

  if (access == false)
    return <Navigate to="/setting/profile?section=Profile" />;
  //CheckForPermission(screenID);

  return (
    <div
      className="d-flex justify-content-start"
      style={{ minHeight: "700px" }}
    >
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard" style={{ overflow: "hidden" }}>
        {/* ادمن او محاسب */}
        {user?.isAdmin ? (
          <AllLiquidationComponent />
        ) : (
          !user?.isAdmin &&
          !user?.liquidation_user && <AllLiquidationComponent />
        )}

        {/* صفحة المندوب */}
        {user?.liquidation_user && !user?.isAdmin && (
          <SalesLiuidationComponent />
        )}
      </div>
    </div>
  );
}
