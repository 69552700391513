import { useEffect, useState } from "react";
import HeadSection from "../../Utilities/HeadSection";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import { useDispatch, useSelector } from "react-redux";
import { getAllLiquidation, getNewLiqidationForSalesUser } from "../../../redux/slices/liquidation/thunk";
import TableForSalesUser from "./TableForSalesUser";
import LastLiquidationInfo from "./LastLiquidationInfo";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default function SalesLiuidationComponent() {
    const user = JSON.parse(localStorage.getItem('user'));
    const[isLodaing,setIsLoading]=useState(false);
    const[selectedLiquidation,setSelectedLiquidation]=useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        let query = ``;
        // مندوب
        if (user?.liquidation_user) query += `sales_id=${user?._id}`;
        setIsLoading(true);
        dispatch(getAllLiquidation(query));
        setIsLoading(false);
    }, []);

   

    const liquidation = useSelector(state => state.liquidationReducer.liquidation);

    const latestLiquidation=selectedLiquidation==0 ?
    liquidation?.find(el=> el.status==='new'):selectedLiquidation;

    console.log('latestLiquidation', latestLiquidation);
    console.log('selectedLiquidation',selectedLiquidation);


    const handlePrint = () => {
        const printWindow = window.open('', '', 'height=800,width=1200');
        printWindow.document.write('<html><head><title>تقرير التصفية</title><style>');
        printWindow.document.write(`
          body { font-family: Arial, sans-serif; font-size: 14px; direction: rtl; margin: 20px; }
          table { width: 100%; border-collapse: collapse; }
          th, td { padding: 10px; text-align: right; border: 1px solid #ddd; }
          .section { margin-bottom: 20px; }
          .title { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
        `);
        printWindow.document.write('</style></head><body>');
    
        // Add content to the print window
        printWindow.document.write('<div class="section">');
        printWindow.document.write(`<h2 class="title">تصفية رقم (${latestLiquidation?.liquidation_number})</h2>`);
        printWindow.document.write(`<h2 class="title">مبيعات  (${latestLiquidation?.sales_id?.fullname})</h2>`);
        printWindow.document.write('</div>');
    
        printWindow.document.write('<div class="section">');
        printWindow.document.write('<h3 class="title">تفاصيل التصفية</h3>');
        printWindow.document.write(`
          <table>
            <tr><td>من تاريخ</td><td>${latestLiquidation?.date_from.split("T")[0]} </td></tr>
            <tr><td>إلى تاريخ</td><td>${latestLiquidation?.date_to.split("T")[0]} </td></tr>
            <tr><td>رصيد التصفية السابقة</td><td>${latestLiquidation?.liquidation_balance_start} ريال</td></tr>
            <tr><td>مجموع العضويات المصدرة المستحقة</td><td>${latestLiquidation?.num_of_cards} بطاقة</td></tr>
            <tr><td>مجموع المبالغ المستحقة على العضويات</td><td>${latestLiquidation?.money_for_company} ريال</td></tr>
            <tr><td>مجموع مبالغ الإيداعات</td><td>${latestLiquidation?.money_for_deposits} ريال</td></tr>
            <tr><td>عمولة المندوب على العضويات المصدرة خلال الفترة</td><td>${latestLiquidation?.sales_commission} ريال</td></tr>
            <tr><td> رصيد المحفظة لدى المندوب</td><td>${latestLiquidation?.current_balance} ريال</td></tr>
            <tr><td> رصيد التصفية </td><td>${latestLiquidation?.liquidation_balance_end} ريال</td></tr>
          </table>
        `);
    
    
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
      };


      const handleDownloadAsExcel = () => {
        // Data for the liquidation report
        const data = [
          // الصف الأول: البيانات الأساسية

          // الصف الثاني: العناوين وتفاصيل التصفية
          [ "تصفية رقم ", "مبيعات" , "من تاريخ", "إلى تاريخ", "رصيد التصفية السابقة", "مجموع العضويات", "مبالغ العضويات", "مبالغ الإيداعات", "عمولة المندوب", "رصيد المحفظة", "رصيد التصفية"],
          [
            latestLiquidation?.liquidation_number || "-",
            latestLiquidation?.sales_id?.fullname  || "-",
            latestLiquidation?.date_from?.split("T")[0] || "-",
            latestLiquidation?.date_to?.split("T")[0] || "-",
            `${latestLiquidation?.liquidation_balance_start || 0} ريال`,
            `${latestLiquidation?.num_of_cards || 0} بطاقة`,
            `${latestLiquidation?.money_for_company || 0} ريال`,
            `${latestLiquidation?.money_for_deposits || 0} ريال`,
            `${latestLiquidation?.sales_commission || 0} ريال`,
            `${latestLiquidation?.current_balance || 0} ريال`,
            `${latestLiquidation?.liquidation_balance_end || 0} ريال`,
          ],
        ];
      
        // Create a new workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data); // Convert array of arrays to a worksheet
        XLSX.utils.book_append_sheet(workbook, worksheet, "تقرير التصفية");
      
        // Convert workbook to a Blob and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, `تقرير_التصفية_${latestLiquidation?.liquidation_number || "غير_معروف"}.xlsx`);
      };
      
    
    // const handlePrint = () => {
    //     const printWindow = window.open("", "_blank");
    //     printWindow.document.write("<html><head><title>Print</title>");
    //     printWindow.document.write("<style>");
    //     printWindow.document.write(`
    //       body {
    //         font-family: Arial, sans-serif;
    //         direction: rtl; /* يجعل النصوص تتوافق مع الكتابة العربية */
    //         text-align: right;
    //       }
    //       table {
    //         width: 100%;
    //         border-collapse: collapse;
    //         margin-bottom: 20px;
    //       }
    //       th, td {
    //         border: 1px solid #dddddd;
    //         padding: 8px;
    //         vertical-align: top;
    //       }
    //       .table-header {
    //         background-color: #f2f2f2;
    //         font-weight: bold;
    //         width: 30%; /* عرض ثابت للعناوين */
    //       }
    //       .table-row {
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //       }
    //     `);
    //     printWindow.document.write("</style>");
    //     printWindow.document.write("</head><body>");
    //     printWindow.document.write(`<h2 style='text-align: center;'>${latestLiquidation?.status == 'new' ? 'تصفية جديدة' : 'التصفية'}</h2>`);
    //     printWindow.document.write("<table>");
        
    //     // Table rows with headers and data side by side
    //     printWindow.document.write(`
    //       <tr>
    //         <th class='table-header'>التصفيه</th>
    //         <td>${latestLiquidation?.sales_id?.fullname || ""}</td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>من تاريخ</th>
    //         <td>${latestLiquidation?.date_from?.split("T")[0] || "-"}</td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>إلى تاريخ</th>
    //         <td>${latestLiquidation?.date_to?.split("T")[0] || "-"}</td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>رصيد التصفية السابقة</th>
    //         <td><span>${latestLiquidation?.liquidation_balance_start || 0}</span> <span>ريال</span></td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>مجموع العضويات المصدرة المستحقة</th>
    //         <td><span>${latestLiquidation?.num_of_cards || 0}</span> <span>بطاقة</span></td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>مجموع المبالغ المستحقة علي العضويات</th>
    //         <td><span>${latestLiquidation?.money_for_company || 0}</span> <span>ريال</span></td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>مجموع مبالغ الإيداعات</th>
    //         <td><span>${latestLiquidation?.money_for_deposits || 0}</span> <span>ريال</span></td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>عمولة المندوب على العضويات المصدرة خلال الفترة</th>
    //         <td><span>${latestLiquidation?.sales_commission || 0}</span> <span>ريال</span></td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>رصيد المحفظة لدى المندوب</th>
    //         <td><span>${latestLiquidation?.current_balance || 0}</span> <span>ريال</span></td>
    //       </tr>
    //       <tr>
    //         <th class='table-header'>رصيد التصفية</th>
    //         <td><span>${latestLiquidation?.liquidation_balance_end || 0}</span> <span>ريال</span></td>
    //       </tr>
    //     `);
      
    //     printWindow.document.write("</table>");
    //     printWindow.document.write("</body></html>");
    //     printWindow.document.close();
    //     printWindow.print();
    //   };
      

    return (
        <div className="firstContainer2 mx-2">
            <HeadSection label={"التصفية"} />
            <div className='d-flex  justify-content-end  my-4'>
                <PrintAndDownload onPrint={handlePrint} onDownload={handleDownloadAsExcel}/>
            </div>
               {latestLiquidation &&<LastLiquidationInfo selectedLiquidation={selectedLiquidation} latestLiquidation={latestLiquidation} isLodaing={isLodaing} />} 

                <TableForSalesUser liquidations={liquidation} setSelectedLiquidation={setSelectedLiquidation} />
            <div>
               
            </div>

        </div>
    )
}
