import { useEffect, useState } from "react";
import AddOrEditOrderHook from "../../hook/orders/AddOrEditOrderHook";
import Input from "../Utilities/Input";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import Select from "../Utilities/Select";
import Select3 from "../Utilities/Select3";

import UsersHeader from "../Utilities/UsersHeader";
import RequestCardsComponent from "./RequestCardsComponent";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";
import { getOneUser } from "../../redux/slices/users/thunk";
import html2pdf from 'html2pdf.js';
import { Spinner } from "react-bootstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import styles from '../members/Sales-Report/SalesReport.module.css';







export default function AddAndEditOrderComponent() {
    const [paymentType, formik, countries, cities, offers, requestCardsArr, setRequestCardsArr, selectedOffer, setSelectedOffer, selectedCity, handleCountryChange, selectedCountry, setSelectedCountry, onSubmit, isSubmitting] = AddOrEditOrderHook();
    const [iteratorArr, setIteratorArr] = useState([]);
    const { id } = useParams();

    const location=useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem("user"));


    const activeOffers = offers?.filter(el =>
        el?.status == true && !user?.offers_banned?.includes(el?._id)
    );

    const oneUser=useSelector(state=>state.permissionsReducer.userScreens);
 const oneUser2 = useSelector((state)=>state.usersReducer.oneUser)
    console.log("oneUser2",oneUser2)

   
console.log("oneUser",oneUser)
     // user?.userMarketer

    //console.log('iteratorArr',iteratorArr);
    console.log('requestCardsArr', requestCardsArr);


    console.log("isSubmitting",isSubmitting)


   

    useEffect(() => {
        if (id) {
            console.log('selectedOffer in use effect', selectedOffer);
            setIteratorArr(() => {
                let arr = [];
                for (let i = 0; i < selectedOffer?.numberOfCards - 1; i++) {
                    arr.push(i);
                }
                return [...arr];

            });
        }

    }, []);


    let isDisabled=false;


    let isOrderOwner = true 
    if(location?.state?.user?._id != user?._id){
      isOrderOwner = false
    }
    else {isOrderOwner = true}
    

    console.log("isOrderOwner",isOrderOwner)

    if(location?.state){
        isDisabled=!location?.state?.hasEdit;
    }

    console.log("location?.state?.hasEdit",location?.state?.hasEdit);
    console.log('isDisabled',isDisabled);


    const screenID = "665f0e4d70673dba321d1600";
    const loggedUser = JSON.parse(localStorage.getItem("user"));
  
    let hasEdit = false;
    let hasAdd = false;
    if (!loggedUser?.isAdmin) {
      hasEdit = CheckForPermissionTypes(screenID, 'edit');
      hasAdd = CheckForPermissionTypes(screenID, 'add');
  
    }
    else {
      hasEdit = true;
      hasAdd = true;
    }
  
    console.log('hasEdit', hasEdit);
    console.log('hasAdd', hasAdd);
  
    const handlePrint = () => {
        const cardsContent = requestCardsArr.map((card, index) => `
          <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <tr>
              <th colspan="2" style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">
                البطاقة ${index + 1}
              </th>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">اسم صاحب الطلب</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.name || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">رقم الهوية او الجواز</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.identity_number_passport || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">رقم الجوال</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.mobile || 'غير متاح'}</td>
            </tr>
          </table>
        `).join(''); 
      
        const tableContent = `
          <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <tr>
              <th colspan="2" style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">
                البيانات الاساسية
              </th>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">نوع البطاقة</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${selectedOffer?.offerNameAr || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">الدولة</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${selectedCountry || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">المدينة</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${formik.values.city || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">العنوان</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${formik.values.address || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">طريقة الدفع</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${formik.values.payment_status || 'غير متاح'}</td>
            </tr>
          </table>
        `;
      
        const fullContent = `
          ${tableContent}
          <h2 style="text-align: center;">تفاصيل البطاقات</h2>
          ${cardsContent}
        `;
      
        const printWindow = window.open("", "_blank");
        printWindow.document.write("<html><head><title>Print</title>");
        printWindow.document.write(`<style>
          body { font-family: Arial, sans-serif; direction: rtl; margin: 10px; }
          table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
          th, td { border: 1px solid #dddddd; padding: 8px; text-align: center; }
          th { background-color: #f2f2f2; }
          h2 { text-align: center; }
        </style>`);
        printWindow.document.write("</head><body>");
        printWindow.document.write(fullContent);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.print();
      };
      
      
      
      const handleDownload = () => {
        const cardsContent = requestCardsArr.map((card, index) => `
          <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <tr>
              <th colspan="2" style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">
                البطاقة ${index + 1}
              </th>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">اسم صاحب الطلب</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.name || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">رقم الهوية او الجواز</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.identity_number_passport || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">رقم الجوال</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.mobile || 'غير متاح'}</td>
            </tr>
          </table>
        `).join('');
    
        const tableContent = `
          <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
            <tr>
              <th colspan="2" style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">
                البيانات الاساسية
              </th>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">نوع البطاقة</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${selectedOffer?.offerNameAr || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">الدولة</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${selectedCountry || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">المدينة</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${formik.values.city || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">العنوان</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${formik.values.address || 'غير متاح'}</td>
            </tr>
            <tr>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">طريقة الدفع</th>
              <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${formik.values.payment_status || 'غير متاح'}</td>
            </tr>
          </table>
        `;
    
        const fullContent = `
          <div>
            ${tableContent}
            <h2 style="text-align: center;">تفاصيل البطاقات</h2>
            ${cardsContent}
          </div>
        `;
    
        // Create a container to hold the content for PDF conversion
        const container = document.createElement('div');
        container.innerHTML = fullContent;
        document.body.appendChild(container);
    
        // Convert to PDF using html2pdf.js
        html2pdf()
          .from(container)
          .set({
            margin: 1,
            filename: 'page-content.pdf',
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
          })
          .save()
          .finally(() => {
            // Remove the container after the PDF is downloaded
            document.body.removeChild(container);
          });
    };
    
    console.log("location", location.state);
    const backHandler = () => {
      navigate(`/orders?requestStatus=new_request`);
    }
    

    return (
        <div>

            <div className="my-3 px-1 py-3">
                <UsersHeader hasAdd={location?.state?.hasAdd} title={"اضافة طلب جديد"} currentRoute={'الطلبات'} navigateTo={"/orders/add"} />
            </div>

            <div onClick={backHandler} style={{ display: 'inline-block', width: 'fit-content' }} className={styles.backicon}>
               <span> الرجوع </span> 
            <IoArrowBackOutline />
            </div>
            
            <div className="d-flex justify-content-end firstContainer">
               {!isDisabled&& <PrintAndDownload uploadLabel='رفع الملف' hasExcel={true} onPrint={handlePrint} onDownload={handleDownload} />} 
            </div>
            <div className="permissionsLayout mt-3">

                <div className="inputContainer justify-content-between">
                    <div className="d-flex label justify-content-end align-items-end p-0 mx-auto"> نوع البطاقة </div>


                    <Select3
              name={"offer"}
              classnam={"inputsReduisAndPadding2"}
              onChange={(e) =>
                setSelectedOffer(() => {
                  if (e.target.value == 0) {
                    setIteratorArr([]);
                    return;
                  }
                  const offer = activeOffers?.find((el) => el._id == e.target.value);

                  // console.log('offer',offer);

                  let arr = [];
                  for (let i = 1; i < offer.numberOfCards; i++) {
                    arr.push(i);
                  }
                  setIteratorArr([...arr]);
                  return offer;
                })
              }
              // onChange={(e) => {
              //   let isFound = user?.offers_banned.includes(e.target.value);
              //   console.log("isFound", isFound);
              //   setSelectedOffer(e.target.value);
              // }}
              value={selectedOffer?._id ? selectedOffer?._id : selectedOffer}
              options={activeOffers}
              width={"87%"}
              labelSelect="offerNameAr"
              defaultOption="من فضلك قم ب اختيار البطاقة"
              // border={"1px solid rgba(132, 203, 0, 1)"}
              disabled={location?.state?._id ? true : false}
            />
                    {/* <Select name={'offer'} classnam={"inputsReduisAndPadding2"} onChange={(e) => setSelectedOffer(() => {

                        // let isFound = user?.offers_banned.includes(e.target.value);
                        // console.log('isFound', isFound);
                        // if (isFound) {
                        //     setSelectedOffer(0);
                        //     return notify(" هذا المنتج محظور", "error");
                        // }
                        const offer = activeOffers?.find(el => el._id == e.target.value);
                        let arr = [];
                        for (let i = 0; i < offer.numberOfCards - 1; i++) {
                            arr.push(i);
                        }
                        setIteratorArr([...arr]);
                        return offer;
                    }
                    )}
                        //value={selectedOffer?._id}
                        value={selectedOffer?._id ? selectedOffer?._id : selectedOffer}
                        options={activeOffers}
                        width={'87%'}
                        labelSelect='offerNameAr'
                        defaultOption='من فضلك قم ب اختيار البطاقة'
                        border={'1px solid rgba(132, 203, 0, 1)'}
                        disabled={isDisabled}
                       // disabled={user?.userMarketer ? true : false}
                    /> */}
                </div>

                <RequestCardsComponent
                
                    requestCardsArr={requestCardsArr}
                    setRequestCardsArr={setRequestCardsArr}
                    selectedOffer={selectedOffer}
                    id={999}
                    main_card={true}

                />

                <div className="inputContainer justify-content-between">
                    <Select3  
                    name={'country'} 
                    options={countries} 
                    value={selectedCountry} 
                    onChange={handleCountryChange} 
                    label={'الدولة'} 
                    defaultOption={oneUser?.country?.nameAr}
                    // defaultOption={'من فضلك قم ب اختيار الدولة'} 
                    width={'45%'} 
                    classnam={"inputsReduisAndPadding2"}
                    //disabled={user?.userMarketer ? true : false} 
                    disabled
                    />
                    <Select3  name={'city'} options={cities} onChange={formik.handleChange} value={formik.values.city} label={'المدينة'} defaultOption={'من فضلك قم ب اختيار المدينة'} width={'45%'} classnam={"inputsReduisAndPadding2"}
                   // disabled={user?.userMarketer ? true : false} 
                    />
                </div>

                <div className="inputContainer justify-content-between">
                    <Input name={'address'} value={formik.values.address} onChange={formik.handleChange} label={'العنوان'} width={'45%'} classnam={"inputsReduisAndPadding2"} 
                    // disabled={user?.userMarketer ? true : false} 
                    />
                    <Select3  name={'payment_status'} onChange={formik.handleChange} value={formik.values.payment_status} label={'طريقة الدفع'} options={paymentType} defaultOption={'من فضلك قم ب اختيار طريقة الدفع'} border={'1px solid rgba(254, 170, 0, 1)'} width={'45%'} classnam={"inputsReduisAndPadding2"}
                    // disabled={user?.userMarketer ? true : false} 
                     />
                </div>



                {
                    iteratorArr?.map((el, i) =>
                        <RequestCardsComponent key={i}
                            requestCardsArr={requestCardsArr}
                            setRequestCardsArr={setRequestCardsArr}
                            selectedOffer={selectedOffer}
                            id={el + 1000}
                        />
                    )
                }

                <div className="px-3 my-5">
                  { hasAdd && !location?.state &&
                      <button onClick={() => onSubmit()} className="addButton w-100" disabled={isSubmitting} >   {isSubmitting ? <Spinner /> : "حفظ"}  </button>
                  }  
                  {isOrderOwner && hasEdit && location?.state &&
                      <button onClick={() => onSubmit()}   className={`addButton w-100 `} 
                      disabled={isSubmitting } >   {isSubmitting ? <Spinner /> : "تعديل"} </button>
                  }  
                </div>




            </div>
        </div>
    )


}
