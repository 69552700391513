import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllScreens , getAllPermissionTypes , addPermissionsToScreen, getScreensForOneGroup } from "../../redux/slices/permissions/thunk";
import { useNavigate, useParams } from "react-router-dom";

export default function EditPermissionsHook() {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const {id}=useParams();

  const[permArray,setPermArray]=useState([]);

  useEffect(()=>{
    const get=async()=>{
     await dispatch(getAllScreens());
     await dispatch(getAllPermissionTypes());
     await dispatch(getScreensForOneGroup({id}));
    }
    
    get();

    return ()=>{
      setPermArray([]);
    }
  },[]);

  const {screens,permissionTypes,addPermissionsToScreen : newPermissions , groupScreens,isLoading}=useSelector(state=>state.permissionsReducer);

  console.log('permArray');
  console.log(permArray);

  useEffect(()=>{
    if(groupScreens?.length>0) setPermArray(groupScreens);
  },[groupScreens]);

const handleAddPermissions=async()=>{
  try {
    await dispatch(addPermissionsToScreen({data:{
      permArray:permArray
     }}));
    
     navigate('/permissions');
    //  if(newPermissions.length>0) navigate('/permissions');
  } catch (error) {

    console.error(error.message); 
  }
}

  return[
    screens,
    permissionTypes,
    permArray,
    setPermArray,
    handleAddPermissions,
    groupScreens,
    isLoading
  ];
}
