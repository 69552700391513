import { color } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function Select3({
  label,
  onChange,
  width,
  options = [],
  value,
  onClick,
  disabled = false,
  name,
  lableClass,
  labelSelect = "nameAr",
  defaultOption,
  border,
  flexGrow,
  classnam = null,
  className = null,
  height,
  optionsHeight,
  optionsColor,
  color,
}) {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false); // For dropdown visibility
  const [selectedOption, setSelectedOption] = useState(value); // For storing selected option
  const selectRef = useRef(null);

  console.log("value..........", value);
  console.log("options", options);
  useEffect(() => {
    setSelectedOption(value);
  }, [value]);
  // Toggle the dropdown
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle option selection
  const handleOptionSelect = (selectedValue) => {
    setSelectedOption(selectedValue);
    onChange({ target: { value: selectedValue, name } }); // Mimic onChange event for parent component
    setIsOpen(false); // Close dropdown after selection
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  console.log("selectedOption", selectedOption);
  console.log("defaultOption", defaultOption);
  return (
    <div
      className={`d-flex flex-column ${className}`}
      style={{ width: width, flexGrow: flexGrow }}
    >
      <div className={`label ${lableClass}`}>{label}</div>

      <div
        className={`custom-select ${classnam}`}
        style={{
          border: border,
          color: color,
          height: height,
          cursor: disabled ? "not-allowed" : "pointer",
          position: "relative",
        }}
        ref={selectRef}
        onClick={disabled ? null : toggleDropdown}
      >
        <div className="selected-option" style={{ fontFamily: "Cairo" }}>
          {selectedOption
            ? options.find((opt) => opt?._id === selectedOption)?.[labelSelect]
            : defaultOption || "Select an option"}
        </div>

        <div className={`arrow ${isOpen ? "open" : ""}`}>&#9660;</div>

        {isOpen && !disabled && (
          <div
            className="dropdown2"
            style={{
              fontFamily: "Cairo",
              position: "absolute",
              top: "100%",
              left: 0,
              color: optionsColor,
              maxHeight: optionsHeight,
            }}
          >
            {/* {defaultOption && <div 
                            className={`dropdown-option2 ${selectedOption === 0 ? 'selected' : ''}`} 
                            onClick={() => handleOptionSelect(0)}
                            value={0}
                            >
                            {defaultOption}
                        </div>} */}

            {options?.map((el) => (
              <div
                key={el?._id}
                className={`dropdown-option2 ${
                  selectedOption === el?._id ? "selected" : ""
                }`}
                onClick={() => handleOptionSelect(el?._id)}
                value={el?._id}
              >
                {el?.[labelSelect]}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
