import React, { useState } from "react";
import axios from "axios";
import { BsUpload } from "react-icons/bs";
import { FiPrinter } from "react-icons/fi";
import { MdOutlineFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getAllMembers } from "../../redux/slices/memberShips/thunk";
export default function PrintAndDownload({
  fontWeight,
  // color = "rgba(51, 51, 51, 1)",
  hasExcel = false,
  printLabel = "طباعة",
  uploadLabel = "رفع جدول اكسل",
  downloadLabel = "تنزيل الملف",
  onPrint,
  onDownload,
  excel_download = "تنزيل excel",
  download_excel,
  hasExcelDownload = false,
}) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const [file, setFile] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const { oneUser } = useSelector((state) => state.usersReducer);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setShowSpinner(true); // Show spinner when uploading starts

      const response = await axios.post(
        "https://takafula.com/api/v1/memberships/upload/excel", // Replace with your endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("File uploaded successfully:", response.data);
      alert("File uploaded successfully!");

      let query = ``;
      if (user?.liquidation_user) query += `user=${user?._id}`;
      dispatch(getAllMembers(query));
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file. Please try again.");
    } finally {
      setShowSpinner(false); // Hide spinner after upload completes (success or failure)
    }
  };
  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.setAttribute("type", "file");
    fileInput.setAttribute("style", "display: none");
    fileInput.addEventListener("change", handleFileChange);

    document.body.appendChild(fileInput);
    fileInput.click();

    // Clean up the file input element
    fileInput.remove();
  };

  let showPrint = true;
  //  لو محاسب شوف عنده الصلاحية دي ولا لا
  if (!user?.isAdmin) {
    if (oneUser?.printing_possibility) {
      showPrint = true;
    } else showPrint = false;
  }
  console.log("showPrint", showPrint);

  return (
    <div
      className="d-flex printColor"
      style={{ gap: "20px", fontWeight: fontWeight }}
    >
      {hasExcel && (
        <div>
          <div
            className="d-flex title printColor"
            style={{ gap: "7px", cursor: "pointer" }}
            onClick={handleUploadClick}
          >
            {uploadLabel}
            <span className="borderPrint">
              <BsUpload />
            </span>
          </div>
        </div>
      )}

      {showPrint && (
        <div
          className="d-flex title"
          style={{ gap: "7px", height: "32px", cursor: "pointer" }}
          onClick={onPrint}
        >
          {printLabel}{" "}
          <span className="borderPrint">
            {" "}
            <FiPrinter />{" "}
          </span>
        </div>
      )}

      <div
        className="d-flex title"
        style={{ gap: "7px", width: "120px", cursor: "pointer" }}
        onClick={onDownload}
      >
        {downloadLabel}
        <span className="borderPrint" style={{ height: "32px" }}>
          {" "}
          <MdOutlineFileDownload />{" "}
        </span>
      </div>
      {hasExcelDownload && (
        <div
          className="d-flex title"
          style={{ gap: "7px", cursor: "pointer" }}
          onClick={download_excel}
        >
          {excel_download}
          <span className="borderPrint" style={{ height: "32px" }}>
            {" "}
            <BsUpload />{" "}
          </span>
        </div>
      )}

      {showSpinner && (
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </div>
  );
}
