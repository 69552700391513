import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import AddAndEditUser from "../../Components/users/AddAndEditUserComponent";
import notify from "../../hook/useNotification";

export default function AddUserPage() {
  const loggedUser = JSON.parse(localStorage.getItem('user'));
  const screenID="665f0e6f70673dba321d1606";
  // const add= CheckForPermission(screenID,'add');

  // if(!add){
  //   notify('هذا المستخدم لا يملك الصلاحية لدخول هذه الصفحة',"error");
  //   window.history.back();
  // }  

  let access = false;
    if (!loggedUser?.isAdmin) access = CheckForPermission(screenID,'add');
    else access=true;

    
    if(loggedUser?.userMarketer) access=false;

    if (access == false) return <Navigate to='/setting/profile?section=Profile' />
//  console.log('permissions',permissions);
//   const add=permissions?.permissionTypes?.find(el=>el?.nameEn=='add');

//   console.log('add',add);

  
  return (
    <div className="d-flex justify-content-start">
         <div>
          <SideBar />
        </div>
        <div className="p-3 w-100 dashboard">
            <AddAndEditUser />
        </div>
    </div>
  )
}
