import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";
import UsersHeader from "../Utilities/UsersHeader";
import CreateBoxComponent from "./CreateBoxComponent";
import {useState} from 'react';
import { useFormik } from "formik";
import notify from "../../hook/useNotification";
import { useDispatch, useSelector } from "react-redux";
import { addAgentToUser, getUserAgents, updateAgentUser } from "../../redux/slices/marketing/thunk";



export default function AddUserMarketingComponent() {

  const screenID = "665f0f8070673dba321d162d";
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [mobileError, setMobileError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { userAgents } = useSelector(state => state.marketingReducer);




  let initialValues = {
    sales_id: loggedUser?._id,
    sales_manager_name: loggedUser?.fullname, 
    marketer_name: "",
    email: "",
    phone_number: "",
    amount_of_commission: "",
    start_date: "",
    end_date: "",
    city: "",
    show_all_sales: true
  }

  
  const onSubmit = async (isEdit=false) => {
    console.log("formik",formik)
    console.log("formik.values.start_date",formik.values.start_date)
    console.log("123")

    if(formik.values.start_date>formik.values.end_date){
      return notify("يجب ان يكون تايخ النهايه بعد تاريخ البدايه", "error");

    }
    if (formik.values.phone_number.length < 8) {
      setMobileError(true);
      console.log("formik.values.phone_number.length",formik.values.phone_number.length)
      return notify("برجاء مراجعة رقم الجوال", "error");
    } else {
      setMobileError(false);
    }
    
    //number_of_marketers_allowed

    // اضافة
    if (formik?.values?._id === undefined) {
      if(userAgents?.length> loggedUser?.number_of_marketers_allowed) return notify("لقد تخطيت عدد المسوقين الخاص بك","error");

      setIsLoading(true);
      try {
        await dispatch(addAgentToUser({ data: formik.values }));
        console.log('Agent added successfully');
      } catch (error) {
        console.error('Failed to add agent:', error);
        // Optional: Add error handling, like showing a notification to the user
      } finally {
        setIsLoading(false); // Ensure loading state is reset whether the add operation succeeds or fails
      }
    }
    
    else {
      console.log('uuuuuuuuuuuuu', formik?.values?._id);
          
      setIsLoading(true);
    
      try {
        await dispatch(
          updateAgentUser({
            id: formik?.values?._id,
            data: formik.values
          })
        );
        console.log('Agent updated successfully');
      } catch (error) {
        console.error('Failed to update agent:', error);
        // Optional: Additional error handling (e.g., show a notification to the user)
      } finally {
        setIsLoading(false); // Ensure loading state is reset after completion or error
      }
    }
    

    

    formik.setValues(initialValues);
  }

  const formik = useFormik({
    initialValues,
    //validationschema here
    enableReinitialize: true,
    onSubmit,
  });

  const handleClose = () => {
    formik.setValues(initialValues);
  }


  let hasEdit = false;
  let hasAdd = false;
  let hasDelete = false;

  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');
    hasDelete = CheckForPermissionTypes(screenID, 'delete');

  }
  else {
    hasEdit = true;
    hasAdd = true;
    hasDelete = true;
  }

  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);
  console.log('hasDelete', hasDelete);

 
  return (
    <div>
      <div onClick={handleClose}>
      {
       loggedUser.liquidation_user && hasAdd!=undefined&& hasAdd &&<UsersHeader title={"اضافة مسوق"}  hasAdd={hasAdd} />

      }
      </div>
      

    {loggedUser.liquidation_user &&  <div className="permissionsLayout mt-4 p-0">
        <div className="permissionsTableHeader">
          <div> انشاء مسوق بالعمولة </div>
        </div>
      </div>}
      <CreateBoxComponent hasEdit={hasEdit} hasAdd={hasAdd} hasDelete={hasDelete} formik={formik} onSubmit={onSubmit} initialValues={initialValues} mobileError={mobileError} isLoading={isLoading} userAgents={userAgents} handleClose={handleClose} />
    </div>
  );
}
