import React, { useEffect, useState } from "react";
import { paymentStatus } from "../../redux/slices/payment/thunk";
import { useDispatch, useSelector } from "react-redux";
import { addOneOrder } from "../../redux/slices/orders/thunk";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";

const SendOrderSuccessfully = () => {
  const invoiceId = localStorage.getItem("invoiceId");
  const orderData = JSON.parse(localStorage.getItem("orderDataJson"));
  const loggedUser = JSON.parse(localStorage.getItem('user'));

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const paymentStatusResponse = useSelector(state => state.paymentReducer.paymentStatusResponse);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const counter = parseInt(searchParams.get('counter'), 10); 

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '20px'
  };

  const messageBoxStyle = {
    width: '100%',
    maxWidth: '500px',
    backgroundColor: '#fff',
    fontFamily: "'Cairo', sans-serif",
    fontSize: '1rem',
    fontWeight: '700',
    height: '200px',
    border: '2px solid #000',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: '#333'
  };

  const headingStyle = { marginBottom: '10px' };
  const paragraphStyle = { marginTop: '0', color: '#666' };

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      if (invoiceId) {
        const data = { key: invoiceId, isTest: true };
        try {
          await dispatch(paymentStatus({ data }));
        } catch (err) {
          setError('Failed to fetch payment status.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchPaymentStatus();
  }, [invoiceId, dispatch]);

  useEffect(() => {
    if (paymentStatusResponse) {
      const invoiceStatus = paymentStatusResponse?.Data?.InvoiceStatus;

      if (invoiceStatus === "Paid" && orderData && counter !== 1) {
        dispatch(addOneOrder({ data: orderData }));
        setSearchParams({ counter: 1 }); // Prevents re-triggering on reload
      }
    }
  }, [paymentStatusResponse, orderData, counter, dispatch, setSearchParams]);

  useEffect(() => {
    if (!loading && paymentStatusResponse?.Data?.InvoiceStatus === "Paid" && loggedUser) {
      setTimeout(() => {
        navigate("/orders");
      }, 2000);
    }
  }, [loading, paymentStatusResponse, loggedUser, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={containerStyle}>
      <div style={messageBoxStyle}>
        {paymentStatusResponse?.Data?.InvoiceStatus === "Paid" ? (
          <>
            <h1 style={headingStyle}>تم ارسال الطلب بنجاح</h1>
            <p style={paragraphStyle}>Your order has been successfully sent</p>
          </>
        ) : (
          <h1 style={headingStyle}>Payment Not Confirmed</h1>
        )}
      </div>
    </div>
  );
};

export default SendOrderSuccessfully;
