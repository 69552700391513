import React from "react";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default function SearchComponentFooter({ deposites,archive_page }) {
  const handlePrint = () => {
    const table = document.getElementById("depositesTable");
    if (table) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write(`<style>
          /* Add your CSS styles here */
          body {
            font-family: Arial, sans-serif;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th {
            background-color: #f2f2f2;
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
          }
          td {
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
          }
          tr:nth-child(even) {
            background-color: #f9f9f9;
          }
        </style>`);
      printWindow.document.write("</head><body>");
      printWindow.document.write("<table>");
      printWindow.document.write(table.innerHTML);
      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };


  
  const handleDownloadAsExcel = () => {
    // Data for the liquidation report - first row with headers
    const data = [
      ["رقم العملية", "نوع العملية", "المبلغ", "تاريخ التحويل", "اسم المحول", "اسم المندوب", "البنك", "رقم حساب العميل", "مصدر الحوالة", "الحالة", "الارشفة", "سبب الرفض"]
    ];
  
    // Map the deposites data to an array of values (second row onwards)
    const depositeRows = deposites.map(deposite => [
      deposite?.operation_number,
      deposite?.operation_type == null ? "المحفظه" : deposite?.operation_type,
      deposite?.price,
      deposite?.deposit_date.split("T")[0],
      deposite?.adapter_name,
      deposite?.user_id?.fullname, 
      deposite?.bank_id?.nameAr ? deposite?.bank_id?.nameAr : "لا يوجد",
      deposite?.account_number_for_client ? deposite?.account_number_for_client : "لا يوجد",
      deposite?.deposite_source ? deposite?.deposite_source : "لا يوجد",
      deposite?.status_of_deposit == "accepted" ? "مقبولة" :  deposite?.status_of_deposit == "pending" ? "معلقة" : "مرفوضة",
      deposite?.is_archeived === true ? "مؤرشف" : "غير مؤرشف",
      deposite?.notes ? deposite?.notes : "لا يوجد"
    ]);
  
    // Combine headers and rows into a single array
    const finalData = [...data, ...depositeRows];
  
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(finalData); // Convert array of arrays to a worksheet
    XLSX.utils.book_append_sheet(workbook, worksheet, "الايداعات");
  
    // Convert workbook to a Blob and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, `الايداعات.xlsx`);
  };
  

  const handleDownload = () => {
    // Construct CSV content
    let csvContent =
      "رقم العملية,نوع العملية,المبلغ,تاريخ التحويل,اسم المحول,البنك,رقم حساب العميل,مصدر الحوالة,الحالة\n";
    deposites.forEach((deposite) => {
      csvContent += `${deposite?.operation_number},${
        deposite?.operation_type?.nameAr
      },${deposite?.price},${deposite?.operation_date?.split("T")[0]},${
        deposite?.adapter_name
      },${deposite?.bank_id?.nameAr},${deposite?.account_number_for_client},${
        deposite?.deposite_source
      },${deposite?.status_of_deposit}\n`;
    });

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create an <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "deposits.csv";

    // Programmatically click the <a> element to trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  let acceptedDeposites= archive_page  ? deposites : deposites?.filter(el=>el?.status_of_deposit=="accepted");

  //el?.status_of_deposit=="accepted"&&
  let totalDepositesPrice = 0;
  acceptedDeposites?.map((el) =>(totalDepositesPrice += el?.price));

  return (
    <div className="d-flex mt-1 justify-content-evenly depositesSearchResponsive" style={{marginBottom:"10px"}}>
      <div className="d-flex" style={{ gap: "12px" }}>
        <div className="title " > عدد الحوالات </div>
        <div className="desposites-box">{acceptedDeposites?.length}</div>
      </div>

      <div className="d-flex" style={{ gap: "12px" }}>
        <div className="title"> قيمة الحوالات </div>
        <div className="desposites-box">{totalDepositesPrice} </div>
      </div>

      <div className="d-flex flex-column justify-content-center">
        <PrintAndDownload onPrint={handlePrint} onDownload={handleDownloadAsExcel} />
      </div>
    </div>
  );
}
