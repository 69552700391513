import add from "../../Images/add.png";
import { useNavigate } from "react-router-dom";

export default function AddButton({title,navigateTo,backgroundColor,color,onClick}) {
    const navigate=useNavigate();
  return (
    <div>
        <button
        onClick={() =>{
         if(navigateTo && (navigateTo!='')) navigate(`${navigateTo}`);
         else if(onClick) onClick();
        //  else onClick();
        } }
        className="addButton"
        style={{backgroundColor:backgroundColor,color:color,border:backgroundColor}}
      >
        {title}
        <img
          src={add}
          alt=""
          style={{ cursor: "pointer", marginRight: "8px", borderColor: 'black',marginTop:'auto' }}
          onClick={() => navigate(`${navigateTo}`)}
        />
      </button>
    </div>
  )
}
