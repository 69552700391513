import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersWithPagination } from "../../redux/slices/orders/thunk";
import { useSearchParams } from "react-router-dom";
import { exportMemberShip } from "../../redux/slices/memberShips/thunk";
import { resetNewMambership } from "../../redux/slices/memberShips/thunk";
import notify from "../../hook/useNotification";

export default function OrdersHook() {
  const dispatch = useDispatch();
  const [showExportMemberShipModal, setShowExportMemberShipModal] =
    useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setloading] = useState(true);

  //requestStatus

  useEffect(() => {
    const get_all = async () => {
      let page = 1;
      if (searchParams.get("page") == null || searchParams.get("page") == 0) {
        //console.log('pppppppppppppppppp')
        searchParams.set("page", 1);
        setSearchParams(searchParams);
      } else {
        if (page == 0) page = 1;
        page = searchParams.get("page");
        searchParams.set("page", page);
        setSearchParams(searchParams);
      }
      let query = `page=${page}`;
      //requestStatus=${searchParams.get('requestStatus')}
      query += `&requestStatus=${searchParams.get("requestStatus")}&`;
      if (user?.liquidation_user || user?.userMarketer)
        query += `user=${user?._id}`;
      if (page == 0) return;
      setloading(true);
      await dispatch(getAllOrdersWithPagination(query));
      setloading(false);
    };
    get_all();
    return () => {
      dispatch(resetNewMambership());
    };
  }, []);

  const orders = useSelector(
    (state) => state.ordersReducer.ordersWithPagination
  );
  const exportedMemberShip = useSelector(
    (state) => state.membersReducer.exportedMemberShip
  );
  console.log("exportedMemberShip", exportedMemberShip);

  useEffect(() => {
    if (exportedMemberShip.length == 0) return;
    console.log("exportedMemberShip2", exportedMemberShip);

    if (exportedMemberShip[0]?.success === false) {
      setShowExportMemberShipModal(false);
      notify(
        "لا يمكن تصدير هذه الطلبات لان الحد الائتماني الخاص بك لا يكفي",
        "error"
      );
    } else {
      notify("تم تصدير العضوية بنجاح", "success");
      setShowExportMemberShipModal(true);
    }
  }, [exportedMemberShip]);
  console.log("exportedMemberShip", exportedMemberShip);

  const transferMemberShip = async (order_id) => {
    try {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0"); // Add seconds

      const currentTime = `${hours}:${minutes}:${seconds}`; // Include seconds in the time format
      console.log(`Current Time: ${currentTime}`); // You can log or use this time as needed
      console.log("ccccccccccccccccc", order_id);
      // return;
      const data = {
        request_Id: order_id,
        time: currentTime,
      };
      await dispatch(exportMemberShip({ data }));
      console.log("after dispatch");

      let query = `requestStatus=${searchParams.get(
        "requestStatus"
      )}&page=${searchParams.get("page")}&`;
      if (user?.liquidation_user) query += `user=${user?._id}`;

      await dispatch(getAllOrdersWithPagination(query));
    } catch (error) {
      console.log("error.message", error.message);
    }
  };

  console.log("ordersWithPagination", orders);

  return [
    orders,
    transferMemberShip,
    showExportMemberShipModal,
    setShowExportMemberShipModal,
    pageCount,
    setPageCount,
    loading,
  ];
}
