import { Spinner } from "react-bootstrap";
import Select from "../Utilities/Select";
import LiquidationBtns from "./LiquidationBtns";
import {
  getAllLiquidation,
  getLiquidationForSearch,
} from "../../redux/slices/liquidation/thunk";
import notify from "../../hook/useNotification";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Search2Component({ liquidation }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [liquidationNumberID, setLiquidationNumberID] = useState("");
  const [selectedUsers, setSelectedUsers] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const accountantPage = location.pathname.includes("/accountant");

  // useEffect(() => {
  //     if (accountantPage) dispatch(getLiquidationForSearch(user?._id));
  // }, []);

  let users = useSelector((state) => state.usersReducer.users);
  // const liquidationSearch = useSelector(state => state.liquidationReducer.liquidationSearch);
  // const liquidation=useSelector(state=>state.liquidationReducer.liquidation);

  users = users?.filter((el) => el.liquidation_user && !el.isAdmin);

  const addOrSearchLiquidation = async () => {
    if (new Date(endDate) < new Date(startDate))
      return notify("يجب ان يكون تاريخ البداية اقل من تاريخ النهاية", "error");
    //     //search

    let liquidationNumber = liquidation.find(
      (el) => el._id == liquidationNumberID
    );
    //console.log('liquidationNumber',liquidationNumber);
    // data.usersList=selectedUsers;
    // data.liquidation_number=liquidationNumber?.liquidation_number;
    let query = `${user?._id}?`;

    if (startDate) query += `&date_from=${startDate}`;
    if (endDate) query += `&date_to=${endDate}`;
    if (liquidationNumberID)
      query += `&liquidation_number=${liquidationNumber?.liquidation_number}`;
    if (selectedUsers) query += `&sales_id=${selectedUsers}`;

    setIsLoading(true);
    await dispatch(getAllLiquidation(query));
    setIsLoading(false);
  };

  const canclelFilter = async () => {
    setStartDate("");
    setEndDate("");
    setLiquidationNumberID("");
    setSelectedUsers("");

    let query = ``;
    // محاسب
    if (!user?.isAdmin && !user?.liquidation_user)
      query += `accountant_id=${user?._id}`;
    // مندوب
    else if (user?.liquidation_user) query += `sales_id=${user?._id}`;
    dispatch(getAllLiquidation(query));
  };

  // console.log('liquidationSearch',liquidation);

  return (
    <>
      <LiquidationBtns />
      <div
        className="d-flex justify-content-between  usersSearchBox my-3"
        style={{ width: "100%", flexWrap: "wrap" }}
      >
        {isLoading && <Spinner />}

        <div className="d-flex gap-3" style={{ flexWrap: "wrap" }}>
          <div className="d-flex flex-column justify-content-end">
            <Select
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e.target.value)}
              label={"اسم المستخدم"}
              options={users}
              labelSelect="fullname"
              defaultOption="اسم المستخدم"
              width={"153px"}
              classnam={"inputsReduisAndPadding2"}
            />
          </div>

          <div className="d-flex flex-column justify-content-end">
            <Select
              value={liquidationNumberID}
              onChange={(e) => setLiquidationNumberID(e.target.value)}
              label={"رقم التصفية"}
              options={liquidation}
              labelSelect="liquidation_number"
              defaultOption="رقم التصفية"
              width={"153px"}
              classnam={"inputsReduisAndPadding2"}
            />
          </div>

          <div className="d-flex flex-column">
            <div className="title my-3">تاريخ بدء التصفية</div>
            <input
              value={startDate.replace(/\//g, "-")}
              onChange={(e) => setStartDate(e.target.value.replace(/-/g, "/"))}
              className="title inputsReduisAndPadding2"
              type="date"
              style={{ color: "rgba(121, 121, 129, 1)", width: "153px" }}
            />
          </div>

          <div className="d-flex flex-column">
            <div className="title my-3">تاريخ نهاية التصفية</div>
            <input
              value={endDate.replace(/\//g, "-")}
              onChange={(e) => setEndDate(e.target.value.replace(/-/g, "/"))}
              className="title inputsReduisAndPadding2"
              type="date"
              style={{ color: "rgba(121, 121, 129, 1)", width: "153px" }}
            />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-end">
          <button
            onClick={() => canclelFilter()}
            className="green-filter-btn primary-btn inputsReduisAndPadding2"
            style={{ width: "132px" }}
          >
            {" "}
            الغاء الفلتر{" "}
          </button>
        </div>

        <div className="d-flex flex-column justify-content-end">
          <button
            onClick={() => addOrSearchLiquidation()}
            className="add_desposite_btn add_bank_btn_active inputsReduisAndPadding2"
            style={{ width: "132px" }}
          >
            فلترة
          </button>
        </div>
      </div>
    </>
  );
}
