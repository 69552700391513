import HeadSection from "../Utilities/HeadSection";
import card from "../../Images/membership-card.png";
import Input from "../Utilities/Input";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyMemberShip, renewMemberShip, resetVerifiedMemberShip } from "../../redux/slices/memberShips/thunk";
import { addOneOrder } from "../../redux/slices/orders/thunk";
import { Spinner } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import notify from "../../hook/useNotification";
import { getAllUsers} from '../../redux/slices/users/thunk';


// import { addOneOrder } from "../../redux/slices/orders/thunk";

//verifyMemberShip
export default function CheckMemberComponent() {
    const dispatch = useDispatch();
    const [number, setNumber] = useState('');
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    const oneUser=useSelector(state=>state.permissionsReducer.userScreens);
    const oneUser2 = useSelector((state)=>state.usersReducer.oneUser)
    console.log("oneUser2",oneUser2)

     
  

    const { verifiedMemberShip, isLoading, renewedMemberShip } = useSelector(state => state.membersReducer);

    const isNotReceived = verifiedMemberShip?.membership_status === "not_received";
    const isReceived = verifiedMemberShip?.membership_status === "received";
    console.log("verifiedMemberShip",verifiedMemberShip)
    const reducedAmount = Number(verifiedMemberShip?.offer?.reducedAmount) || 0;
     const commissionAmount = Number(verifiedMemberShip?.offer?.commission) || 0;
     const creditLimit = Number(oneUser2?.father_id?.credit_limit ? oneUser2?.father_id?.credit_limit : oneUser2?.credit_limit) || 0;
    
    const handleUpdateMemberShip = async () => {
        const data = { value: number };

        await dispatch(verifyMemberShip({ data }));
    };

    let users = useSelector(state => state.usersReducer.users);
    console.log("users",users)

    useEffect(() => {
        dispatch(getAllUsers());
        return ()=>dispatch(resetVerifiedMemberShip());
      }, []);
      const adminUsers = users.filter(user => user?.isAdmin);
      console.log("adminUsers", adminUsers[0]);


      let CurrentId = "";
      if(!loggedUser){
          CurrentId = adminUsers[0]
      }    
      else CurrentId = loggedUser?._id;

    const handleRenewMemberShip = async () => {

    //     const isUserAdmin = loggedUser?.isAdmin;
    // const walletCreditLimit = oneUser2?.father_id ? oneUser2?.father_id?.wallet_credit_limit : oneUser2?.wallet_credit_limit;
    // const totalAmount = reducedAmount - commissionAmount + creditLimit;
    // console.log("trywalletCreditLimit",walletCreditLimit)
    // if (!isUserAdmin && walletCreditLimit < totalAmount) {
    //   return notify("لا يمكن إتمام العملية لأنك وصلت إلى أقصى حد للائتمان المتاح لك", "error");
    // }

        // --------- send as memberShip ----------------------
        // const data = {
        //     membership_number: selectedCard?.serial_number,
        //     identity_number_passport: verifiedMemberShip?.identity_number_passport,
        //     user:loggedUser?._id
        // }

        // await dispatch(renewMemberShip({ data }));
    // }
    // -------------- to send as order ------------------------
    const requestCardDetails = (verifiedMemberShip?.membershipCards || []).map(card => ({
        name: card.name,
        identity_number_passport: card.identity_number_passport,
        mobile: card.mobile,
        main_card: card.main_card,
    }));

 
        const data = {
            // user: verifiedMemberShip?.user,
            // user_take_comission: verifiedMemberShip?.user,
            user:CurrentId,
            user_take_comission: CurrentId,
            city: verifiedMemberShip?.city?._id,
            country:verifiedMemberShip?.country,
            offer:verifiedMemberShip?.offer,
            address:" ",
            requestCard:requestCardDetails,
            marketing_link_number: 0,
            payment_status:"cash",
            isRenew:true
            
            // membership_number: main_card_info?.serial_number,
            // identity_number_passport: verifiedMemberShip?.identity_number_passport,
            // payment_status:verifiedMemberShip?.payment_method,
        }
        // await dispatch(renewMemberShip({ data }));
        console.log("data",data)

        await dispatch(addOneOrder({ data }));
    }
        // -------------- to send as order ------------------------


    let main_card_info = verifiedMemberShip?.membershipCards?.find(el => el.main_card == true);
    console.log("membership_status",main_card_info)
    // const data = {
    //     user: userAgentInfo?._id,
    //     user_take_comission: userAgentInfo?._id,
    //     city: selectedCity,
    //     country:selectedCountry,
    //     offer: offerToSend?._id,
    //     address: address,
    //     requestCard: requestCardsArr,
    //     marketing_link_number:searchParams.get('marketing_link_number'),
    //     payment_status:paymentType
    //   };
  

    let selectedCard = verifiedMemberShip?.membershipCards?.find(el => 
    el.serial_number == number || el.identity_number_passport == number);
    console.log("selectedCard",selectedCard)

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleUpdateMemberShip();
        }
      };

    return (
        <div>
            <HeadSection label={"التحقيق من العضوية"} />
            <div className="d-flex justify-content-center align-items-center flex-column">
                <img className="d-block" style={{ width: '280px'}} src={card}  alt="" />
                <h4 className="h4-header" > مرحبا بك</h4>
                <p className="p-text"  style={{ textAlign: 'center'}}>  للتحقق من العضوية يرجي البحث برقم العضوية او رقم الهوية </p>
                <Input
                    value={number}
                    setValue={setNumber}
                    name={'name'}
                    placeholder={'اكتب رقم العضوية او رقم الهوية'}
                    width={'30%'}
                    minWidth={"240px"}
                    classnam={"inputsReduisAndPadding2"}
                    onKeyPress={handleKeyPress}
                    type="number"

                    
                />

                <div className="mt-3 d-flex justify-content-center">
                    <button onClick={() => handleUpdateMemberShip()} className="setting_btn" style={{ width: '132px' }}>
                        {isLoading && <Spinner />}      تحقق
                    </button>
                </div>
                {
                    verifiedMemberShip && <div className="verify my-3" style={{overflow:"auto"}} >
                        <table style={{overflow:"auto"}}>
                            <thead >
                                <tr>
                                    <th> رقم العضوية </th>
                                    <th> العضوية الاساسية </th>
                                    <th> الهوية الاساسية </th>
                                    <th> البطاقات المرفقة </th>
                                    <th> تاريخ الانتهاء </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedCard?.serial_number}</td>
                                    <td>{selectedCard?.name}</td>
                                    <td>{selectedCard?.identity_number_passport}</td>
                                    <td> {verifiedMemberShip?.membershipCards.length} <span className="mx-1" style={{ color: 'rgba(121, 121, 129, 1)' }}> <FaCaretDown size={25} /> </span> </td>
                                    <td>{verifiedMemberShip?.end_date.split('T')[0]}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="mt-3 d-flex justify-content-center">
                            {new Date() > new Date(verifiedMemberShip?.end_date.split('T')[0])
                                ? <div>
                                    <div style={{color:"#F0142F", fontSize:"20px", fontFamily:"Cairo", fontWeight:"600"}}>العضوية منتهية</div>
                                   { <button onClick={() => handleRenewMemberShip()} className="green-filter-btn my-2" style={{ width: '132px' }}>
                                        {isLoading && <Spinner />}
                                        تجديد العضوية
                                    </button>}
                                </div> : isNotReceived ?
                                (<div style={{color:"#F0142F", fontSize:"20px", fontFamily:"Cairo", fontWeight:"600"}}> عضوية غير فعالة </div>)                           
                                :
                                   <>
                                    <button className="green-filter-btn my-2" style={{ width: '132px' }}>
                                        {isLoading && <Spinner />}
                                          العضوية سارية
                                    </button>
                                </>}


                        </div>
                    </div>


                }


            </div>
        </div>
    )
}
