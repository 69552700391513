import { useEffect, useRef, useState } from "react";
import AddNewMemberHook from "../../../hook/members/AddNewMemberHook";
import AddMemberShipBtn from "./AddMemberShipBtn";
import Header from "./Header";
import MemberCardComponent from "./MemberCardComponent";
import style from "../../../Pages/orders/form.module.css";
import NewOrdersHook from "../../../hook/orders/NewOrdersHook";
import notify from "../../../hook/useNotification";
import { addOneMemberShip, updateMemberShip } from "../../../redux/slices/memberShips/thunk";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CheckForPermissionTypes from "../../Utilities/CheckForPermissionTypes";
import { getAllCities } from "../../../redux/slices/cities/thunk";
import { getOneUser } from "../../../redux/slices/users/thunk";





export default function AddMemberComponent() {
  const [offers , numberOfCards, setNumOfCards, selectedOffer, setSelectedOffer, memberComponentNum, setMemberComponentNum, isLoading] = AddNewMemberHook();
  //  const [offers, selectedOffer, setSelectedOffer, requestCardsArr, setRequestCardsArr, cities, countries, selectedCity, setSelectedCity, selectedCountry, setSelectedCountry, handleCountryChange, address, setAddress, paymentType,
  //   setPaymentType, onSubmit] = NewOrdersHook();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false); 
  // const [emptyFlag, setEmptyFlag] = useState(false); 



  const oneUser=useSelector(state=>state.permissionsReducer.userScreens);


  const [iteratorArr, setIteratorArr] = useState(() => {
    if (location.state) {
      let arr = [];
      for (let i = 0; i < location?.state?.membershipCards.length; i++) {
        arr.push(i);
      }

      // console.log('arr in use state',arr);

      return [...arr];
      // setIteratorArr([...arr]);


    }
    else {
      return [];
    }
  }

  );
  const [requestCardsArr, setRequestCardsArr] = useState([]);
  const [sendFrom, setSendFrom] = useState(
    location?.state?.sent_by ? location?.state?.sent_by : ""
  );
  console.log("location",location)

  const [selectedCountry, setSelectedCountry] = useState(
    location?.state?.city?.country ? location?.state?.city?.country : 0
  );
  const [selectedCity, setSelectedCity] = useState(
    location?.state?.city ? location?.state?.city : 0
  );

  const { liquidationUsers } = useSelector(
    (state) => state.usersReducer
  );
  const oneUser2 = useSelector((state)=>state.usersReducer.oneUser)
console.log("oneUser2",oneUser2)
  const firstRender = useRef(true);

  const dispatch = useDispatch();

  useEffect(()=>{
    if(!user?.isAdmin){
      if(oneUser?._id){
         dispatch(getAllCities(oneUser?.country?._id));
         setSelectedCountry(oneUser?.country?._id);
      }
    }
  },[oneUser]);

  useEffect(() => {
    // if(location?.state?._id)

    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    // console.log('useeeeeeeeeeeeeee');
    setRequestCardsArr([]);
    // setSendFrom("");
    // setSelectedCountry(0);
    // setSelectedCity(0);
    // setIteratorArr(true);
  }, [iteratorArr]);

  const { offers: userOffers } = useSelector(state => state.offersReducer);

  const reducedAmount = Number(selectedOffer?.reducedAmount) || 0;
  const commissionAmount = Number(selectedOffer?.commission) || 0;
  const deposits = Number(selectedOffer?.deposits) || 0;
  const creditLimit = Number(oneUser2?.father_id?.credit_limit ? oneUser2?.father_id?.credit_limit : oneUser2?.credit_limit) || 0;

  console.log("deposits",deposits)

  console.log("selectedOffer",selectedOffer)
  console.log("try2",commissionAmount)
  console.log("try3",creditLimit)
  console.log("try4Mosref",oneUser?.father_id?.wallet_credit_limit)
  console.log("try4Mandoub",oneUser?.wallet_credit_limit)

  
  const user = JSON.parse(localStorage.getItem("user"));

  const activeOffers = offers?.filter(el =>
    el?.status == true && !user?.offers_banned?.includes(el?._id)
  );
  // if(wallet_credit_limit<){

  // }
  console.log("selectedCountry",selectedCountry)
  console.log("offersuserlimit",user.wallet_credit_limit)

  const onSubmit = async () => {

    
    if(selectedOffer == 0){
      return notify("يرجاء اختيار نوع العرض","error")
    }


    for (let i = 0; i < requestCardsArr.length; i++) {
      if (requestCardsArr[i]?.name == "") {
        return notify("يرجاء ادخال  اسم صاحب الطلب","error");
      }
    }
    for (let i = 0; i < requestCardsArr.length; i++) {
      if (requestCardsArr[i]?.identity_number_passport == "") {
        return notify("يرجاء ادخال رقم الهوية او الجواز  ","error");
      }
    }
    for (let i = 0; i < requestCardsArr.length; i++) {
      if(selectedCountry == "66c33878e67a6fe5f149fdec"){
        if (requestCardsArr[i]?.identity_number_passport.length != 10 ) {
          return notify("يرجاء ادخال رقم الهوية او الجواز المكون من ١٠ ارقام  ","error");
        }
      }
     
    }


    // if (requestCardsArr?.mobile =="") {
    //   return notify("برجاء مراجعة رقم الجوال", "error");
    // }
    console.log("requestCardsArr",requestCardsArr)


    const isUserAdmin = user?.isAdmin;
    const walletCreditLimit = oneUser2?.father_id ? oneUser2?.father_id?.wallet_credit_limit : oneUser2?.wallet_credit_limit;
    const totalAmount = reducedAmount - commissionAmount + creditLimit - deposits;
    console.log("trywalletCreditLimit",walletCreditLimit)
    if (!isUserAdmin && walletCreditLimit < totalAmount) {
      return notify("لا يمكن إتمام العملية لأنك وصلت إلى أقصى حد للائتمان المتاح لك", "error");
    }
    
    
  
    for (let i = 0; i < requestCardsArr.length; i++) {
      if (requestCardsArr[i]?.mobile?.length != 10 && selectedCountry == "66c33878e67a6fe5f149fdec") {
        return notify("برجاء مراجعة رقم الجوال", "error");
      }
    }

    if(selectedCity=='0' || selectedCountry=='0') return notify("يجب اختيار البلد والمدينة", "error");

    //  console.log("requestCardsArr", requestCardsArr);
    let requestCardsArrSorted = requestCardsArr.sort((a, b) => a.uniqueKey - b.uniqueKey);

    console.log('requestCardsArrSorted', requestCardsArrSorted);

    // return;

    //  console.log("selectedOffer", selectedOffer);
    // if (selectedOffer?.numberOfCards !== requestCardsArr.length)
    //   return notify("يجب ادخال جميع البطاقات", "error");


    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0'); // Add seconds

    
    const currentTime = `${hours}:${minutes}:${seconds}`; // Include seconds in the time format
    console.log(`Current Time: ${currentTime}`); // You can log or use this time as needed



    let offerToSend = '';
    if (user?.isAdmin || user?.is_accountant) {
      offerToSend = selectedOffer;
    }
    else {

      offerToSend = userOffers?.find(el => el?.offer?._id == selectedOffer?._id);
      console.log('offerToSend', offerToSend);
      offerToSend = offerToSend?._id;
    }

    let data = {
      user: user?._id,
      city: selectedCity,
      country: selectedCountry,
      offer: offerToSend,
      name: requestCardsArrSorted[0]?.name,
      identity_number_passport: requestCardsArrSorted[0]?.identity_number_passport,
      mobile: requestCardsArrSorted[0]?.mobile,
      member_cards: requestCardsArrSorted,
      time:currentTime
    };
    console.log("user?._id,",user?._id,)
// مندوب
      if(sendFrom?.fullname) data.sent_by = sendFrom?.fullname;  
      //  مشرف
      // if(sendFrom?.father_id) data.sent_by = sendFrom?.father_id?.fullname;        
       else data.sent_by = sendFrom.trim();

    
    console.log("data--------------",data)

// return
    // edit
   
    setIsSubmitting(true);

    try {
        let response;

        // Check if you're updating or adding a membership
        if (location?.state?._id) {
            response = await dispatch(updateMemberShip({ id: location?.state?._id, data }));
        } else {
            response = await dispatch(addOneMemberShip({ data }));
        }

        console.log("API response:", response); 

        if (updateMemberShip.fulfilled.match(response) || addOneMemberShip.fulfilled.match(response)) {
            setSelectedOffer(0);
            setSelectedCountry(0);
            setRequestCardsArr([]);
            navigate('/members'); 
        }
    } catch (error) {
        console.error('Failed to process membership:', error);
    } finally {
        setIsSubmitting(false); 
    }
  }

  // console.log('selectedOffer',selectedOffer);
  // console.log('userOffers',userOffers);

  console.log('sendFrom', sendFrom);
  // console.log('countries');

  console.log('location', location?.state);
  //--------------------MOKHTAR TEST ---------------------
  const screenID = "665f0e0970673dba321d15f7";

  let hasEdit = false;
  let hasAdd = false;
  if (!user?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');

  }
  else {
    hasEdit = true;
    hasAdd = true;
  }

  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);

  //--------------------MOKHTAR TEST ---------------------
  //-------------------------
  // let sendFrom2;
  // if (typeof sendFrom === 'string') {
  //   sendFrom2 = sendFrom.trim();
  //   console.log('sendFrom2',sendFrom2);
  // }
  //-------------------------


console.log("user?.father_id",user?.father_id)
  useEffect(()=>{
    if(user?.father_id){
      dispatch(getOneUser({id:user?.father_id}));
    }
    
  },[]);



  
  const setStateEmpty = () => {
    console.log("setStateEmpty");
if(!location?.state){
  setNumOfCards(null);
  setMemberComponentNum(null);
  
  setSendFrom("");
  setSelectedOffer(0)
  setSelectedCity(0);
  setRequestCardsArr([]);
  setIteratorArr([]);
  // setEmptyFlag(true)

  if(user?.isAdmin){
    setSelectedCountry(0);

  }
}

  };
  console.log("selectedOffer",selectedOffer)
  console.log("selectedCity",selectedCity)

  
  useEffect(() => {
    if (location?.state === null) {
      setNumOfCards(null);
      setMemberComponentNum(null);
      setSendFrom("");
      setSelectedOffer(0);
      setSelectedCity(0);
      setRequestCardsArr([]);
      setIteratorArr([]);
  
      if (user?.isAdmin) {
        setSelectedCountry(0);
      }
    }
  }, [location?.state]);
  



  return (
    <div>


      {!location?.state &&<Header setStateEmpty={setStateEmpty}/>}
      {isLoading ?
        <Spinner />
        :
        <>
          {
            <MemberCardComponent id={1000}
              main_card={true}
              sendFrom={sendFrom} setSendFrom={setSendFrom}
              selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
              selectedCity={selectedCity} setSelectedCity={setSelectedCity}
              requestCardsArr={requestCardsArr} setRequestCardsArr={setRequestCardsArr}
              setIteratorArr={setIteratorArr}
              offers={activeOffers} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer}
              isLoading={isLoading} 
              hasAdd={hasAdd}
              hasEdit={hasEdit}
              // setEmptyFlag={setEmptyFlag}
              // emptyFlag={emptyFlag}
              />
          }
          {/* <AddMemberShipBtn selectedOffer={selectedOffer} offers={activeOffers} numberOfCards={numberOfCards} setNumOfCards={setNumOfCards} memberComponentNum={memberComponentNum} setMemberComponentNum={setMemberComponentNum} /> */}

          {
            iteratorArr?.length > 0 && iteratorArr?.map((el, i) =>
              i > 0 && <MemberCardComponent key={i}
                requestCardsArr={requestCardsArr}
                setRequestCardsArr={setRequestCardsArr}
                sendFrom={sendFrom} setSendFrom={setSendFrom}
                selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
                selectedCity={selectedCity} setSelectedCity={setSelectedCity}
                selectedOffer={selectedOffer}
                id={el + 1000}
                setIteratorArr={setIteratorArr}
                offers={activeOffers}
                setSelectedOffer={setSelectedOffer}
                isLoading={isLoading}
                hasAdd={hasAdd}
                hasEdit={hasEdit}
              />
            )
          }

        </>
      }

     {location?.state == null && hasAdd && !isLoading &&
      <button onClick={() => onSubmit()} 
      className="customButton save w-100" disabled={isSubmitting}>  {isSubmitting &&  <Spinner />}   حفظ  </button>}
     {location?.state && hasEdit && !isLoading &&  <button 
     onClick={() => onSubmit()} 
     className="customButton save w-100" disabled={isSubmitting}> {isSubmitting &&  <Spinner />} تعديل  </button>}

       {/* hasAdd
       hasEdit */}
    </div>
  )
}
