import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";

export default function Select2({ 
    label, 
    onChange, 
    width, 
    options=[], 
    value, 
    disabled = false, 
    name, 
    lableClass, 
    labelSelect = 'nameAr', 
    labelSelect2 = 'bankAccountNumber', 
    defaultOption, 
    border, 
    flexGrow, 
    classnam = null 
}) {
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);  // Manage dropdown visibility
    const [selectedOption, setSelectedOption] = useState(value); // Store selected option
    const selectRef = useRef(null);

    console.log('value..........', value);
    console.log('options', options);

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    // Handle option selection
    const handleOptionSelect = (selectedValue) => {
        setSelectedOption(selectedValue);
        onChange({ target: { value: selectedValue, name } }); // Mimic onChange event for parent component
        setIsOpen(false);  // Close dropdown after selection
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`d-flex flex-column`} style={{ width: width, flexGrow: flexGrow }}>
            <div className={`label ${lableClass}`}>{label}</div>

            <div 
                className={`custom-select ${classnam}`} 
                style={{ border: border, cursor: disabled ? 'not-allowed' : 'pointer', position: 'relative' }} 
                ref={selectRef} 
                onClick={toggleDropdown}
            >
                <div className="selected-option">
                    {selectedOption ? 
                        `${options.find(opt => opt?._id === selectedOption)?.[labelSelect]} - ${options.find(opt => opt?._id === selectedOption)?.[labelSelect2]}` 
                        : defaultOption || 'Select an option'}
                </div>
                
                <div className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</div>
                
                {isOpen && !disabled && (
                    <div className="dropdown2" style={{ position: 'absolute', top: '100%', left: 0 }}>
                        {defaultOption && (
                            <div 
                                className={`dropdown-option2 ${selectedOption === 0 ? 'selected' : ''}`} 
                                onClick={() => handleOptionSelect(0)}
                            >
                                {defaultOption}
                            </div>
                        )}
                        
                        {options?.map((el) => (
                            <div 
                                key={el?._id} 
                                className={`dropdown-option2 ${selectedOption === el?._id ? 'selected' : ''}`} 
                                onClick={() => handleOptionSelect(el?._id)}
                            >
                                {el?.[labelSelect]} - {el?.[labelSelect2]}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
