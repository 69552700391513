import { useEffect, useState } from "react";
import AddButton from "../../Utilities/AddButton";
import Filter from "../../Utilities/Filter";
import PdfBox from "../../Utilities/PdfBox";
import RenewMembershipModal from "./RenewMembershipModal";
import { useDispatch } from "react-redux";
import { resetVerifiedMemberShip } from "../../../redux/slices/memberShips/thunk";

export default function Header({setStateEmpty}) {
  const[showModal,setShowModal]=useState(false);
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(resetVerifiedMemberShip());
  },[]);

  const submit =()=>{
    console.log("setStateEmpty")
  }

  return (
    <div className={`d-flex ${'justify-content-between'} firstContainer`}>
      {showModal&&<RenewMembershipModal showModal={showModal} setShowModal={setShowModal} />}
      {
        <>
        <AddButton onClick={()=>setStateEmpty()}  navigateTo={''} className={"responsiveFont"} title={'اضافة عضوية جديدة'} backgroundColor={'rgba(254, 170, 0, 1)' } color={'white'}  />
        { <AddButton  className={"responsiveFont"} title={'تجديد عضوية'} 
        onClick={()=>setShowModal(true)}
        navigateTo={''}
         backgroundColor={'#0d6efd'} color={'white'}  />}
        </>
        
      }
     

    <div className="d-flex justify-content-between" style={{gap:'20px', flexWrap:'wrap'}}>
    <Filter types={[
        {text:'اليوم',value:'day'},
        {text:'الشهر',value:'month'},
        {text:'الاسبوع',value:'week'}
    ]} />

    <PdfBox />

    </div>
   
    </div>
  )
}
