import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import SettingsComponent from "../../Components/settings/SettingsComponent";
import { getSmsLinkByID } from "../../redux/slices/banks/thunk";


export default function SettingsPage() {
    const screenID="665f0fa570673dba321d1633";
    const loggedUser = JSON.parse(localStorage.getItem('user'));
  //if(!loggedUser?.isAdmin)CheckForPermission(screenID);

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access=true;

  if (access == false) return <Navigate to='/setting/profile?section=Profile' />
    //CheckForPermission(screenID);
    return (
        <div className="d-flex" style={{overflow:'hidden'}}>
            <div>
                <SideBar />
            </div>
            <div className="p-3 w-100 dashboard">
                <SettingsComponent />
            </div>

        </div>
    )
}
