import { useDispatch, useSelector } from "react-redux";
import HeadSection from "../../Utilities/HeadSection";
import DepositesTable from "../DepositesTable";
import SearchComponent from "../SearchComponent";
import { useEffect } from "react";
import { getAllDepositesForUser, getAllDepositesForUserWithPagination } from "../../../redux/slices/deposites/thunk";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../Utilities/Pagination";

export default function ArchievesComponent({ type }) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const[searchParams,setSearchParams]=useSearchParams();


  useEffect(() => {
    let page = 1;
    if (searchParams.get('page') == null || searchParams.get('page') == 0) {
      page = 1;
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
    else {
      page = searchParams.get('page');
    }


    if (type === "accountant" || user?.isAdmin) {
      let query=`page=${page}`;
      dispatch(getAllDepositesForUserWithPagination(query));
    } else {
      const userID=user?._id;
      let query=`user_id=${userID}&page=${page}`
      dispatch(getAllDepositesForUserWithPagination(query));
    }
  }, [dispatch]);

  const nextPage = async (page) => {
    if (page == 0) return;

    searchParams.set('page', page);
    setSearchParams(searchParams);
    if (type == 'accountant' || user?.isAdmin) {
      //const userID=user?._id;
      let query = `page=${page}`;

      if (searchParams.get('startDate') !== null) {
        query += `${query}&start_date=${searchParams.get('startDate')}`;
        // searchParams.set('startDate',startDate);
      }
      if (searchParams.get('endDate') !== null) {
        query += `${query}&end_date=${searchParams.get('endDate')}`;
        //searchParams.set('endDate',endDate);
      }
      if (searchParams.get('bank_id') !== null) {
        query += `${query}&bank_id=${searchParams.get('bank_id')}`;
        // searchParams.set('bank_id',bankID);
      }
      if (searchParams.get('operation_type') !== null) {
        query += `${query}&operation_type=${searchParams.get('operation_type')}`;
        //searchParams.set('operation_type',operationType);
      }
      if (searchParams.get('status_of_deposit') !== null) {
        //searchParams.set('status_of_deposit',selectedDepositeStatus);
        query += `${query}&status_of_deposit=${searchParams.get('status_of_deposit')}`;
      }

      if (searchParams.get('user_id') !== null) {
        //console.log('iiiiiiiiiiiiiiiiii')
        //searchParams.set('user_id',selectedUsers);
        query += `${query}&user_id=${searchParams.get('user_id')}`;
      }

      if (searchParams.get('deposite-type') !== null) query += `&status_of_deposit=${searchParams.get('deposite-type')}`;
      dispatch(getAllDepositesForUserWithPagination(query));
    }
    else {
      //مندوب
      const userID = user?._id;
      let query = `user_id=${userID}&page=${page}`;
     // let query = `page=${page}&`;

      if (searchParams.get('startDate') !== null) {
        query =+ `${query}&start_date=${searchParams.get('startDate')}`;
        // searchParams.set('startDate',startDate);
      }
      if (searchParams.get('endDate') !== null) {
        query =+ `${query}&end_date=${searchParams.get('endDate')}`;
        //searchParams.set('endDate',endDate);
      }
      if (searchParams.get('bank_id') !== null) {
        query =+ `${query}&bank_id=${searchParams.get('bank_id')}`;
        // searchParams.set('bank_id',bankID);
      }
      if (searchParams.get('operation_type') !== null) {
        query =+ `${query}&operation_type=${searchParams.get('operation_type')}`;
        //searchParams.set('operation_type',operationType);
      }
      if (searchParams.get('status_of_deposit') !== null) {
        //searchParams.set('status_of_deposit',selectedDepositeStatus);
        query =+ `${query}&status_of_deposit=${searchParams.get('status_of_deposit')}`;
      }

      // if (searchParams.get('user_id') !== null) {
      //   //console.log('iiiiiiiiiiiiiiiiii')
      //   //searchParams.set('user_id',selectedUsers);
      //   query = `${query}&user_id=${searchParams.get('user_id')}`;
      // }

      if (searchParams.get('deposite-type') !== null) query += `&status_of_deposit=${searchParams.get('deposite-type')}`;

      dispatch(getAllDepositesForUserWithPagination(query));
    }
  }

  const deposites = useSelector(state => state.depositesReducer.depositesWithPaginatioon);

  const archeived_deposites = deposites?.data?.filter((el) => el.is_archeived === true);

  return (
    <div>
      <HeadSection label={"ارشيف الايداعات"} />
      <div className="permissionsLayout mt-3">
        <SearchComponent deposites={archeived_deposites} />
        <div style={{overflow:"auto"}}>
        <DepositesTable deposites={archeived_deposites} pageType={type} />
        </div>

        <div>
          <Pagination onPress={nextPage} pageCount={deposites?.num_of_pages} />
        </div>
      </div>
    </div>
  );
}
