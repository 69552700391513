import HeadSection from "../Utilities/HeadSection";
export default function MedicalSystemComponent() {
  const cities = [
    { nameAr: "الرياض" },
    { nameAr: "جدة" },
    { nameAr: "تبوك" },
    { nameAr: "جدة" },
    { nameAr: "الرياض" },
  ];
  return (
    <div>
      <HeadSection label={"الشبكات الطبية"} />
      <div className="medicalSystemLayout my-3">
        <div className="container">
          <div className="customRow">
            {cities.map((el, i) => (
              <div key={i} className="customCol my-2">
                <div className="cityCard">
                  <div className="city-container" />
                  <div className="cityName">
                    <div>{el.nameAr}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
