import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import AddUserMarketingComponent from "../../Components/marketing/AddUserMarketingComponent";

export default function AddMaketingUserPage() {
  const screenID = "665f0f8070673dba321d162d";
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  // if(!loggedUser?.isAdmin)CheckForPermission(screenID);

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access = true;

  if (access == false)
    return <Navigate to="/setting/profile?section=Profile" />;
  //CheckForPermission(screenID);
  return (
    <div
      className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4 w-100 backgroundForDarkMode" style={{ overflow: "hidden" }}>
        <AddUserMarketingComponent />
      </div>
    </div>
  );
}
