import { useLocation } from "react-router-dom";
import Filter from "../Utilities/Filter";
import PdfBox from "../Utilities/PdfBox";
import EditPermissionsHook from "../../hook/permissions/EditPermissionsHook";
import ScreenBoxPermissionComponent from "./ScreenBoxPermissionComponent";


export default function EditPermissionsComponent() {
   const[screens,permissionTypes , permArray,setPermArray,handleAddPermissions,groupScreens,isLoading] =EditPermissionsHook();
    const location = useLocation();
    //console.log(location?.state);

    console.log("screens",screens)

    return (
        <>
            <div className="d-flex justify-content-between firstContainer">
                <h5 className="h5Title">الصلاحيات</h5>
                <div className="d-flex justify-content-between" style={{ gap: '20px' }}>
                    <Filter
                        gap={'0'}
                        types={[
                            { text: 'اليوم', value: 'day' },
                            { text: 'الشهر', value: 'month' },
                            { text: 'الاسبوع', value: 'week' }
                        ]} />

                    <PdfBox />
                </div>
            </div>

            <div className="permissionsLayout" style={{ padding: '16px', marginTop: '25px',height:'632px' }}>
                <h5 className="h5Title" style={{ color: "rgba(0, 128, 251, 1)" }}>
                    صلاحيات ({location?.state?.nameAr})
                </h5>

                <div className="screensBox">
                    { !isLoading&& screens && screens?.map((el, i) => (
                        <div key={i} style={{margin:'8px'}}>
                            <div className="screensBoxtitle">
                                 {el?._id === "665f0e0970673dba321d15f7" ?
                                  `${el?.nameAr}  (التعديل علي بيانات العضوية) ` :
                                   el?._id === "665f0dee70673dba321d15f3"?  
                                   `${el?.nameAr}  (التعديل علي حالة العضوية) `
                                   :el?.nameAr}
                            </div>
                               <ScreenBoxPermissionComponent
                               screen={el}
                               permArray={permArray} 
                               setPermArray={setPermArray}
                               permissionTypes={permissionTypes} 
                               groupScreens={groupScreens}
                               />
                        </div>
                    ))}


                </div>
            </div>

            <div className="d-flex justify-content-end firstContainer" style={{marginTop:'2%'}}>
                <button onClick={()=>handleAddPermissions()} className="permissions-save"> تم </button>
            </div>
        </>

    )
}
