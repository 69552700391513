import SideBar from "../../Components/Utilities/SideBar";
import PrintAndDownload from "../../Components/Utilities/PrintAndDownload";
import Billing from "../../Pages/members/Billing";
import HeadSection from "../../Components/Utilities/HeadSection";
import { getReceipt } from "../../redux/slices/memberShips/thunk";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const PillMemberPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [receipt_data, setReceipt_data] = useState({});
  const [qrCodeData, setQRCodeData] = useState("");

  useEffect(() => {
    const fetchReceipt = async () => {
      await dispatch(getReceipt(id));
    };

    fetchReceipt();
  }, [dispatch, id]);

  const receipt = useSelector((state) => state.membersReducer.getReceipt);

  useEffect(() => {
    if (receipt) {
      setReceipt_data(receipt);
    }
  }, [receipt]);

  console.log(receipt_data);
  const handlePrint = () => {
    const receiptContent = `
    <html>
      <head>
        <title>Print Receipt</title>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

          /* Add your CSS styles here */
          body {
            font-family: Cairo, sans-serif;
          }
          .receipt-container {
            border: 1px solid #ddd;
            padding: 20px;
            margin: 20px;
          }
          .receipt-header {
            text-align: center;
            margin-bottom: 20px;
          }
          .receipt-table {
            width: 100%;
            border-collapse: collapse;
            direction: rtl;
          }
          .receipt-table th, .receipt-table td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: right;
          }
          .receipt-qrcode {
            text-align: center;
            margin-top: 20px;
          }
          .table-2-div{
            display: flex;
            justify-content: space-between;
        
          }
          .main-data{
            display: flex;
            justify-content: space-between;
            direction: rtl;
            margin-bottom: 15px;

          }
          .data-client-second{
            margin-bottom: 15px;
          }
          .data-clent{
            display: flex;
            flex-direction: column;        
          }
          .receipt-table-2 {
            border-collapse: collapse;
            direction: rtl;
          }

          .receipt-table-2 th, .receipt-table-2 td {
            border: 1px solid #ddd;
            padding: 8px;
            padding: 8px;
            text-align: right;
          }
          .receipt-table-2 td {
            padding-left: 60px; /* Add left padding for better alignment */
          }
          </style>
      </head>
      <body>
        <div class="receipt-container">
          <h2 class="receipt-header">الفاتوره</h2>
          <div class="main-data">
          
          <div class="data-company">
          
          <table class="receipt-table-2">
          <tr>
            <td>بيانات البائع</td>
            <td>seller info</td>
          </tr>
          <tr>
            <td>اسم الشركة</td>
            <td>شركة تكافل العربية</td>
          </tr>
          <tr>
            <td>اسم الفرع</td>
            <td>الفرع الرئيسي</td>
          </tr>
          <tr>
            <td>رقم المبني</td>
            <td>4</td>
          </tr>
          <tr>
          <td>اسم الشارع</td>
          <td>حي المرسلات</td>
        </tr>
        <tr>
        <td>المدينة</td>
        <td>الرياض</td>
      </tr> 
       <tr>
      <td>المنطقة</td>
      <td>السعودية</td>
    </tr>
    <tr>
    <td>الرمز البريدي</td>
    <td>9200009460</td>
  </tr>
  <tr>
  <td>الرقم البريدي</td>
  <td>302006361700003</td>
</tr>

          </table>

          </div>
          <div class="data-clent">

          <div class="data-client-second">
          
          <table class="receipt-table-2">
          <tr>
            <td>رقم الفاتورة</td>
            <td>${receipt_data.serial_number}</td>
          </tr>
          <tr>
            <td>تاريخ الفاتورة</td>
            <td>${receipt_data.createdAt}</td>
          </tr>
          <tr>
            <td>تاريخ التوريد</td>
            <td>${receipt_data?.createdAt}</td>
          </tr>
          <tr>
            <td>الوصف</td>
            <td>لا يوجد وصف</td>
          </tr>
          </table>

          </div>
           <div class="receipt-data-second">
          
           <table class="receipt-table-2">
           <tr>
             <td>بيانات العميل</td>
             <td></td>
           </tr>
           <tr>
             <td></td>
             <td>${receipt_data.membership?.name}</td>
           </tr>
           <tr>
             <td>رقم الجوال</td>
             <td>${receipt_data.membership?.mobile}</td>
           </tr>
           </table>

          </div>

          </div>

          </div>

          <table class="receipt-table">
            <tr>
              <th>الرمز</th>
              <th>السعر بالضريبه</th>
              <th>الضريبه</th>
              <th>الخصم</th>
              <th>السعر</th>
              <th>الكميه</th>
            </tr>
            <tr>
              <td>${receipt_data.serial_number}</td>
              <td>${receipt_data.total_price_with_tax}</td>
              <td>${receipt_data.tax}</td>
              <td>${receipt_data.discount}</td>
              <td>${receipt_data.price}</td>
              <td>${receipt_data.quantity}</td>
            </tr>
          </table>

          <div class="table-2-div">
          <div class="receipt-qrcode">
          <img src="${qrCodeData}" alt="QR Code" width="100px" height="100px">
          </div>
          <table class="receipt-table-2">
          <tr>
            <td>الإجمالي</td>
            <td>${receipt_data.membership?.price}</td>
          </tr>
          <tr>
            <td>الإجمالي بالضريبه</td>
            <td>${receipt_data.membership?.price}</td>
          </tr>
          <tr>
            <td>الإجمالي</td>
            <td>${receipt_data?.tax}</td>
          </tr>
          <tr>
            <td>الصافي</td>
            <td>${receipt_data?.membership?.price}</td>
          </tr>
          </table>
        
          </div>
        </div>
      </body>
    </html>
  `;

    const printWindow = window.open("", "_blank");
    printWindow.document.write(receiptContent);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.focus();
      printWindow.print();
    }, 500); // Adjust the delay if needed
  };

  const handleDownload = () => {
    // Construct CSV content header
    let csvContent =
      "Serial Number,Total Price With Tax,Tax,Discount,Price,Quantity\n";

    // Extracting desired fields from receipt_data
    const {
      serial_number,
      total_price_with_tax,
      tax,
      discount,
      price,
      quantity,
    } = receipt_data;

    // Constructing CSV row
    const row = `${serial_number},${total_price_with_tax},${tax},${discount},${price},${quantity}\n`;

    // Appending row to CSV content
    csvContent += row;

    // Creating CSV file
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Creating download link
    const a = document.createElement("a");
    a.href = url;
    a.download = "receipt.csv";
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard" style={{ overflow: "hidden" }}>
        <HeadSection label={"الفاتورة"} />

        <div className="permissionsLayout mt-3">
          <div className="d-flex justify-content-end">
            <PrintAndDownload
              onPrint={handlePrint}
              onDownload={handleDownload}
            />
          </div>
          <div style={{ overflow: "auto" }}>
            <Billing receipt={receipt_data} setQRCodeData={setQRCodeData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PillMemberPage;
