import React, { useRef, useState } from "react";
import BtnGroup from "./BtnGroup";
import MemberDetailsCardItem from "./MemberDetailsCardItem";
import { useSelector } from "react-redux";

export default function MemberDetailsCard({ data, main }) {
  const canvasRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const { oneUser } = useSelector((state) => state.usersReducer);

  const handleDownload = (card) => {
    console.log("Download button clicked", card); // Add logging

    const canvas = canvasRef.current;
    canvas.width = 800; // Set canvas width
    canvas.height = 500; // Set canvas height
    const ctx = canvas.getContext("2d");

    // Set background color
    ctx.fillStyle = "white"; // Background color
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas with the background color

    ctx.font = "bold 20px Cairo"; // Set font family and size
    ctx.fillStyle = "black"; // Change text color to black

    // Define positions for each text item using bottom offsets
    const positions = {
      name: { left: 128.94, bottom: 166.57 }, // 50px from the bottom
      idNo: { left: 128.94, bottom: 117.8 }, // 150px from the bottom
      date: { left: 193.64, bottom: 68.32 }, // 200px from the bottom
      membershipNo: { right: 96.79, bottom: 117.8 }, // 150px from the bottom
      expDate: { right: 167, bottom: 68.32 }, // 200px from the bottom
    };

    // Draw left-aligned text
    ctx.textAlign = "left";
    ctx.fillText(
      `${card.name}`,
      positions.name.left,
      canvas.height - positions.name.bottom
    );
    ctx.fillText(
      `${card.idNo}`,
      positions.idNo.left,
      canvas.height - positions.idNo.bottom
    );
    ctx.fillText(
      // `${card.date}`,
      `${new Date(card.date).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the created date

      // `${new Date(card.date).toLocaleDateString('en-GB')}`, // Updated to display the created date

      positions.date.left,
      canvas.height - positions.date.bottom
    );

    // Draw right-aligned text
    ctx.textAlign = "right";
    ctx.fillText(
      `${card.membershipNo}`,
      canvas.width - positions.membershipNo.right,
      canvas.height - positions.membershipNo.bottom
    );
    ctx.fillText(
      // `${card.expDate}`,
      `${new Date(card.expDate).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the created date
      // `${new Date(card.expDate).toLocaleDateString('en-GB')}`, // Updated to display the created date
      canvas.width - positions.expDate.right,
      canvas.height - positions.expDate.bottom
    );

    // Download the canvas as an image
    const link = document.createElement("a");
    link.download = "card.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  const handlePrint = (card) => {
    console.log("Print button clicked", card); // Add logging

    const printWindow = window.open("", "_blank");

    const canvas = document.createElement("canvas");
    canvas.width = 800; // Set canvas width
    canvas.height = 500; // Set canvas height
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";

    // Clear the canvas to ensure it's empty and transparent
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.font = "bold 20px Cairo"; // Set font family and size
    ctx.fillStyle = "black"; // Change text color to black

    // Define positions for each text item
    const positions = {
      name: { left: 100, bottom: 150 }, // 50px from the bottom
      idNo: { left: 100, bottom: 100 }, // 150px from the bottom
      date: { left: 150, bottom: 50 }, // 200px from the bottom
      membershipNo: { right: 100, bottom: 100 }, // 150px from the bottom
      expDate: { right: 150, bottom: 50 }, // 200px from the bottom
    };

    // Draw left-aligned text
    ctx.textAlign = "left";
    ctx.fillText(
      `${card.name}`,
      positions.name.left,
      canvas.height - positions.name.bottom
    );
    ctx.fillText(
      `${card.idNo}`,
      positions.idNo.left,
      canvas.height - positions.idNo.bottom
    );
    ctx.fillText(
      // `${card.date}`,
      `${new Date(card.date).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the created date

      // `${new Date(card.date).toLocaleDateString('en-GB')}`, // Updated to display the created date

      positions.date.left,
      canvas.height - positions.date.bottom
    );

    // Draw right-aligned text
    ctx.textAlign = "right";
    ctx.fillText(
      `${card.membershipNo}`,
      canvas.width - positions.membershipNo.right,
      canvas.height - positions.membershipNo.bottom
    );
    ctx.fillText(
      // `${card.expDate}`,
      `${new Date(card.expDate).toLocaleDateString('en-GB').replace(/\//g, '-')}`, // Updated to display the created date
      // `${new Date(card.expDate).toLocaleDateString('en-GB')}`, // Updated to display the updated date
      canvas.width - positions.expDate.right,
      canvas.height - positions.expDate.bottom
    );

    // Add the canvas element to the print window or iframe
    printWindow.document.body.appendChild(canvas);

    // Print the window or iframe
    printWindow.print();
  };
  console.log("main", main);

  let showPhoneNumber = true;
  //  لو محاسب شوف عنده الصلاحية دي ولا لا
  if (!user.liquidation_user && !user.isAdmin) {
    if (oneUser?.show_mobile_in_users_card_table) {
      showPhoneNumber = true;
    } else showPhoneNumber = false;
  }
  const getAdminOrSupervisorLabel = () => {
    if (main?.user?.isAdmin) return "اسم الادمن";
    if (main?.user?.liquidation_user) return "اسم المندوب";
    return "اسم المندوب";
  };

  const getAdminOrSupervisorData = () => {
    if (!main?.user?.father_id) return main.user.fullname;
    return main?.user?.father_id?.fullname;
  };
  console.log(main?.user);
  console.log("datta",data)
  return (
    <div>
      <BtnGroup
        handleDownload={handleDownload}
        handlePrint={handlePrint}
        main={main}
        data={data}
      />
      <MemberDetailsCardItem label={"إٍسم صاحب الطلب"} data={data?.name} />
      <MemberDetailsCardItem label={"رقم العضوية"} data={data?.serial_number} />
      <MemberDetailsCardItem
        label={"رقم الإثبات"}
        data={data?.identity_number_passport}
      />
      {showPhoneNumber && (
        <MemberDetailsCardItem label={"رقم الجوال"} data={data?.mobile} />
      )}
      {showPhoneNumber && data?.mobile2 && (
        <MemberDetailsCardItem label={"رقم الجوال"} data={data?.mobile2} />
      )}
      <MemberDetailsCardItem label={"المدينة "} data={main?.city?.nameAr} />
      {/* {   main?.user?.father_id &&   <MemberDetailsCardItem
        label={"إٍسم المندوب"}
        data={
           main?.user?.father_id?.fullname
        }      
        />} */}

      <>
        <div className="d-flex my-2 mx-3 font" style={{ gap: "30px" }}>
          <div className="member-details-title font">
            {getAdminOrSupervisorLabel()}
          </div>
          <div className="member-details-title font">
            {getAdminOrSupervisorData()}
          </div>
          {!main?.user?.isAdmin && main?.user?.father_id && (
            <>
              <div className="member-details-title font"> / </div>
              <div className="member-details-title font">
                {main?.user?.fullname}
              </div>
              <div className="member-details-title font">(مشرف)</div>
            </>
          )}
        </div>

        <hr className="hr_line" />
      </>

      <MemberDetailsCardItem
        label={"مرسلة من"}
        data={
          // main?.sent_by?.father_id
          //   ? main.sent_by.fullname
          //   : main?.sent_by_text
          //     ? main.sent_by_text
          //     : "لا يوجد مشرف"
          main?.sent_by ? main?.sent_by : "لا يوجد مشرف"
        }
      />

      <MemberDetailsCardItem
        label={" الوقت"}
        data={main?.time}
      />

      <canvas
        ref={canvasRef}
        width={400}
        height={250}
        style={{ display: "none" }}
      ></canvas>
    </div>
  );
}
