import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addBalanceToWallet } from '../../../redux/slices/wallet/thunk';
import notify from '../../../hook/useNotification';
import { getNewLiqidationForSalesUser } from '../../../redux/slices/liquidation/thunk';
import { addNewDeposite } from '../../../redux/slices/deposites/thunk';
import { Spinner } from "react-bootstrap";

const LiquidationSubModal = ({ showModal, setShowModal, latestLiquidation, HandleSendLiquidation }) => {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));

    const [isLoading,setIsLoading]=useState(false)
    //current_balance
    const handleClick = async () => {
        try {

            
            console.log('liquidation_balance_end', Math.abs(latestLiquidation?.liquidation_balance_end));
            console.log('current balance', latestLiquidation?.current_balance);
            setIsLoading(true);
            //1) لو رصيد التصفية يسمح اخسم من المحفظة
            if (latestLiquidation?.current_balance >= Math.abs(latestLiquidation?.liquidation_balance_end)) {
                const data = {
                    "type": "sub",
                    "Withdrawals": Math.abs(latestLiquidation?.liquidation_balance_end),
                    "sales_id": user?._id
                };
                await dispatch(addBalanceToWallet({ data }));

                  //2- اعمل ايداع ف التصفية
                  const depositeData = {
                    user_id: user?._id,
                    price: Math.abs(latestLiquidation?.liquidation_balance_end),
                    deposit_date: new Date(latestLiquidation?.date_from.split('T')[0]),
                    operation_date: new Date(),
                    operation_type: null,
                    adapter_name: "Mandoub",
                    type: 'liquidation',
                    status_of_deposit : "accepted",
                    is_wallet:true
                };
                if(depositeData.price > 0){
                    await dispatch(addNewDeposite({ data: depositeData }));
                }

                let liquidationData = {
                    status: 'send',
                    user_id: user?._id,
                    liquidation_balance_end: 0
                }

                HandleSendLiquidation(liquidationData);
            }

            else {
                // لو رصيد التصفية اكبر من رصيد المحفظة
                //  اسحب كل رصيد المحفظة  وبعدها شوف الحد الائماني لو يسمح كمل العملية
                const wallet_credit_limit = latestLiquidation?.wallet_credit_limit;
                console.log('wallet_credit_limit', wallet_credit_limit);
                //1 اسحب المحفظة
                const data = {
                    "type": "sub",
                    "Withdrawals": Math.abs(latestLiquidation?.current_balance),
                    "sales_id": user?._id
                };


                if(latestLiquidation?.current_balance >0) await dispatch(addBalanceToWallet({ data }));

                //2- اعمل ايداع ف التصفية
                const depositeData = {
                    user_id: user?._id,
                    price: latestLiquidation?.current_balance,
                    "deposit_date": new Date(latestLiquidation?.date_from.split('T')[0]),
                    "operation_date": new Date(),
                    "operation_type": null,
                    "adapter_name": "Mandoub",
                    type: 'liquidation',
                    status_of_deposit : "accepted"
                };

                if(latestLiquidation?.current_balance >0) await dispatch(addNewDeposite({ data: depositeData }));


                const liquidation_balance_end = 
                Math.abs(latestLiquidation?.liquidation_balance_end) 
                - Math.abs(latestLiquidation?.current_balance);

                let liquidationData = {
                    //status: 'send',
                    user_id: user?._id,
                    liquidation_balance_end: liquidation_balance_end * -1
                }

                if (wallet_credit_limit > liquidation_balance_end) {
                    //2 شيل رصيد المحفظة من رصيد النهاية
                    liquidationData.status = 'send';
                    // let liquidationData = {
                    //     //status: 'send',
                    //     user_id: user?._id,
                    //     liquidation_balance_end: liquidation_balance_end * -1
                    // }
                }
                else {
                    notify(" رصيد التصفية قد تخطي الحد الائتماني الخاص بك", "error");
                }

                HandleSendLiquidation(liquidationData);
                const query = `user_id=${user?._id}`;
                //console.log('query',query);
                dispatch(getNewLiqidationForSalesUser({ id: latestLiquidation?._id, query }));
                setShowModal(false);
            }
        } catch (error) {
            console.error(error.message)
        } finally{
            setIsLoading(false)
        }

    }

    console.log('salesLiquidationUser', latestLiquidation);
    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} >

            <Modal.Body>
                <div className="label d-flex justify-content-center">
                    سيتم خصم الرصيد من المحفظة
                </div>

                <div className="d-flex justify-content-between">
                    <Button
                        onClick={() => handleClick()}
                        disabled={isLoading}
                        variant="primary" style={{ backgroundColor: "#0080FB" }}>
                        { isLoading ? <Spinner/> :"موافق"}
                    </Button>

                    <Button onClick={() => setShowModal(false)} variant="danger">
                        الغاء
                    </Button>
                </div>

            </Modal.Body>

        </Modal>
    );
};


export default LiquidationSubModal;