import SideBar from "../../Components/Utilities/SideBar";
import AddDepositeComponent from "../../Components/deposites/add/AddDepositeComponent";

export default function AddDepositePage() {
  const user = JSON.parse(localStorage.getItem("user"));
  let type = "";
  if (!user?.isAdmin && !user?.liquidation_user) type = "accountant";

  return (
    <div
      className="d-flex justify-content-start"
      style={{ minHeight: "700px" }}
    >
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard">
        <AddDepositeComponent type={type} />
      </div>
    </div>
  );
}
