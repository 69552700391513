import { createSlice } from "@reduxjs/toolkit";
import { addNewLiquidation, deleteOneLiquidation, editOneLiquidation, getAllLiquidation, getLiquidationForSearch, getNewLiqidationForSalesUser } from "./thunk";

//getLiquidationForSearch
const initialState={
    liquidation:[],
    liquidationSearch:[],
    salesLiquidation:{}
};

const liquidationSlice=createSlice({
    initialState,
    name:'liquidationSlice',
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllLiquidation.fulfilled,(state,action)=>{
            state.liquidation=action?.payload;
        });
        builder.addCase(addNewLiquidation.fulfilled,(state,action)=>{
            //state.liquidation=[...state.liquidation,action?.payload];
        });
        builder.addCase(getLiquidationForSearch.fulfilled,(state,action)=>{
            state.liquidationSearch=action?.payload;
        });
        builder.addCase(editOneLiquidation.fulfilled,(state,action)=>{
            state.liquidation=state.liquidation.map(item=>
                item?._id.toString()===action?.payload?._id.toString()
                ? {...item,...action.payload} : item
            );
        });
        builder.addCase(deleteOneLiquidation.fulfilled,(state,action)=>{
            state.liquidation=state.liquidation.filter(item=>
                item?._id.toString()!=action?.payload?._id.toString()
            )
        });
        builder.addCase(getNewLiqidationForSalesUser.fulfilled,(state,action)=>{
            state.salesLiquidation=action?.payload;
        });

    }
});

export default liquidationSlice.reducer;