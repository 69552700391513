import { HiPencilSquare } from "react-icons/hi2";
import CircleCheckBoxWithIcon from "../Utilities/CircleCheckBoxWithIcon";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { exportToExcel } from "../Utilities/exportToExcel";
export default function AllOffersTableComponent({
  offers,
  handleUpdateStatus,
  showItem,
  hasAdd,
  hasEdit
}) {
  const handleExport = () => {
    exportToExcel(offers, "offers");
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }
      .table-header {
        background-color: #f2f2f2;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    `);
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");
    printWindow.document.write("<table>");

    // Table headers
    printWindow.document.write("<tr>");
    printWindow.document.write("<th class='table-header'>نوع العرض</th>");
    printWindow.document.write("<th class='table-header'>القيمة</th>");
    printWindow.document.write("<th class='table-header'>فترة الصلاحية	</th>");
    printWindow.document.write("<th class='table-header'>عدد البطاقات</th>");
    printWindow.document.write("<th class='table-header'>عمولة المندوب</th>");
    printWindow.document.write("<th class='itable-header'>تاريخ الانشاء</th>");

    printWindow.document.write("</tr>");

    // Table data
    offers?.forEach((offer) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(`<td>${offer?.offerNameAr}</td>`);
      printWindow.document.write(`<td>${offer?.reducedAmount}</td>`);
      printWindow.document.write(
        `<td>${offer?.periodType == 1 ? "شهر" : "سنة"}</td>`
      );
      printWindow.document.write(`<td>${offer?.numberOfCards}</td>`);
      printWindow.document.write(`<td>${offer?.salesCommission}</td>`);
      printWindow.document.write(`<td>${offer?.createdAt.split("T")[0]}</td>`);

      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownload = () => {
    let csvContent =
      "نوع العرض,القيمة,فترة الصلاحية,عدد البطاقات,عمولة المندوب,تاريخ الانشاء\n";
    offers?.forEach((offer) => {
      csvContent += `${offer?.offerNameAr},${offer?.reducedAmount},${
        offer?.periodType == 1 ? "شهر" : "سنة"
      },${offer?.numberOfCards},${offer?.salesCommission},${
        offer?.createdAt.split("T")[0]
      }\n`;
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "offers.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="header" style={{ minWidth: "1000px", width: "100%" }}>
        <div>العروض الحالية</div>
        <PrintAndDownload
          color="white"
          fontWeigth={"100"}
          hasExcelDownload={true}
          download_excel={handleExport}
          onPrint={handlePrint}
          onDownload={handleDownload}
        />
      </div>

      <div
        className="permissionsTable stripe"
        style={{ overflow: "auto", minWidth: "1000px", width: "100%" }}
      >
        <table style={{ minWidth: "1000px", width: "100%" }}>
          <thead>
            <tr>
              <th>نوع العرض</th>
              <th>القيمة</th>
              <th> فترة الصلاحية</th>
              <th>عدد البطاقات</th>
              <th> عمولة المندوب</th>
              <th>تاريخ الانشاء</th>
              <th>تشغيل /ايقاف</th>
              <th>تعديل</th>
            </tr>
          </thead>
         {offers?.length > 0 ? (<tbody>
            {offers?.map((offer, i) => (
              <tr key={i}>
                <td>{offer?.offerNameAr}</td>
                <td>{offer?.reducedAmount}</td>
                <td>
                  {offer?.validityPeriod} -{" "}
                  {offer?.periodType == 1 ? "شهر" : "سنة"}
                </td>
                <td>{offer?.numberOfCards}</td>
                <td>{offer?.salesCommission}</td>
                <td>{offer?.createdAt.split("T")[0]}</td>
                <td>
                  <div>
                    <CircleCheckBoxWithIcon
                      onClick={() => {
                        handleUpdateStatus(offer);
                      }}
                      check={offer?.status}
                      disabled={!hasEdit}
                    />
                  </div>
                </td>
                <td
                  onClick={() =>{
                    if (!hasEdit) return;
                    showItem(offer);
                  }}
                  style={{ color: "#FEAA00", cursor: "pointer" }}
                >
                  تعديل{" "}
                  <span>
                    <HiPencilSquare />{" "}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>) : (
    <tbody>
      <tr>
        <td colSpan="100%" style={{ textAlign: "center", padding: "20px", fontSize: "18px", color: "#888" }}>
          لا يوجد بيانات للعرض
        </td>
      </tr>
    </tbody>
  )}
        </table>
      </div>
    </>
  );
}
