import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import WalletPageComponent from "../../Components/wallet/WalletPageComponent";

export default function WalletPage() {
  const screenID="665f0f9470673dba321d1630";
 
  const loggedUser = JSON.parse(localStorage.getItem('user'));

    let access = false;
    if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
    else access=true;

    if (access == false) return <Navigate to='/setting/profile?section=Profile' />
  return (
    <div className="d-flex justify-content-start">
        <div>
            <SideBar />
        </div>
        <div className="p-3 w-100 dashboard" style={{overflow:"hidden"}}>      
            <WalletPageComponent />
        </div>
    </div>
  )
}
