import SideBar from "../../Components/Utilities/SideBar";
import ReportPageComponent from "../../Components/marketing/ReportPageComponent";

export default function ReportPage() {
    const loggedUser= JSON.parse(localStorage.getItem('user'));
    
  return (
    <div className="d-flex justify-content-start">
        <div>
        {loggedUser?._id&& <SideBar />} 
        </div>
        <div className="p-4 w-100 dashboard" style={{overflow:"hidden"}}>
        <ReportPageComponent />
    
        </div>
    </div>
  )
}
