import { useDispatch } from "react-redux";
import { editOneLiquidation } from "../../../redux/slices/liquidation/thunk";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Box3Component({ item }) {

  const[notes,setNotes]=useState('');

  const dispatch=useDispatch();
  const navigate=useNavigate();
  const loggedUser=JSON.parse(localStorage.getItem('user')); 


  const HandleUpdate=async(status)=>{
    try {
      let data = {
        status: status,
        user_id:loggedUser?._id,
        notes
    };
  
    await dispatch(editOneLiquidation({
        id: item?._id,
        data
    }));

    navigate('/liquidation?liquidation-type=new');

    } catch (error) {
      console.error(error?.message);
    }
    
  }

  // /liquidation
  return (
    <div style={{ width: '94%' }}>
      <div className="h5Title pink"> ملاحظات </div>
      <textarea
        className="mt-2 liquidation-details-textarea w-100"
        value={notes}
        onChange={(e)=>setNotes(e.target.value)}
      />

      <div className="d-flex justify-content-between my-3  px-5">
        <button onClick={()=>HandleUpdate('accepted')} className="addButton" style={{ width: '175px' }}> تصفية (قبول) </button>
        <button onClick={()=>HandleUpdate('refused')} className="addButton" style={{ width: '175px' }}> رفض </button>
        {/* <button className="addButton" style={{ width: '175px' }}> حفظ </button> */}
      </div>
    </div>
  )
}
