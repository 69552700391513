import HeadSection from "../Utilities/HeadSection";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import WalletFilter from "./WalletFilter";
import WalletCards from "./WalletCards";
import style from "./WalletCards.module.css";
import MainUserComponent from "./MainUserComponent";
import ChildUsersComponent from "./ChildUsersComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect ,useState} from "react";
import { getUserWalletInfo } from "../../redux/slices/wallet/thunk";
import { getAllUsers } from "../../redux/slices/users/thunk";

// import style from "./WalletCards.module.css";

export default function WalletPageComponent() {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hasFilter, setHasFilter] = useState(false);


  console.log("hasFilter",hasFilter)

  useEffect(() => {
    let query = ``;
    if (loggedUser?.isAdmin) return;
    else query = `sales_id=${loggedUser?._id}`;

    dispatch(getUserWalletInfo(query));
  }, []);

  useEffect(()=>{
    dispatch(getAllUsers());
  },[])

  const { wallet } = useSelector((state) => state.walletReducer);

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((obj) => Object.values(obj).join(",")).join("\n");
    return headers + rows;
  };

  const handleDownload = () => {
    if (wallet.length != 0) {
      const csv = convertToCSV(wallet);
      const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(csvBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "walet.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const handlePrint = () => {
    if (wallet.length != 0) {
      const table = document.createElement("table");
      const headers = Object.keys(wallet[0]);
      const headerRow = table.insertRow();
      headers.forEach((header) => {
        const th = document.createElement("th");
        th.textContent = header;
        headerRow.appendChild(th);
      });
      wallet.forEach((member) => {
        const row = table.insertRow();
        headers.forEach((header) => {
          const cell = row.insertCell();
          cell.textContent = member[header];
        });
      });

      const printWindow = window.open("", "", "height=600,width=800");
      printWindow.document.write(
        "<html><head><title>Walet Table</title></head><body>"
      );
      printWindow.document.write("<h1>Walet Table</h1>");
      printWindow.document.write(table.outerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };

  console.log("startDate",startDate)
  console.log("endDate",endDate)

  //console.log('wallet',wallet);
  return (
    <div className="firstContainer mx-2">
      <HeadSection label={"المحفظة"} />
      <div className="d-flex  justify-content-end  my-4">
        {/* <PrintAndDownload onPrint={handlePrint} onDownload={handleDownload} /> */}
      </div>
      <div className="permissionsLayout">
        <WalletFilter hasFilter={hasFilter} setHasFilter={setHasFilter}  startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
        <WalletCards wallet={wallet} hasFilter={hasFilter} />
      </div>
    </div>
  );
}
