import { createSlice } from "@reduxjs/toolkit";
import { getAllOffers,addOneOffer,updateOneOffer ,getAllOffersforMandob } from "./thunk";

const initialState={
    offers:[]
};

const offersSlice=createSlice({
    initialState,
    name:"offersSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllOffers.fulfilled,(state,action)=>{
            console.log("action",action)
            if(action.payload==undefined) return;
            state.offers=action.payload;
        });
        builder.addCase(getAllOffersforMandob.fulfilled,(state,action)=>{
            if(action.payload==undefined) return;
            state.offers=action.payload;
        });
        builder.addCase(addOneOffer.fulfilled,(state,action)=>{
            console.log("action.payload",action.payload)
            if(action.payload==undefined) return;
            state.offers=[...state.offers,action.payload];
        });
        builder.addCase(updateOneOffer.fulfilled,(state,action)=>{
            state.offers = state.offers.map((offer) =>
            offer._id.toString() === action.payload?._id.toString()
              ? { ...offer, ...action.payload }
              : offer
              );
        });
    }
});

export default offersSlice.reducer;