import React, { useState } from "react";
import { HiPencilSquare } from "react-icons/hi2";
// import { useNavigate } from 'react-router-dom';
import EditDateModal from "./EditDateModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

export default function LiquidationSalesTable({ liquidation }) {
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeletedItem] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));
  console.log("liquidation",liquidation)

  // const navigate=useNavigate();
  return (
    <div className="permissionsLayout w-100" style={{overflow:"auto"}}>
      {showDeleteModal && (
        <ConfirmDeleteModal
          deleteItem={deleteItem}
          setDeleteItem={setDeletedItem}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
        />
      )}
      {showModal && (
        <EditDateModal
          editItem={editItem}
          setEditItem={setEditItem}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}

      <div className="permissionsTable" style={{overflow: 'auto'}}>

{  !user.is_accountant &&      <div className="header mt-2" style={{width:"100%", minWidth:"1200px"}}> التصفيات السابقة</div>}
{   user.is_accountant &&      <div className="header mt-2" style={{width:"100%", minWidth:"1200px"}}> التصفيات المرسلة</div>}
        <table className="pt-2" id="liquidationTable" style={{width:"100%", minWidth:"1200px"}}>
          <thead >
            <tr>
              <th> رقم التصفية </th>
              <th> اسم المستخدم </th>
              <th> تاريخ بداية التصفية </th>
              <th> تاريخ نهاية التصفية </th>
              <th> تعديل </th>
           {false &&  <th> حذف </th>}
            </tr>
          </thead>
          {liquidation?.length > 0 ? (<tbody className="sacondBackground">
            {liquidation?.map((el, i) => (
              <tr key={i}>
                <td>{el?.liquidation_number} </td>
                <td>{el?.sales_id?.fullname} </td>
                <td>{el?.date_from?.split("T")[0]} </td>
                <td>{el?.date_to?.split("T")[0]} </td>
                <td style={{ color: "#FEAA00", cursor: "pointer" }}>
                  <div
                    onClick={() => {
                      setEditItem(el);
                      setShowModal(true);
                    }}
                  >
                    تعديل{" "}
                    <span>
                      <HiPencilSquare />{" "}
                    </span>
                  </div>
                </td>

               { false &&<td style={{ color: "red", cursor: "pointer" }}>
                  <div
                    onClick={() => {
                      setDeletedItem(el);
                      setShowDeleteModal(true);
                    }}
                  >
                    حذف{" "}
                    <span>
                      <HiPencilSquare />{" "}
                    </span>
                  </div>
                </td>}
              </tr>
            ))}
          </tbody>) : (
    <tbody>
      <tr>
        <td colSpan="100%" style={{ textAlign: "center", padding: "20px", fontSize: "18px", color: "#888" }}>
          لا يوجد بيانات للعرض
        </td>
      </tr>
    </tbody>
  )}
        </table>
      </div>
    </div>
  );
}
