import { useEffect, useState } from "react";
import "../../App.css";
import LOGO from "../../Images/LOGO.png";
import notify from "../../hook/useNotification";
import { login } from "../../redux/API/users";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../Images/takafol-logo.png";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

// import {  getScreensForOneGroup } from "../../redux/slices/permissions/thunk";

export default function LoginPage() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State to track loading status
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { permissionTypes } = useSelector((state) => state.permissionsReducer);

  console.log("permissionTypes", permissionTypes);

  const handleLogin = async () => {
    try {
      if (userName === "" || password === "")
        return notify("من فضلك ادخل اسم المستخدم وكلمة السر", "error");

      setLoading(true); // Set loading to true while waiting for response

      const res = await login({
        username: userName,
        password: password,
      });

      console.log("res", res);

      setLoading(false); // Reset loading state after response

      if (res.status === 400) {
        setUserName("");
        setPassword("");
        return notify(res.data, "error");
      } else {
        console.log("login res", res?.data);

        let userObj = { ...res?.data };
        localStorage.setItem("user", JSON.stringify(userObj));
        // navigate('/home');
        if (userObj?.isAdmin) window.location.href = "/home";
        else window.location.href = "/setting/profile?section=Profile";
      }
    } catch (error) {
      console.error(error.message);
      setLoading(false); // Reset loading state in case of error
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      if (loggedUser?.isAdmin) window.location.href = "/home";
      else window.location.href = "/setting/profile?section=Profile";
    }
    return () => {};
  }, []);

  //if(permissionTypes?.length===0) return;

  return (
    <div className="loginPage">
      <div className="loginBox">
        <div className="logoContainer">
          <img
            src={logo}
            alt=""
            // style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="userLoginContainer">
          <div className="username">
            <div>اسم المستخدم</div>
            <input
              className="inputField"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="اسم المستخدم"
              onKeyPress={handleKeyPress}
              style={{ color: "#000" }}
            />
          </div>
          <div className="username">
            <div>كلمة المرور</div>
            <div className="passwordInputContainer">
              <input
                className="inputField"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="كلمة المرور"
                onKeyPress={handleKeyPress}
                style={{ color: "#000" }}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="togglePasswordButton"
              >
                {showPassword ? (
                  <span role="img" aria-label="Hide password">
                    <FaEye size={28} />
                  </span>
                ) : (
                  <span role="img" aria-label="Show password">
                    <FaEyeSlash size={28} />
                  </span>
                )}
              </button>
            </div>
          </div>
          {/* Conditionally render spinner inside the login button based on loading state */}
          <button onClick={() => handleLogin()} className="loginButton">
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <span>دخول</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
