import React from "react";
import DeliveredCardGraphComponent from "../DeliveredCardGraphComponent/DeliveredCardGraphComponent";
import UnDeliveredCardGraphComponent from "../UnDeliveredCardGraphComponent/UnDeliveredCardGraphComponent";
import TotalDepositsComponent from "../TotalDepositsComponent/TotalDepositsComponent";

import styles from "./MasterLineGraphConponent.module.css";

const MasterLineGraphConponent = ({ statistics }) => {
  const amount_of_deposits = statistics.map((stat) => stat.amount_of_deposits);
  const number_of_undelivered_cards = statistics.map(
    (stat) => stat.number_of_undelivered_cards
  );
  const number_of_delivered_cards = statistics.map(
    (stat) => stat.number_of_delivered_cards
  );

  const currentMonthIndex = new Date().getMonth();
console.log("currentMonthIndex",currentMonthIndex)

const elementForCurrentMonth = number_of_undelivered_cards[currentMonthIndex];
const elementdeliveredForCurrentMonth = number_of_delivered_cards[currentMonthIndex];
const elementForMoneyCurrentMonth = amount_of_deposits[currentMonthIndex];


  console.log("elementForCurrentMonth",elementForCurrentMonth)


  return (
    <div className={styles.MasterLineGraphConponent}>
      <TotalDepositsComponent data={amount_of_deposits} elementForMoneyCurrentMonth={elementForMoneyCurrentMonth}/>
      <UnDeliveredCardGraphComponent data={number_of_undelivered_cards} elementForCurrentMonth={elementForCurrentMonth} />
      <DeliveredCardGraphComponent data={number_of_delivered_cards} elementdeliveredForCurrentMonth={elementdeliveredForCurrentMonth} />
    </div>
  );
};

export default MasterLineGraphConponent;
