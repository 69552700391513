
import React, { useRef, useEffect, useState } from "react";
import { Chart } from "chart.js/auto";
import styles from "./HistogramChart.module.css";
import { useSearchParams } from "react-router-dom";

const HistogramChart = ({ statistics_charts }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const[word,setWord]=useState('يوم');

  const [searchParams, setSearchParams] = useSearchParams();

 
  
  console.log('word',word)

  useEffect(() => {
    

    if (statistics_charts && statistics_charts.length > 0) {
      const months = statistics_charts.map((item) => item.month);
      const requestsData = statistics_charts.map(
        (item) => item.number_of_requests
      );
      const membershipData = statistics_charts.map(
        (item) => item.number_of_membership
      );

      const chartColors = {
        type1: "#2AAA8A",
        type2: "#0088FF",
      };

      const chartData = {
        labels: months,
        datasets: [
          {
            label: "العضويات",
            data: membershipData,
            backgroundColor: chartColors.type2,
            borderColor: chartColors.type2,
            borderWidth: 1,
            barPercentage: 0.6,
            borderRadius: 25,
          },
          {
            label: "الطلبات",
            data: requestsData,
            backgroundColor: chartColors.type1,
            borderColor: chartColors.type1,
            borderWidth: 0,
            barPercentage: 0.6,
            borderRadius: 25,
          },
        ],
      };

      setChartData(chartData);
    }
  }, [statistics_charts]);

  useEffect(() => {
    if (chartRef.current && chartData) {
      chartRef.current.style.minHeight = '500px';
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }

      const ctx = chartRef.current.getContext("2d");

      chartRef.current.chart = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "top",
              labels: {
                font: {
                  family: "Cairo",
                  size: 12,
                  weight: "bold",
                },
                usePointStyle: true,
                pointStyle: "circle",
              },
            },
          },
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 10,
              bottom: 10,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return value;
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        },
      });
    }

    let word='يوم';

    if(searchParams.get('filter')=='month') word='شهر';
    if(searchParams.get('filter')=='week') word='اسبوع';
    if(searchParams.get('filter')=='day') word='يوم';

    setWord(word);
  }, [chartData]);

  return (
    <div className={styles.HistogramChartMasterContainer}>
      <h2 className={styles.HistogramChartTitle}> عدد الطلبات حسب كل {word}  </h2>
      <div className={styles.HistogramChartContainer}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default HistogramChart;
