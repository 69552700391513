import { useEffect, useRef, useState } from "react";
import { getAllPermissionGroups, updateOnePermissionGroup , addOnePermissionGroup } from "../../redux/slices/permissions/thunk";
import { useDispatch, useSelector } from "react-redux";

export default function AllPermisionsHook() {
  const [showModal, setShowModal] = useState(false);
  const [selectedNameGroup, setSelectedNameGroup] = useState('');
  const [isLoading, setIsLoading]= useState(false)

  const updateID = useRef(0);
  const updateField = useRef();

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getAllPermissionGroups());
  }, []);

  const permissionGroups= useSelector(state => state.permissionsReducer.permissionGroups);

 // console.log('permissions', permissions);
  console.log('selectedNameGroup', selectedNameGroup);
  console.log('updateID', updateID.current);

  const handleAddOrUpdate = async () => {
    try {
      //edit
      if (updateID.current != 0) {
        let updateObj = {};
        if (updateField.current == 'name') {
          updateObj.nameAr = selectedNameGroup;
          //updateObj.nameEn = selectedNameGroup;
        }
        setIsLoading(true);
        await dispatch(updateOnePermissionGroup({ id: updateID.current, data: updateObj }));

        setShowModal(false);
        updateID.current=0;
        updateField.current="";
      }
      else{
        //{data:{....}}
        let addObj={
          nameAr:selectedNameGroup,
          nameEn:selectedNameGroup,
          status:true
        };
        setIsLoading(true)
        await dispatch(addOnePermissionGroup({data:addObj}));
        setShowModal(false);
      }

    } catch (error) {
          console.error(error.message);
    } finally{
      setIsLoading(false);
    }

  }

  const handleUpdateStatus=async(p)=>{
    try {
      let updateObj = {};
      updateObj.status=!p?.status;
      console.log('handleUpdateStatus.................');
      await dispatch(updateOnePermissionGroup({ id: p?._id, data: updateObj }));

    } catch (error) {
      console.error(error);
    }
    
  }



  return [
    permissionGroups,
    showModal, setShowModal,
    selectedNameGroup, setSelectedNameGroup,
    updateID,
    updateField,
    handleAddOrUpdate,
    handleUpdateStatus,
    isLoading
  ];
}
