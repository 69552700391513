import React, { useState } from "react";
import style from "./WalletFilter.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserWalletInfo, resetWallet  } from "../../redux/slices/wallet/thunk";
import SendWithdrawalRequestModal from "./SendWithdrawalRequestModal";
import { Spinner } from "react-bootstrap";

const WalletFilter = ({startDate, setStartDate, endDate, setEndDate, hasFilter, setHasFilter}) => {
  const [salesID, setSalesID] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const[showWithDrawModal,setShowWithDrawModal]=useState(false);

  const dispatch = useDispatch();

  const loggedUser = JSON.parse(localStorage.getItem('user'));

  let users = useSelector(state => state.usersReducer.users);

  users = users?.filter(el => el.liquidation_user && !el.isAdmin);

  const handleSearch = async () => {

    setHasFilter(true)

    let query = ``;

    if (loggedUser?.isAdmin) query = `sales_id=${salesID}&`;
    else query = `sales_id=${loggedUser?._id}&`;

    query += `startDate=${startDate}&endDate=${endDate}`;
    setIsLoading(true);
    await dispatch(getUserWalletInfo(query));
    setIsLoading(false);

  }

  const canclelFilter = async () => {
    setHasFilter(false)
    setStartDate('');
    setEndDate('');
    setSalesID(0);
    //resetWallet
    let query = ``;
    if (loggedUser?.isAdmin) {
      dispatch(resetWallet());
    }
    else {
      //query=`sales_id=${salesID}`;
      query = `sales_id=${loggedUser?._id}`;
      await dispatch(getUserWalletInfo(query));

    }

  }

  //console.log('showWithDrawModal',showWithDrawModal)

  return (
    <>
    {/* {showWithDrawModal&&<SendWithdrawalRequestModal showModal={showWithDrawModal} setShowModal={setShowWithDrawModal} />} */}

      <div className={`${style.parent}` } style={{marginTop:"1rem"}}>
        <div className={style.Container1}>
          {
            loggedUser?.isAdmin &&
            <div className={style.userName}>
              <label className={style.UserNameLabel}>اسم المستخدم</label>
              <select
                className={style.userNameSelect}
                value={salesID}
                onChange={(e) => setSalesID(e.target.value)}
              >
                <option value={'0'}>اختر المستخدم </option>
                {
                  users?.map((el, i) =>
                    <option key={i} value={el?._id}> {el?.fullname} </option>
                  )
                }
              </select>
            </div>
          }

          <div className={style.Date}>
            <label className={style.DateLabel}>تاريخ البدء</label>
            <input
              className={style.DateInput} type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className={style.Date}>
            <label className={style.DateLabel}>تاريخ النهاية</label>
            <input
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={style.DateInput} type="date" />
          </div>




        </div>
        <div className={style.Container2}>

          <div style={{height:'75px'}}>
            {/* {
              loggedUser?.liquidation_user &&
              <div className={`${style.filteration}`} onClick={() => setShowWithDrawModal(true)} > ارسال طلب سحب </div>
            } */}
          </div>
          <div className={style.filteration} disabled={isLoading} onClick={() =>  handleSearch() } >{isLoading && <Spinner />} فلترة</div>
          <div className={style.cancelfilteration} onClick={() => canclelFilter()}>إلغاء الفلترة</div>
        </div>
      </div>
      <div className={style.line}></div>
    </>
  );
};

export default WalletFilter;
