import { Navigate , useLocation} from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import AddMemberComponent from "../../Components/members/Add-Member/AddMemberComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetVerifiedMemberShip } from "../../redux/slices/memberShips/thunk";
import RenewMemberShipComponent from "./RenewMemberShipComponent";

export default function AddMemberPage() {
  const screenID = "665f0e0970673dba321d15f7";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const { verifiedMemberShip, isLoading } = useSelector(
    (state) => state.membersReducer
  );

  // useEffect(() => {
  //     return () => dispatch(resetVerifiedMemberShip());
  // }, []);

  console.log("verifiedMemberShip in parent", verifiedMemberShip);

  let location = useLocation();

  console.log("location",location)

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access = true;

  if (access == false )
    return <Navigate to="/setting/profile?section=Profile" />;

  if (loggedUser?.is_accountant && location.state == null )
    return <Navigate to="/setting/profile?section=Profile" />;


  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard" style={{ overflow: "hidden" }}>
        {verifiedMemberShip ? (
          <RenewMemberShipComponent />
        ) : (
          <AddMemberComponent />
        )}
      </div>
    </div>
  );
}
