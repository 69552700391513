import SideBar from "../../Components/Utilities/SideBar";
import AddAndEditOrderComponent from "../../Components/orders/AddAndEditOrderComponent";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import { Navigate } from "react-router-dom";


export default function AddOrderPage() {
  const screenID = "665f0e4d70673dba321d1600";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access = true;

  if (access == false)
    return <Navigate to="/setting/profile?section=Profile" />;
  //CheckForPermission(screenID);
  return (
    
    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-3 w-100 dashboard">
        <AddAndEditOrderComponent />
    </div>

  </div>
  )
}
