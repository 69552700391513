import { useSearchParams } from "react-router-dom";
import PdfBox from "../Utilities/PdfBox";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getAllOrders, getAllOrdersWithPagination } from "../../redux/slices/orders/thunk";
import {getAllAdmins} from "../../redux/slices/users/thunk";


export default function SearchSection({ handleDownload, handlePrint }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

 

  //requestStatus=${searchParams.get('requestStatus')}&startDate=4/19/2024&endDate=4/21/2024
  const handleSetSearchType = (search_type) => {
    searchParams.set("requestStatus", search_type);
    setSearchParams(searchParams);

    if (search_type == "enterNew") return;
    let query = `requestStatus=${searchParams.get("requestStatus")}&`;

    // مندوب
    if (user?.liquidation_user || user?.userMarketer) query += `user=${user?._id}`;
    //   // مسوق
    //   if(user?.userMarketer){
    //     query+=`user=${user?.father_id}`;
    //     // document.getElementsByClassName('addButton')[0].display='none';
    // } 

    // const query=`requestStatus=${searchParams.get('requestStatus')}`;

    query += '&page=1';

    searchParams.set('page', 1);
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    searchParams.delete("admin_request"); // Clear admin_request when changing filter type
    searchParams.delete("isTransfered"); 
    setSearchParams(searchParams);

    dispatch(getAllOrdersWithPagination(query));
    setStartDate("");
    setEndDate("");
    
  };
  const handleSetSearchAdmin = () => {
    searchParams.set("admin_request", true);
    setSearchParams(searchParams);
    

    let query = `admin_request=${searchParams.get("admin_request")}`;
    query += '&page=1';

    searchParams.set('page', 1);
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams);

    dispatch(getAllOrdersWithPagination(query));
    setStartDate("");
    setEndDate("");
    
  };
  const handleTransferRequest = () => {
    searchParams.set("isTransfered", true);
    setSearchParams(searchParams);
    

    let query = `isTransfered=${searchParams.get("isTransfered")}&`;
    query +=`user_transfered_request=${user?._id}`
    query += '&page=1';

    searchParams.set('page', 1);
    searchParams.delete('requestStatus');
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams);

    dispatch(getAllOrdersWithPagination(query));
    setStartDate("");
    setEndDate("");
    
  };



  // console.log('startDate',startDate);

  const dateFilter = async () => {
    // const query=`requestStatus=${searchParams.get('requestStatus')}&startDate=${startDate}&endDate=${endDate}`;

    let query = `requestStatus=${searchParams.get(
      "requestStatus"
    )}&startDate=${startDate}&endDate=${endDate}&`;

    // مندوب
    if (user?.liquidation_user || user?.userMarketer) query += `user=${user?._id}`;
    //   // مسوق
    //   if(user?.userMarketer){
    //     query+=`user=${user?.father_id}`;
    //     // document.getElementsByClassName('addButton')[0].display='none';
    // } 
    query += '&page=1';
    searchParams.set('page', 1);
    searchParams.set('startDate',startDate);
    searchParams.set('endDate',endDate);
    setSearchParams(searchParams);

    dispatch(getAllOrdersWithPagination(query));
  };

  const cancelFilter=async()=>{
    let query='';
    query += '&page=1';

    searchParams.set('page', 1);
    searchParams.set('requestStatus','new_request');
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    setSearchParams(searchParams);

    dispatch(getAllOrdersWithPagination(query));
  }

  // console.log('searchParams', searchParams.get('saerch_type'));
  return (
    <div> 
         <div className="my-1 d-flex justify-content-end">
          <PrintAndDownload onDownload={handleDownload} onPrint={handlePrint} />
        </div>
      <div className="d-flex justify-content-between"> 
      
      <div className="d-flex justify-content-between" style={{ flexWrap:"wrap", width:"100%"}}>
        <div className="d-flex betweenAndCenterResponsive" style={{ gap:"10px",  flexWrap:"wrap", width:"100%"}}>
          <div className="d-flex flex-column">
            <div className="title my-2 ">تاريخ بدء البحث</div>
            <input
              value={startDate.replace(/\//g, "-")}
              onChange={(e) => setStartDate(e.target.value.replace(/-/g, "/"))}
              className="title inputsReduisAndPadding2"
              type="date"
              style={{ color: "rgba(121, 121, 129, 1)",  minWidth:"150px" }}
            />
          </div>

          <div className="d-flex flex-column">
            <div className="title my-2 width100inmobile">تاريخ نهاية البحث</div>
            <input
           
              value={endDate.replace(/\//g, "-")}
              onChange={(e) => setEndDate(e.target.value.replace(/-/g, "/"))}
              className="title inputsReduisAndPadding2"
              type="date"
              style={{ color: "rgba(121, 121, 129, 1)",  minWidth:"150px" }}
            />
          </div>

          <div className="d-flex flex-column justify-content-end">
            <button onClick={() => dateFilter()} className="green-filter-btn"  style={{minWidth:"150px"}}
            >
              {" "}
              فلترة علي حسب التاريخ{" "}
            </button>
          </div>

          <div className="d-flex flex-column justify-content-end">
            <button onClick={() => cancelFilter()} className="green-filter-btn" style={{border:'none',background:'rgba(254, 170, 0, 1)', minWidth:"150px"}}>
              {" "}
              الغاء الفلتر{" "}
            </button>
          </div>

        </div>

        
      </div>
   
      </div>

      <div
        className="d-flex justify-content-center mt-4"
        style={{ gap: "32px", marginTop: "30px", marginBottom: "30px", flexWrap:"wrap"}}
      >
        <button
          onClick={() => handleSetSearchType("new_request")}
          style={{minWidth:"150px"}}
          className={`inputsReduisAndPadding2 orders_search_btn ${searchParams.get("requestStatus") == "new_request"
              ? "orders_search_btn_active"
              : ""
            }`}
        >
          الطلبات الجديدة
        </button>
        <button
          onClick={() => handleSetSearchType("paid_request")}
          style={{minWidth:"150px"}}
          className={`inputsReduisAndPadding2 orders_search_btn ${searchParams.get("requestStatus") == "paid_request"
              ? "orders_search_btn_active"
              : ""
            }`}
        >
          الطلبات المدفوعة
        </button>
        <button
          onClick={() => handleSetSearchType("not_paid_request")}
          style={{minWidth:"150px"}}
          className={`inputsReduisAndPadding2 orders_search_btn ${searchParams.get("requestStatus") == "not_paid_request"
              ? "orders_search_btn_active"
              : ""
            }`}
        >
          الطلبات الغير مدفوعة
        </button>
        <button
          onClick={() => handleSetSearchType("exported")}
          style={{minWidth:"150px"}}
          className={`inputsReduisAndPadding2 orders_search_btn ${searchParams.get("requestStatus") == "exported"
              ? "orders_search_btn_active"
              : ""
            }`}
        >
          {" "}
          مصدرة
        </button>
        <button
          onClick={() => handleSetSearchType("archived")}
          style={{minWidth:"150px"}}
          className={`inputsReduisAndPadding2 orders_search_btn ${searchParams.get("requestStatus") == "archived"
              ? "orders_search_btn_active"
              : ""
            }`}
        >
          {" "}
          تم الارشفة{" "}
        </button>
        <button
          onClick={() => handleTransferRequest()}
          style={{minWidth:"150px"}}
          className={`inputsReduisAndPadding2 orders_search_btn ${searchParams.get("isTransfered") == "true"
              ? "orders_search_btn_active"
              : ""
            }`}
        >
          {" "}
          الطبات المحولة {" "}
        </button>
       { user?.isAdmin && <button
          onClick={() => handleSetSearchAdmin()}
          style={{minWidth:"150px"}}
          className={`inputsReduisAndPadding2 orders_search_btn ${searchParams.get("admin_request") == "true"
              ? "orders_search_btn_active"
              : ""
            }`}
        >
          {" "}
           طلبات مرسلة من رابط منتهي{" "}
        </button>}
        {/* <button
          onClick={() => handleSetSearchType("all")}
          className={`orders_search_btn ${
            searchParams.get("requestStatus") == null
              ? "orders_search_btn_active"
              : ""
          }`}
        >
          {" "}
          الكل{" "}
        </button> */}
      </div>
    </div>
  );
}
