import React, { useEffect, useState } from "react";
import MasterLineGraphConponent from "./Graph/MasterLineGraphConponent/MasterLineGraphConponent";
import ChartComponent from "./Chart/ChartComponent";
import HistogramChart from "./Histogram/HistogramChart ";
import Styles from "./page2Component.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeStatistics,
  getHomeStatisticsChart,
  getHomeStatisticsOffers,
} from "../../redux/slices/home/thunk";
import { useSearchParams } from "react-router-dom";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { exportToExcel } from "../Utilities/exportToExcel";
import { Spinner as BootstrapSpinner } from "react-bootstrap"; // Import Bootstrap Spinner

const Page2Component = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [loadingStatistics, setLoadingStatistics] = useState(true);
  const [loadingOffers, setLoadingOffers] = useState(true);
  const [loadingCharts, setLoadingCharts] = useState(true);

  useEffect(() => {
    let query = "type=day";
    if (searchParams.get("filter"))
      query = `type=${searchParams.get("filter")}`;

    if (!loggedUser?.isAdmin) {
      query += `&user_id=${loggedUser?._id}`;
    }

    const fetchData = async () => {
      setLoadingStatistics(true);
      await dispatch(getHomeStatistics(query));
      setLoadingStatistics(false);
    };

    const fetchOffers = async () => {
      setLoadingOffers(true);
      await dispatch(getHomeStatisticsOffers(query));
      setLoadingOffers(false);
    };

    const fetchCharts = async () => {
      setLoadingCharts(true);
      await dispatch(getHomeStatisticsChart(query));
      setLoadingCharts(false);
    };

    fetchData();
    fetchOffers();
    fetchCharts();
  }, [dispatch]);

  const statistics = useSelector((state) => state.homeReducer.statistics);
  const statistics_offers = useSelector(
    (state) => state.homeReducer.statistics_offers
  );
  const statistics_charts = useSelector(
    (state) => state.homeReducer.statistics_chart
  );
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }
      .table-header {
        background-color: #f2f2f2;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    `);
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");
    printWindow.document.write("<table>");

    // Table headers
    printWindow.document.write("<tr>");
    printWindow.document.write(
      "<th class='table-header'>عدد البطاقات غير المسلمة</th>"
    );
    printWindow.document.write(
      "<th class='table-header'>عدد البطاقات المسلمة</th>"
    );
    printWindow.document.write("<th class='table-header'>كمية الودائع	</th>");
    printWindow.document.write("<th class='table-header'>الشهر</th>");

    printWindow.document.write("</tr>");

    // Table data
    statistics?.forEach((member) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(
        `<td>${member?.number_of_undelivered_cards}</td>`
      );
      printWindow.document.write(
        `<td>${member?.number_of_delivered_cards}</td>`
      );
      printWindow.document.write(`<td>${member?.amount_of_deposits}</td>`);
      printWindow.document.write(`<td>${member?.month}</td>`);

      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("<table>");

    printWindow.document.write("<tr>");
    printWindow.document.write("<th class='table-header'>العرض بالعربي</th>");

    printWindow.document.write(
      "<th class='table-header'>العرض بالانجليزي</th>"
    );
    printWindow.document.write("<th class='table-header'>عدد المدفوعات	</th>");
    printWindow.document.write("<th class='table-header'>عدد الكروت</th>");

    printWindow.document.write("<th class='table-header'>السعر</th>");

    printWindow.document.write("</tr>");
    printWindow.document.write("<tr>");

    statistics_offers?.forEach((member) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(`<td>${member?.labelAr}</td>`);
      printWindow.document.write(`<td>${member?.labelEn}</td>`);
      printWindow.document.write(`<td>${member?.num_of_paid}</td>`);
      printWindow.document.write(`<td>${member?.num_of_cards}</td>`);
      printWindow.document.write(`<td>${member?.price}</td>`);

      printWindow.document.write("</tr>");
    });
    printWindow.document.write("</table>");

    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };
  const handleExport = () => {
    exportToExcel([...statistics, ...statistics_offers], "home_stat");
  };

  return (
    <div className={Styles.MasterPage2}>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <PrintAndDownload
          hasExcelDownload={true}
          onPrint={handlePrint}
          download_excel={handleExport}
        />
      </div>
      {loadingStatistics ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <BootstrapSpinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </BootstrapSpinner>
        </div>
      ) : (
        <MasterLineGraphConponent statistics={statistics} />
      )}
      {loadingOffers ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <BootstrapSpinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </BootstrapSpinner>
        </div>
      ) : (
        <ChartComponent statistics_offers={statistics_offers} />
      )}
      {loadingCharts ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <BootstrapSpinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </BootstrapSpinner>
        </div>
      ) : (
        <HistogramChart statistics_charts={statistics_charts} />
      )}{" "}
    </div>
  );
};

export default Page2Component;
