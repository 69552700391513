import { createSlice } from "@reduxjs/toolkit";
import {
  getHomeStatistics,
  getHomeStatisticsChart,
  getHomeStatisticsOffers,
} from "./thunk";

const initialState = {
  statistics: [],
  statistics_offers: [],
  statistics_chart: [],
  error: null,
};

const homeSlice = createSlice({
  initialState,
  name: "homeSlice",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHomeStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload;
    });
    builder.addCase(getHomeStatisticsOffers.fulfilled, (state, action) => {
      state.statistics_offers = action.payload;
    });
    builder.addCase(getHomeStatisticsChart.fulfilled, (state, action) => {
      state.statistics_chart = action.payload;
    });
  },
});

export default homeSlice.reducer;
