import { createSlice } from "@reduxjs/toolkit";
import { addOneOrder,getAllOrders,updateOrder , getAllOrdersWithPagination } from "./thunk";

const initialState={
    orders:[],
    ordersWithPagination:[]
};

const ordersSlice=createSlice({
    initialState,
    name:"ordersSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(addOneOrder.fulfilled,(state,action)=>{
            state.orders=[...state.orders,action.payload];
        });
        builder.addCase(getAllOrders.fulfilled,(state,action)=>{
            state.orders=action?.payload;
        });
        builder.addCase(updateOrder.fulfilled,(state,action)=>{

           // console.log('action.payload',action.payload);

            // state.ordersWithPagination.data = state?.ordersWithPagination?.data?.map((order) =>
            //     order?._id.toString() === action.payload?._id.toString()
            //       ? { ...order, ...action.payload }
            //       : order
            //       );
        });

        builder.addCase(getAllOrdersWithPagination.fulfilled,(state,action)=>{
           // console.log('action?.payload',action?.payload);
            state.ordersWithPagination=action?.payload;
        });
      
    }
});

export default ordersSlice.reducer;