import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteOneLiquidation } from "../../redux/slices/liquidation/thunk";

export default function ConfirmDeleteModal({ deleteItem, setDeleteItem, showModal, setShowModal }) {

    const dispatch=useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));


    const handleDeleteItem=async()=>{
        await dispatch(deleteOneLiquidation({
            id:deleteItem?._id,
            data:{
                user_id:user?._id
            }
        }));
        setDeleteItem(null);
        setShowModal(false);
    }

    const handleClose=()=>{
        setDeleteItem(null);
        setShowModal(false);
    }
    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Body>
                <div className="label text-center d-block"> تأكيد الحذف </div>
             
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-center border-0">
                <Button
                    onClick={() => handleDeleteItem()}
                    className="w-25"
                    variant="danger"
                    //style={{ backgroundColor: "#0080FB" }}
                >
                    حذف
                </Button>
                <Button
                    onClick={() => handleClose()}
                    className="w-25"
                    variant="primary"
                    style={{ backgroundColor: "#0080FB" }}
                >
                    الغاء
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
