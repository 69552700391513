import React, { useRef, useState } from "react";
import BtnGroup from "./BtnGroup";
import MemberDetailsCardItem from "./MemberDetailsCardItem";

export default function MainMemberDetailsCard({ data, main }) {


  return (
    <div>
      <MemberDetailsCardItem label={"الرقم التسلسلي"} data={main?.membership_number} />
      <MemberDetailsCardItem
        label={"تاريخ الاصدار"}
        data={main?.start_date.split("T")[0]}
      />
      <MemberDetailsCardItem
        label={"تاريخ الانتهاء"}
        data={main?.end_date.split("T")[0]}
      />
      <MemberDetailsCardItem
        label={"نوع العضوية"}
        data={main?.offer?.offerNameAr}
      />
      <MemberDetailsCardItem label={"سعر العضوية"} data={main?.price} />
      <MemberDetailsCardItem
        label={"طريقة الدفع"}
        data={main?.payment_method}
      />
    </div>
  );
}
