import React, { useEffect, useRef } from "react";
import styles from "./Billing.module.css";
import QRCode from "qrcode.react";

export default function Billing({ receipt, setQRCodeData }) {
  const qrRef = useRef(null);

  const QRCodeData = receipt?.qrcode64;
  console.log("__________", receipt);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? "Invalid Date" : date.toISOString().split("T")[0];
  };
  useEffect(() => {
    // Generate the QR code image data URL
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector("canvas");
      if (canvas) {
        const qrDataURL = canvas.toDataURL("image/png");
        setQRCodeData(qrDataURL); // Pass the data URL to the parent component
      }
    }
  }, [QRCodeData, setQRCodeData]);
  console.log("receipt?.membership",receipt)

  return (
    <div
      className={styles.BillingContainer}
      id="receiptTable"
      style={{ flexWrap: "wrap" }}
    >
      <div className={styles.Billing} style={{ flexWrap: "wrap" }}>
        <div className={styles.BillingSalesInfoContainer}>
          <div className={styles.BillingSalesInfoContent}>
            <span>بيانات البائع</span>
            <span></span>
            <span>seller info</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>اسم الشركة</span>
            <span></span>
            <span>company name</span>
          </div>
          <div className={styles.BillingCompanyName}>
            <span>شركة تكافل العربية</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>اسم الفرع</span>
            <span></span>
            <span>branch name</span>
          </div>
          <div className={styles.BillingCompanyName}>
            <span>الفرع الرئيسي</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>رقم المبني</span>
            <span>4</span>
            <span>building no</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>اسم الشارع</span>
            <span>حي المرسلات</span>
            <span>address</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>المدينة</span>
            <span>الرياض</span>
            <span>city</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>المنطقة</span>
            <span>المنطقة الوسطي</span>
            <span>area</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>البلد</span>
            <span>السعودية</span>
            <span>country</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>الرمز البريدي</span>
            <span>9200009460</span>
            <span>postal code</span>
          </div>
          <div className={styles.BillingSalesInfoContent}>
            <span>الرقم البريدي</span>
            <span>302006361700003</span>
            <span>vat no</span>
          </div>
        </div>
        <div className={styles.BillingInfoAndCustomerInfoContainer}>
          <div className={styles.BillingInfoContainer}>
            <div className={styles.BillingInfoContent}>
              <span>رقم الفاتورة inv no</span>
              <span>{receipt ? receipt?.serial_number : "serial_number"}</span>
            </div>
            <div className={styles.BillingInfoContent}>
              <span>تاريخ الفاتورة data</span>
              <span>
                {receipt ? formatDate(receipt?.createdAt) : "createdAt"}
              </span>
            </div>
            <div className={styles.BillingInfoContent}>
              <span>تاريخ التوريد s.data</span>
              <span>
                {receipt ? formatDate(receipt?.createdAt) : "createdAt"}
              </span>
            </div>
            <div className={styles.BillingInfoContent}>
              <span>الوصف desc</span>
              <span>{receipt?.membership?.offer?.offerNameAr}</span>
            </div>
          </div>
          <div className={styles.BillingCustomerInfoContainer}>
            <div className={styles.BillingInfoContent}>
              <span>بيانات العميل</span>
              <span></span>
              <span>customer info</span>
            </div>
            <div className={styles.BillingInfoContent}>
              <span>اسم العميل</span>
              <span></span>
              <span>customer name</span>
            </div>
            <div className={styles.BillingCompanyName}>
              <span>{receipt ? receipt?.membership?.name : "name"}</span>
            </div>
            <div className={styles.BillingInfoContent}>
              <span>رقم الجوال</span>
              <span>{receipt ? receipt?.membership?.mobile : "mobile"}</span>
              <span>mobile no</span>
            </div>
          </div>
        </div>
      </div>
      <table className={styles.BillingTable}>
        <tr>
          <th>
            <span>الرمز</span>
            <span>item code</span>
          </th>
          <th>
            <span>الشرح</span>
            <span>description</span>
          </th>
          <th>
            <span>الكمية</span>
            <span>qty</span>
          </th>
          <th>
            <span>السعر</span>
            <span>price</span>
          </th>
          <th>
            <span>الخصم</span>
            <span>disc</span>
          </th>
          <th>
            <span>السعر الإجمالي</span>
            <span>total price</span>
          </th>
          <th>
            <span>الضريبة %</span>
            <span>vat %</span>
          </th>
          <th>
            <span>قيمة الضريبة</span>
            <span>vat</span>
          </th>
          <th>
            <span>الصافي</span>
            <span>net price</span>
          </th>
        </tr>
        <tr>
          <td>
            {receipt
              ? receipt?.membership?.membership_number
              : "membership_number"}
          </td>
          <td>{receipt?.membership?.offer?.offerNameAr}</td>
          <td>
            {receipt
              ? receipt?.membership?.offer?.numberOfCards
              : "membership.offer.numberOfCards"}
          </td>
          <td>
            {receipt
              ? receipt?.membership?.price
              : "membership.membership.price"}
          </td>
          <td>{receipt && receipt?.tax_value}</td>
          <td>
            {receipt ? receipt?.total_price : "membership.membership.price"}
          </td>
          <td>{receipt ? receipt?.tax : "tax"}</td>{" "}
          <td>{receipt && receipt?.tax_value}</td>
          <td>
            {receipt
              ? receipt?.membership?.price
              : "membership.membership.price"}
          </td>
        </tr>
      </table>
      <div className={styles.BillingTable2andQRCode}>
        <table className={styles.BillingTable2}>
          <tr>
            <td>
              <span>الإجمالي</span>
              <span>amont</span>
            </td>
            <td>
              {" "}
              {receipt
                ? receipt?.membership?.price
                : "membership.membership.price"}
            </td>
          </tr>
          <tr>
            <td>
              <span>الإجمالي</span>
              <span>discount</span>
            </td>
            <td>{receipt && receipt?.tax_value}</td>
          </tr>
          <tr>
            <td>
              <span>الإجمالي</span>
              <span>{receipt && receipt?.tax}</span>
            </td>
            <td> {receipt && receipt?.tax_value}</td>
          </tr>
          <tr>
            <td>
              <span>الصافي</span>
              <span>net amount</span>
            </td>
            <td>
              {" "}
              {receipt
                ? receipt?.membership?.price
                : "membership.membership.price"}
            </td>
          </tr>
        </table>
        <div className={styles.QRCode} ref={qrRef}>
          <QRCode value={QRCodeData} size={170} fgColor="#000000" />
        </div>
      </div>
    </div>
  );
}
