import { useEffect, useState } from "react";
import Select from "../Utilities/Select";
import { useDispatch, useSelector } from "react-redux";
import { addNewLiquidation, getAllLiquidation, getLiquidationForSearch } from "../../redux/slices/liquidation/thunk";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import LiquidationBtns from "./LiquidationBtns";
import notify from "../../hook/useNotification";
//liquidationReducer
export default function AddComponent() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [liquidationNumberID, setLiquidationNumberID] = useState('');
    const [selectedUsers, setSelectedUsers] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();


    const user = JSON.parse(localStorage.getItem('user'));
    const accountantPage = location.pathname.includes('/accountant');

    useEffect(() => {
        if (accountantPage) dispatch(getLiquidationForSearch(user?._id))
    }, []);




    let users = useSelector(state => state.usersReducer.users);
    const liquidationSearch = useSelector(state => state.liquidationReducer.liquidationSearch);

    users = users?.filter(el => el.liquidation_user && !el.isAdmin);

    const addOrSearchLiquidation = async () => {

        // console.log('startdate',new Date(startDate));
        // console.log('enddate',new Date(endDate));

        // console.log('compare',new Date(endDate) > new Date(startDate) );
        // return;
        if(startDate=='' || endDate=='') return notify('من فضلك ادخل تاريخ البداية والنهاية','error');
        if(new Date(endDate) < new Date(startDate)) return notify('يجب ان يكون تاريخ البداية اقل من تاريخ النهاية','error');
        let data = {
            accountant_id: user?._id,
            usersList: [selectedUsers],
            date_from: startDate,
            date_to: endDate
        };
        console.log("data",data)
        const query = `accountant_id=${user?._id}`;

        setIsLoading(true);
        await dispatch(addNewLiquidation({ data }));
        await dispatch(getAllLiquidation(query));
        setIsLoading(false);

      
    }

    const canclelFilter = async () => {
        setStartDate('');
        setEndDate('');
        setLiquidationNumberID('');
        setSelectedUsers('');

        await dispatch(getAllLiquidation(user?._id));
    }
        


    return (
        <div style={{width:"90%"}}>
        <div style={{width:"100%"}}>
            <LiquidationBtns />
        </div>
            <div className="d-flex justify-content-between  usersSearchBox my-3" style={{width:"90%", margin:"auto", flexWrap:"wrap"}}>
                {isLoading && <Spinner />}

                <div className="d-flex gap-3" style={{flexWrap:"wrap"}}>
                    <div className="d-flex flex-column justify-content-end">
                        <Select
                            value={selectedUsers}
                            onChange={(e) => setSelectedUsers(e.target.value)}
                            label={'اسم المستخدم'}
                            options={users}
                            labelSelect='fullname'
                            defaultOption='اسم المستخدم'
                            width={'auto'}
                            classnam={"inputsReduisAndPadding"}

                        />

                    </div>
                    {
                        accountantPage &&
                        <div className="d-flex flex-column justify-content-end">
                            <Select
                                value={liquidationNumberID}
                                onChange={(e) => setLiquidationNumberID(e.target.value)}
                                label={'رقم التصفية'}
                                options={liquidationSearch}
                                labelSelect='liquidation_number'
                                defaultOption='رقم التصفية'
                                width={'auto'}
                                classnam={"inputsReduisAndPadding"}
                            />

                        </div>
                    }

                    <div className="d-flex flex-column">
                        <div className="title my-3">تاريخ بدء التصفية</div>
                        <input
                            value={startDate.replace(/\//g, "-")}
                            onChange={(e) => setStartDate(e.target.value.replace(/-/g, "/"))}
                            className="title inputsReduisAndPadding2"
                            type="date"
                            style={{ color: 'rgba(121, 121, 129, 1)' }} />
                    </div>

                    <div className="d-flex flex-column">
                        <div className="title my-3 ">تاريخ نهاية التصفية</div>
                        <input
                            value={endDate.replace(/\//g, "-")}
                            onChange={(e) => setEndDate(e.target.value.replace(/-/g, "/"))}
                            className="title inputsReduisAndPadding2"
                            type="date"
                            style={{ color: 'rgba(121, 121, 129, 1)' }} />
                    </div>

                </div>
                {
                    accountantPage && <div className="d-flex flex-column justify-content-end">
                        <button
                            onClick={() => canclelFilter()}
                            className="green-filter-btn primary-btn"
                            style={{ width: '130px', margin:"20px" }}
                        > الغاء الفلتر </button>
                    </div>
                }


                <div className="d-flex flex-column justify-content-end">
                    <button onClick={() => addOrSearchLiquidation()}
                        className="add_desposite_btn add_bank_btn_active membersBtns" 
                        disabled={isLoading}
                        >
                        {accountantPage ? 'فلترة' : 'ارسال'}
                        
                    </button>
                </div>
            </div>
        </div>

    )
}
