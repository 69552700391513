import { createSlice } from "@reduxjs/toolkit";
import { getAllPermissionGroups, updateOnePermissionGroup , addOnePermissionGroup, getAllScreens , getAllPermissionTypes,addPermissionsToScreen,getScreensForOneGroup, getUserScreens } from "./thunk";

const initialState={
    permissionGroups:[],
    screens:[],
    permissionTypes:[],
    newPermissionGroup:{},
    groupScreens:[],
    isLoading:false,
    isLoadingScreen:false,
    userScreens:[],
    error:null
};

const permissionsSlice=createSlice({
    initialState,
    name:"permissionsSlice",
    reducers:{},
    extraReducers:(builder)=>{
        /****get all permission groups******/
        builder.addCase(getAllPermissionGroups.pending,(state,action)=>{
            state.isLoading=true;
        });
        builder.addCase(getAllPermissionGroups.rejected,(state,action)=>{
            state.isLoading=false;
            state.error=action?.payload;
        });
        builder.addCase(getAllPermissionGroups.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.permissionGroups=action?.payload;
        });
        /******end*********/
        /******update one permission******/
        builder.addCase(updateOnePermissionGroup.pending,(state,action)=>{
            state.isLoading=true;
        });
        builder.addCase(updateOnePermissionGroup.rejected,(state,action)=>{
            state.isLoading=false;
            state.error=action.payload;
        });
        builder.addCase(updateOnePermissionGroup.fulfilled,(state,action)=>{
            state.permissionGroups = state.permissionGroups.map((p) =>
                p._id.toString() === action.payload?._id.toString()
                  ? { ...p, ...action?.payload }
                  : p
                  );
                  state.isLoading=false;
        });
        /*********end************/

        builder.addCase(addOnePermissionGroup.fulfilled,(state,action)=>{
            state.permissionGroups=[...state.permissionGroups,action?.payload];
        });

        /****get all screens****/
        builder.addCase(getAllScreens.pending,(state,action)=>{
            state.isLoading=true;
        });
        builder.addCase(getAllScreens.fulfilled,(state,action)=>{
            state.screens=action?.payload;
            state.isLoading=false;
        });

        builder.addCase(getAllScreens.rejected,(state,action)=>{
            state.isLoading=false;
            state.error=action.payload;
        });
        /**********end*********/

        /***** get all permission types****/
        builder.addCase(getAllPermissionTypes.pending,(state,action)=>{
            state.isLoading=true;
        });

        builder.addCase(getAllPermissionTypes.fulfilled,(state,action)=>{
            state.permissionTypes=action.payload;
            state.isLoading=false;
        });

        //********end*** */
        /******start add addPermissionsToScreen****/
        builder.addCase(addPermissionsToScreen.pending,(state,action)=>{
            state.isLoading=true;
        });

        builder.addCase(addPermissionsToScreen.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.newPermissionGroup=action.payload;
            state.groupScreens=action.payload;
        });

        builder.addCase(addPermissionsToScreen.rejected,(state,action)=>{
            state.isLoading=false;
        });

        //*********end */

        //********get screens for one group */
        builder.addCase(getScreensForOneGroup.pending,(state,action)=>{
            state.isLoading=true;
            state.isLoadingScreen=true;
        });

        builder.addCase(getScreensForOneGroup.rejected,(state,action)=>{
            state.isLoading=false;
            state.isLoadingScreen=false;
            state.error=action.payload;
        });

        builder.addCase(getScreensForOneGroup.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isLoadingScreen=false;
            state.groupScreens=action.payload;
        });

        builder.addCase(getUserScreens.fulfilled,(state,action)=>{
            state.userScreens=action?.payload;
        })
    }
});

export default permissionsSlice.reducer;
//state.groupScreens=action.payload;