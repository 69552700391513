import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import ArchievesComponent from "../../Components/deposites/archieves/ArchievesComponent";

export default function ArchivesDepositesPage() {
  const user = JSON.parse(localStorage.getItem("user"));
  let type = "";
  if (!user?.isAdmin && !user?.liquidation_user) type = "accountant";

  const screenID = "665f0eb070673dba321d1616";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access = true;

  if (access == false)
    return <Navigate to="/setting/profile?section=Profile" />;

  return (
    <div
      className="d-flex justify-content-start"
      style={{ minHeight: "700px" }}
    >
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard" style={{ overflow: "hidden" }}>
        <ArchievesComponent type={type} />
      </div>
    </div>
  );
}
