export default function Input({
  label,
  formik,
  onChange,
  width,
  minWidth,
  value,
  setValue = false,
  name,
  disabled = false,
  height,
  UserIcon,
  type = "text",
  lableClass,
  minValue = "0",
  placeholder,
  bankPattern = false,
  classnam = null,
  className = null,
  onKeyPress,
  maxLength,
  minLength,
  cursor,
  
}) {
  const number_reg = /^[0-9]*$/; // Allows only English numerals


  const replaceArabicNumbers = (str) => {
    return str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function(digit) {
      return String.fromCharCode(digit.charCodeAt(0) - 1632); // Convert Arabic to English
    });
  };
  
  return (
    <div
      className={`d-flex flex-column ${className}`}
      style={{ width: width, minWidth: minWidth }}
    >
      <div className={`label ${lableClass}`}>
        {label}
        {UserIcon}
      </div>
      <input
        className={classnam}
        type={ type}
        name={name}
        value={value}
        onKeyPress={onKeyPress}

        // onChange={
        //   setValue
        //     ? (e) =>
        //         setValue((prev) =>
        //           type == "number"
        //             ? number_reg.test(e.target.value)
        //               ? e.target.value
        //               : ""
        //             : e.target.value
        //         )
        //     : onChange
        // }

        onChange={
          setValue
            ? (e) => {
                // Replace Arabic numbers with English before setting value
                const updatedValue = replaceArabicNumbers(e.target.value);
                setValue((prev) =>
                  type === "number" && number_reg.test(updatedValue)
                    ? updatedValue
                    : updatedValue
                );
              }
            : (e) => {
                // If no setValue, process onChange as well
                const updatedValue = replaceArabicNumbers(e.target.value);
                onChange(e, updatedValue);
              }
        }
        
        placeholder={`${placeholder ? placeholder : label}`}
        readOnly={disabled}
        style={{ height: height, fontFamily: "Cairo", cursor:cursor }}
        min={minValue}
        maxLength={maxLength}
        minLength={minLength}
      />
    </div>
  );
}
