import { Navigate } from "react-router-dom";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";
import CheckMemberComponent from "../../Components/members/CheckMemberComponent";

export default function CheckMemberShipPage() {
  
  const screenID="665f0e2a70673dba321d15fa";
  const loggedUser= JSON.parse(localStorage.getItem('user'));

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access=true;

  if (access == false) return <Navigate to='/setting/profile?section=Profile' />;

  return (
    <div className="d-flex justify-content-start" style={{overflow:'hidden'}}>
   { loggedUser && <div>
      <SideBar />
    </div>}
    <div className="p-3 w-100 dashboard" style={{overflow:'hidden'}}>
      <CheckMemberComponent />
    </div>

  </div>
  )
}
