import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useInsertData } from "../../../hooks/useInsertData";
import { useGetDataToken } from "../../../hooks/useGetData";



export const paymentStatus=createAsyncThunk(
    "/paymentSlice/paymentStatus",
    async({data})=>{
        try {
            const response = await useInsertData("/api/v1/payment/paymentStatus", data);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
             return notify('حدث خطأ حاول مرة اخري ', "error");

             //notify(error.response.data, "error");
        }
    }
);
export const addPayment=createAsyncThunk(
    "/paymentSlice/addPayment",
    async({data})=>{
        try {
            const response = await useInsertData("/api/v1/payment/excecutePayment", data);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
             return notify('حدث خطأ حاول مرة اخري ', "error");

             //notify(error.response.data, "error");
        }
    }
);
export const getPaymentMethod=createAsyncThunk(
    "/paymentSlice/getPaymentMethod",
    async()=>{
        try {
            const response = await useGetDataToken("/api/v1/payment/initiatePayment");
            console.log("response",response)
            return response;
            
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
             return notify('حدث خطأ حاول مرة اخري ', "error");

             //notify(error.response.data, "error");
        }
    }
);

