import { Navigate } from "react-router-dom";
import SideBar from "../../Components/Utilities/SideBar";
import AddAndEditUser from "../../Components/users/AddAndEditUserComponent";

export default function EditUserPage() {
   //const loggedUser = JSON.parse(localStorage.getItem('user'));
   let access=true;
   const loggedUser = JSON.parse(localStorage.getItem('user'));
   if(loggedUser?.userMarketer) access=false;
   if (access == false) return <Navigate to='/setting/profile?section=Profile' />

  // // محاسب
   //if (!loggedUser.liquidation_user && !loggedUser.isAdmin) return window.history.back();
  return (
    <div className="d-flex justify-content-start">
         <div >
          <SideBar />
        </div>
        <div className="p-3 w-100">
            <AddAndEditUser />
        </div>
    </div>
  )
}
