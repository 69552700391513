import React, { useState } from 'react'

export default function CircleCheckBoxWithIcon({check,onClick,disabled=false}) {
    const[checked,setChecked]=useState(check);
  return (
    <div className="d-flex justify-content-center">
            
            <div>
            <label className="checkboxContainer">
                <input type="checkbox" checked={checked} onChange={(e)=>{
                  setChecked(e.target.checked);
                  onClick();
                } 
                } 
                disabled={disabled}
                />
                <span className="checkmark2"></span>
            </label>
            </div>
            
        </div>
  )
}
