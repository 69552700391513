import { useSearchParams } from "react-router-dom";
import CircleCheckBoxWithIcon from "../../Utilities/CircleCheckBoxWithIcon";
import { HiPencilSquare } from "react-icons/hi2";
import AddEditCloseBtnGroup from "../../Utilities/AddEditCloseBtnGroup";
import Input from "../../Utilities/Input";
import { useEffect, useState } from "react";
import CheckForPermissionTypes from "../../Utilities/CheckForPermissionTypes";

export default function DepositeTabsComponent({
  banks,
  handleUpdateStatus,
  formik,
  showItem,
  resetValues,
  onSubmit,
  isLoading,
}) {
  const [deposit_operation_type, setdeposit_operation_type] = useState("0");
  const [isBanksLoading, setIsBanksLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  let initialValues = {
    _id: "",
    settingsType: "",
    nameAr: "",
    nameEn: "",
    bankAccountNumber: "",
    deposit_operation_type:"0",
    status: true,
  };

  useEffect(() => {
    console.log("use effect");
    formik.setValues(initialValues);
  }, [searchParams]);

  useEffect(() => {
    if (banks !== undefined) {
      setIsBanksLoading(false);
    }
  }, [banks]);

  console.log("banks",banks)

  const handeltypeChange = (e) => {
    setdeposit_operation_type(e.target.value);
    let obj = {
      ...formik.values,
      // deposit_operation_type: e.target.value,
      type: "depositProessType",
    };

    console.log("initialValues", initialValues);
    formik.setValues(obj);
  };
  console.log("aaaaaa", formik.values);

  if (searchParams.get("section") !== "Deposite") return;

  console.log('DepositeTabsComponent');

  const loggedUser = JSON.parse(localStorage.getItem("user"));
  
    let hasEdit = false;
    let hasAdd = false;

    const screenID ="665f0fc870673dba321d163d";

    if (!loggedUser?.isAdmin) {
      hasEdit = CheckForPermissionTypes(screenID, 'edit');
      hasAdd = CheckForPermissionTypes(screenID, 'add');
  
    }
    else {
      hasEdit = true;
      hasAdd = true;
    }
  
    console.log('hasEdit', hasEdit);
    console.log('hasAdd', hasAdd);


  return (
    <div>
      <div
        className="d-flex justify-content-between responsiveFont responsiveMobileBankBtn "
        style={{ width: "100%" }}
      >
        <Input
          name={"nameAr"}
          label={"اسم العملية باللغة العربية"}
          value={formik.values.nameAr}
          onChange={formik.handleChange}
          className={"width45"}
          classnam={"inputsReduisAndPadding2 width100"}
        />
        <Input
          name={"nameEn"}
          label={"اسم العملية باللغة الانجليزية"}
          value={formik.values.nameEn}
          onChange={formik.handleChange}
          className={"width45"}
          classnam={"inputsReduisAndPadding2 width100"}
        />
      </div>

      <div
        className="d-flex justify-content-between colouminmobile "
        style={{ width: "100%" }}
      >
          {/* <Input
            name={"bankAccountNumber"}
            bankPattern={true}
            label={"رقم  الحساب"}
            placeholder={"XXXX-XXXX-XXXX"}
            value={formik.values.bankAccountNumber}
            onChange={formik.handleChange}
            className={"width45"}
            classnam={"inputsReduisAndPadding2 width100"}
          /> */}
        {/* <div className="d-flex flex-column width45">
          <label className="label">نوع الايداع</label>
          <select
            id="deposit_operation_type"
            value={formik.values.deposit_operation_type}
            onChange={(e) => handeltypeChange(e)}
            className="select inputsReduisAndPadding2 width100"

          >
            <option value={"0"}>من فضلك اختر نوع الايداع</option>
            <option value={"cash"}>كاش</option>
            <option value={"banking_transfer"}>التحويل البنكي</option>
            <option value={"atm"}>الصرافات</option>
            <option value={"network"}>اجهزة الشبكة</option>
            {/* <option value={"online"}>اونلاين</option> */}
        
        {/*  </select>
        </div> */}
      </div>

      <AddEditCloseBtnGroup hasAdd={hasAdd} hasEdit={hasEdit} onSubmit={onSubmit} resetValues={resetValues} isSubmitting={isLoading} />

      <div className="header" style={{ marginTop: "30px" }}>
        <div>انواع الحوالات الحالية</div>
      </div>

      <div className="permissionsTable stripe" style={{ overflow: "auto" }}>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead
            style={{
              backgroundColor: "rgba(242, 246, 255, 1)",
              padding: "3px",
            }}
          >
            <tr>
              <th style={{ padding: "5px" }}>نوع الحوالة</th>
              <th style={{ padding: "5px" }}>تشغيل /ايقاف</th>
              <th style={{ padding: "5px" }}>تعديل</th>
            </tr>
          </thead>
       {   isLoading ? (
    <p>Loading data...</p>
  ) : (
    banks && banks.length > 0 ? (
      <tbody>
        {banks.map((bank, i) => (
          <tr key={i}>
            <td>{bank?.nameAr}</td>
            <td>
              <div>
                <CircleCheckBoxWithIcon
                  onClick={() => handleUpdateStatus(bank)}
                  check={bank?.status}
                  disabled={!hasEdit}
                />
              </div>
            </td>
            <td
              onClick={() => {
                if (!hasEdit) return;
                showItem(bank);
              }}
              style={{ color: "#FEAA00", cursor: "pointer" }}
            >
              تعديل <HiPencilSquare />
            </td>
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td colSpan="100%" style={{ textAlign: "center", padding: "20px", fontSize: "18px", color: "#888" }}>
            لا يوجد بيانات للعرض
          </td>
        </tr>
      </tbody>
    )
  )}
        </table>
      </div>
    </div>
  );
}
