// src/components/CustomDropdown.js
import React from "react";
import Select from "react-select";
import "./CustomDropdown.css"; // Ensure to import the CSS file if you use external CSS

const CustomDropdown = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  labelKey,
  valueKey,
  width,
  disabled
}) => {
  if (!Array.isArray(options)) {
    console.error("Invalid options prop: expected an array.");
    return null;
  }

  // Ensure labelKey and valueKey are strings
  if (typeof labelKey !== "string" || typeof valueKey !== "string") {
    console.error("Invalid labelKey or valueKey prop: expected strings.");
    return null;
  }

  // Transform options to the format required by react-select
  const formattedOptions = options.map((option) => ({
    label: option[labelKey],
    value: option[valueKey],
  }));

  // Handle change event
  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : "");
  };

  console.log('options',options);

  return (
    <div className="custom-dropdown" style={{width : width}}>
      {label && <label className="label">{label}</label>}
      <Select
         classNamePrefix="custom-select" // Prefix for custom class names
        options={formattedOptions}
        value={formattedOptions.find((option) => option.value === value)}
        onChange={handleChange}
        placeholder={placeholder}
        isClearable
        isSearchable
        isDisabled={disabled}
        styles={{
          container: (provided) => ({
            ...provided,
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            background: "var(--background-white3)",
          }),
          menu: (provided) => ({
            ...provided,
            width: "100%", // Ensures the menu matches the width of the control
          }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: "200px", // Optional: set max height for the dropdown menu
            overflowY: "auto", // Ensure scroll if content exceeds max height
            background: "var(--background-white3)",
          }),
        }}
      />
    </div>
  );
};

export default CustomDropdown;
