import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetData } from "../../../hooks/useGetData";

export const getAllSystemScreens=createAsyncThunk(
    "/screensSlice/getAllSystemScreens",
    async()=>{
        try {
            const response=await useGetData("/api/v1/screens");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
                else
                return notify(error.response.data,"error");
        }
    }
);