import React, { useEffect, useState } from "react";
import Input from "../../Utilities/Input";
import Select from "../../Utilities/Select";
import Select2 from "../../Utilities/Select2";
import Select3 from "../../Utilities/Select3";
import { getAllBanks } from "../../../redux/slices/banks/thunk";
import { useDispatch, useSelector } from "react-redux";
import { addNewDeposite } from "../../../redux/slices/deposites/thunk";
import notify from "../../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { getAllLiquidation } from "../../../redux/slices/liquidation/thunk";
import { FormikConsumer } from "formik";
import { Spinner } from "react-bootstrap";
import UsersHeader from "../../Utilities/UsersHeader";


const AddDepositeForm = ({ type }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [operationType, setOperationType] = useState(null);
  const [depositOperationType, setDepositOperationType] = useState(" ");

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [deviceNumber, setDeviceNumber] = useState("");
  const [operationDate, setOperationDate] = useState("");
  const [bankID, setBankID] = useState(0);
  const [clientBankNumber, setClientBankNumber] = useState("");
  const [depositeSource, setDepositeSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [accountantName, setAccountantName] = useState(0);

  useEffect(() => {
    let query = ``;
    // مندوب
    if (user?.liquidation_user) query += `sales_id=${user?._id}`;
    //setIsLoading(true);
    dispatch(getAllLiquidation(query));
    //setIsLoading(false);

    dispatch(getAllBanks());
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("user",user)

  const liquidation = useSelector(
    (state) => state.liquidationReducer.liquidation
  );

  const latestLiquidation = liquidation.find((el) => el.status === "new");
  let latestLiquidationDate = "";

  if (new Date() > new Date(latestLiquidation?.date_to))
    latestLiquidationDate = new Date(latestLiquidation?.date_to);
  else latestLiquidationDate = new Date();

  console.log("latestLiquidationDate", latestLiquidationDate);

  // useEffect(() => {
  //   dispatch(getAllBanks());
  // }, []);

  const { banks } = useSelector((state) => state.banksReducer);
  const accountantUsers = useSelector((state) => state.usersReducer.accountantUsers);

  // let banks_typeBanks = [];
  // banks_typeBanks = banks?.filter((el) => el.settingsType == "bankType");
  // const network_banks_typeBanks = banks_typeBanks.filter((el)=> el?.deposit_operation_type_banks == "network")
  // const transfer_banks_typeBanks = banks_typeBanks.filter((el)=> el?.deposit_operation_type_banks == "banking_transfer")
  // const atm_banks_typeBanks = banks_typeBanks.filter((el)=> el?.deposit_operation_type_banks == "atm")
// Filter banks based on settingsType
const bankTypeBanks = banks?.filter(bank => bank.settingsType === "bankType") || [];

// Further categorize the filtered banks based on deposit operation types
const network_banks_typeBanks = bankTypeBanks.filter(bank => bank?.deposit_operation_type_banks === "network");
const transfer_banks_typeBanks = bankTypeBanks.filter(bank => bank?.deposit_operation_type_banks === "banking_transfer");
const atm_banks_typeBanks = bankTypeBanks.filter(bank => bank?.deposit_operation_type_banks === "atm");
const UserBanks = bankTypeBanks.filter(bank => bank?.deposit_operation_type_banks == depositOperationType) || [];

  //console.log('banks_typeBanks',banks_typeBanks);
  //تحويل بنكي – إيداع بنكي – جهاز شبكة – صرافة بنك – كاش
  const operationTypes = [
    { _id: "كاش" },
    { _id: "صرافة بنك" },
    { _id: "جهاز شبكة" },
    { _id: "إيداع بنكي" },
    { _id: "تحويل بنكي" },
  ];
  const banks_deposite = banks?.filter(
    (el) => el.settingsType == "depositProcessType"
  );

  //console.log('bankID',bankID);


  console.log("UserBanks",UserBanks)

  const handleAddNewDeposite = async () => {

    if(operationType == null || operationType == 0){
      return notify("يجب اختيار نوع العملية","error")
    }

    if(name == ""){
      if (depositOperationType == " ") 
         return notify(" يجب ادخال اسم المندوب ","error") 
        else
        return notify(" يجب ادخال اسم المحول ","error") 
    }
    if(price == ""){
         return notify(" يجب ادخال المبلغ ","error") 
    }
    if(clientBankNumber.length !== 4){
      return notify("يجب ادخال اخر ٤ ارقام من رقم حساب العميل","error")
    }

// ----------------------------------------------------------------


// ----------------------------------------------------------------

    let data = {
      user_id: user?._id,
      operation_type: operationType,
      adapter_name: name,
      price: price,
      deviceNumber: deviceNumber,
      operation_date: operationDate,
      account_number_for_client: clientBankNumber,
      deposit_date:
        new Date() > new Date(latestLiquidation?.date_to)
          ? latestLiquidation?.date_to
          : new Date(),
    };

    // console.log('date',new Date(latestLiquidation?.date_to),new Date());
    // return;

    if (type == "accountant") {
      data.accountant_name = depositeSource;
    } else {
      data.bank_id = bankID;
      data.deposite_source = depositeSource;

      // لو في تصفية هتزود ال key ده
      //deposit_date
      if (latestLiquidation?._id) {
        //console.log('date',new Date(latestLiquidation?.date_to));

        if (new Date() > new Date(latestLiquidation?.date_to))
          data.deposit_date = latestLiquidation?.date_to;
        else data.deposit_date = new Date();
      }
    }
    console.log("data", data);

    // const keys = Object.keys(data);
    // for (let index = 0; index < keys.length; index++) {
    //   const key = keys[index];

    //   const value = data[key];
    //   console.log(key, data.key);

    //   if (value == null || value == '') return notify("من فضلك اكمل البيانات", 'error');
    // }
    // console.log(Object.keys(data));

    if (accountantName !== 0) data.accountantName = accountantName;
    setIsLoading(true);
    await dispatch(addNewDeposite({ data }));
    setIsLoading(false);

    // setTimeout(() => {
    //   let url = '/deposites';
    //   // if(type=='accountant') url='/deposites/accountant';
    //   // else url='/deposites';
    //   navigate(url);
    // }, 1000);
  };

  console.log("operationType?._id", operationType);

  console.log("clientBankNumber", clientBankNumber.length);
  console.log("depositOperationType",depositOperationType)

  console.log("isLoading",isLoading)
  const emptyState = () => {
    setOperationType(null);
    setDepositOperationType(" ");
    setName("");
    setPrice("");
    setDeviceNumber("");
    setOperationDate("");
    setBankID(0);
    setClientBankNumber("");
    setDepositeSource("");
    setAccountantName(0);
  };


  return (
    <div>
      <div onClick={emptyState}>
      <UsersHeader title={"اضف ايداع جديد"} backgroundColorBtn={'#0080FB'} />
      </div>

    <div className="permissionsLayout mt-3" style={{ minHeight: "auto" }}>
      <div className="inputContainer justify-content-between">
        <div className="d-flex label justify-content-end align-items-end p-0 mx-auto">
          {" "}
          نوع العملية{" "}
        </div>
        <Select3
          name={"offer"}
          value={operationType}
          onChange={(e) => {
            const selectedOperation = e.target.value;
            setOperationType(selectedOperation);
            const selectedBank = banks_deposite.find(
              (el) => el._id === selectedOperation
            );
            if (selectedBank) {
              setDepositOperationType(selectedBank?._id);
            }
          }}
          options={banks_deposite?.filter((el) => el.status == true)}
          width={"87%"}
          labelSelect="nameAr"
          defaultOption="من فضلك قم ب اختيار نوع العملية"
          classnam={"inputsReduisAndPadding2"}
        />
      </div>

      <div className="inputContainer2 justify-content-between mx-2">
        <Input
          value={name}
          setValue={setName}
          name={"name"}
          label={UserBanks.length == 0 ? "اسم المندوب" : "اسم  المحول"}
          // label={type == 'accountant' ? 'اسم المندوب' : 'اسم  المحول'}
          width={"45%"}
          classnam={"inputsReduisAndPadding2"}
        />
        <Input
          type="number"
          value={price}
          setValue={setPrice}
          name={"identity_number_passport"}
          label={"المبلغ"}
          width={"45%"}
          classnam={"inputsReduisAndPadding2"}
        />
      </div>

      <div className="inputContainer2 mx-2 my-3 " style={{ gap: "119px" }}>
        <div
          className="d-flex"
          style={{ flexDirection: "column", width: "45%" }}
        >
          <div className="d-flex label p-0 ">
            {depositOperationType !=" " &&
              (UserBanks.length == 0
                ? "تاريخ الايداع"
                : "تاريخ التحويل")}
          </div>
          {depositOperationType !=" " && (
            <input
              style={{ width: "100%" }}
              onChange={(e) => setOperationDate(e.target.value)}
              value={operationDate}
              className="add-deposite-date inputsReduisAndPadding2"
              type="date"
            />
          )}
        </div>
        {/* Mokhtar change it  */}
        {/* {type !== "accountant" && depositOperationType === "online" && (   */}
        {UserBanks.length > 0 && (
          // operationType === "تحويل بنكي" ||
          // operationType === "صرافة بنك")
          <div
            className="d-flex"
            style={{ width: "45%", flexDirection: "column" }}
          >
            {/* <div className="d-flex label"> البنك </div> */}
            <Select2
              name={"offer"}
              value={bankID}
              onChange={(e) => setBankID(e.target.value)}
              options={UserBanks}
              labelSelect="nameAr"
              labelSelect2="bankAccountNumber"
              defaultOption="من فضلك قم ب اختيار  البنك"
              border={"1px solid rgba(132, 203, 0, 1)"}
              classnam={"inputsReduisAndPadding2"}
              label={"البنك"}
            />
          </div>
        )}
       
        
        {/* Mokhtar change it  */}
        {/* {type !== "accountant" && depositOperationType === "network" && ( */}

        {/* Customer change */}
        {/* {depositOperationType === "network" && (
          <div className="d-flex" style={{ width: "45%" }}>
            <Input
              type="number"
              value={deviceNumber}
              setValue={setDeviceNumber}
              name={"network_device_number"}
              label={"رقم جهاز الشبكة"}
              width={"100%"}
              classnam={"inputsReduisAndPadding2"}
            />
          </div>
        )} */}

      </div>

      {/* {
        type != 'accountant' &&
        <div className="d-flex mx-2" style={{ gap: '51px' }}>
          <div className="d-flex label justify-content-start align-items-start p-0">  تاريخ اخر تصفية </div>
          <div>  <input
            disabled
            value={latestLiquidationDate && latestLiquidationDate?.toISOString()?.split('T')[0]} className="add-deposite-date mt-0" type="date" /> </div>
        </div>
      } */}

      <div className="inputContainer2 justify-content-between mx-2">
        <Input
          value={clientBankNumber}
          setValue={setClientBankNumber}
          label={
            UserBanks.length == 0 ? "رقم السند" : "رقم حساب العميل"
          }
          // label={type == 'accountant' ? 'رقم السند' : 'رقم حساب العميل'}
          width={"45%"}
          classnam={"inputsReduisAndPadding2"}
        />

        {/* operationType == 'كاش' ? 'اسم المحاسب' : 'مصدر الحوالة' */}
        {UserBanks.length == 0 ? (
          <div
            className="inputContainer2 justify-content-between mx-2"
            style={{ width: "45%" }}
          >
            <Select3
              name={"fullname"}
              label={"اسم المحاسب"}
              value={accountantName}
              onChange={(e) => setAccountantName(e.target.value)}
              options={accountantUsers}
              labelSelect="fullname"
              defaultOption="من فضلك اختر المحاسب"
              width={"100%"}
              classnam={"inputsReduisAndPadding2"}
              optionsHeight={"100px"}
            />
          </div>
        ) : (
          <Input
            value={depositeSource}
            setValue={setDepositeSource}
            label={"مصدر الحوالة"}
            width={"45%"}
            classnam={"inputsReduisAndPadding2"}
          />
        )}
      </div>

      <div className="my-3">
        <button
          onClick={() => handleAddNewDeposite()}
          className="addButton w-100"
          disabled={isLoading}
        >

{isLoading && <Spinner />}
          {" "}
          اضافة{" "}
        </button>
      </div>
    </div>
    </div>
  );
};

export default AddDepositeForm;
