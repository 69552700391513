import { createSlice } from "@reduxjs/toolkit";
import { getAllBanks,addOneBank,updateOneBank,getSmsLinkByID } from "./thunk";

const initialState={
    banks:null,
    smsLink:""
}

const banksSlice=createSlice({
    initialState,
    name:"banksSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllBanks.fulfilled,(state,action)=>{
            state.banks=action.payload;
        });

        builder.addCase(addOneBank.fulfilled,(state,action)=>{
            state.banks=[...state.banks,action.payload];
        });

        builder.addCase(updateOneBank.fulfilled,(state,action)=>{
            state.banks = state?.banks?.map((bank) =>
            bank._id.toString() === action.payload?._id.toString()
              ? { ...bank, ...action.payload }
              : bank
              );
        });
        builder.addCase(getSmsLinkByID.fulfilled,(state,action)=>{
            state.smsLink=action?.payload;
        })
    }
});

export default banksSlice.reducer;