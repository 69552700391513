import { Button, Modal } from "react-bootstrap"
import { useState } from "react";
import { Spinner } from "react-bootstrap";


export default function EqualLiquidationModal({ showLiquidationEqualModal, setShowLiquidationEqualModal, latestLiquidation, HandleSendLiquidation }) {

    const user = JSON.parse(localStorage.getItem('user'));

    const [isLoading,setIsLoading]=useState(false)
    const sendLiquidation = async () => {
        try {
            let liquidationData = {
                status: 'send',
                user_id: user?._id,
                liquidation_balance_end: 0,
            }
    
            HandleSendLiquidation(liquidationData);   
        } catch (error) {
            console.error(error.message)
        }finally {
            setIsLoading(false);
        }
    }


    return (
        <Modal show={showLiquidationEqualModal} onHide={() => setShowLiquidationEqualModal(false)} >

            <Modal.Body>
                <div className="label d-block">
                    هل تريد ارسال التصفية
                </div>

                <div className="d-flex justify-content-between">
                    <Button onClick={() => sendLiquidation()} disabled={isLoading} variant="primary" style={{ backgroundColor: "#0080FB" }}>
                      {isLoading ? <Spinner/> :  "موافق"}
                    </Button>

                   

                    <Button onClick={() => setShowLiquidationEqualModal(false)} variant="danger">
                        الغاء
                    </Button>
                </div>

            </Modal.Body>

        </Modal>
    )
}
