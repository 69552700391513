import React, { useState } from "react";
import styles from "./CommissionrepoSearch.module.css";
import { getMarketerReport } from "../../redux/slices/marketing/thunk";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import notify from "../../hook/useNotification";

const CommissionrepoSearch = ({ report }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const canclelFilter = async () => {
    const query = `marketing_link_number=${searchParams.get(
      "marketing_link_number"
    )}`;
    await dispatch(getMarketerReport(query));

    setStartDate("");
    setEndDate("");
  };

  const handleSearch = async () => {
    if (startDate == "" || endDate == "")
      notify("من فضلك ادخل تاريخ البداية والنهاية", "error");
    let query = `marketing_link_number=${searchParams.get(
      "marketing_link_number"
    )}&start_date=${startDate}&end_date=${endDate}`;
    await dispatch(getMarketerReport(query));
  };
  return (
    <div className={styles.CommissionrepoSearchContainer}>
      <div className={styles.CommissionrepoSearchChildContainer}>
        <div className="d-flex" style={{ gap: "28px" }}>
          <div className="title my-3">تاريخ بدء البحث</div>
          <div className="d-flex flex-column justify-content-center">
            <input
              className="title"
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              style={{ color: "rgba(121, 121, 129, 1)", height: "38px" }}
            />
          </div>
        </div>
        <div className="d-flex" style={{ gap: "28px" }}>
          <div className="title my-3">تاريخ نهاية البحث</div>
          <div className="d-flex flex-column justify-content-center">
            <input
              className="title"
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              style={{ color: "rgba(121, 121, 129, 1)", height: "38px" }}
            />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <button
            onClick={() => canclelFilter()}
            className="green-filter-btn primary-btn"
            style={{ height: "38px", width: "132px" }}
          >
            {" "}
            الغاء الفلتر{" "}
          </button>
        </div>
        <div
          onClick={() => handleSearch()}
          className={styles.CommissionrepoSearchBtn}
        >
          بدء البحث
        </div>
      </div>
      <div style={{display:"flex", flexDirection:"row", gap:"50px", flexWrap:"wrap"}}>
      <div className={styles.CommissionrepoSearchBlocks}>
        <div className={styles.CommissionrepoSearchBlock}></div>
        <div className={styles.CommissionrepoSearchTextBtn}>
          <span>عدد مرات التسجيل</span>{" "}
          <span>{report?.requests_became_membership}</span>
        </div>
      </div>

      <div className={styles.CommissionrepoSearchBlocks}>
        <div className={styles.CommissionrepoSearchBlock}></div>
        <div className={styles.CommissionrepoSearchTextBtn}>
          <span>مجموع المبيعات</span> <span>{report?.sum_of_sales}</span>
        </div>
      </div>
      <div className={styles.CommissionrepoSearchBlocks}>
        <div className={styles.CommissionrepoSearchBlock}></div>
        <div className={styles.CommissionrepoSearchTextBtn}>
          <span>اجمالي العمولة</span> <span>{report?.due_commission}</span>
        </div>
      </div>

      {/* <div className={styles.CommissionrepoSearchBlocksBtns}>
        <div className={styles.CommissionrepoSearchBlockBtn}></div>
        <div className={styles.CommissionrepoSearchBlockBtn}></div>
        <div className={styles.CommissionrepoSearchBlockBtn}></div>
      </div> */}
      {/* <div className={styles.CommissionrepoSearchTextBtns}></div> */}
    </div>
    </div>

  );
};

export default CommissionrepoSearch;
