import { useEffect, useRef, useState } from "react";
import Input from "../../Utilities/Input";
import Select from "../../Utilities/Select";
import Select3 from "../../Utilities/Select3";
import { useDispatch, useSelector } from "react-redux";
import BtnGroupComponent from "./BtnGroupComponent";
import { addOneMemberShip } from "../../../redux/slices/memberShips/thunk";
import notify from "../../../hook/useNotification";
import { Spinner } from "react-bootstrap";
import { getAllCities } from "../../../redux/slices/cities/thunk";
import { resetMainCard } from "../../../redux/slices/memberShips/membersSlice"; // Import the resetMainCard action
import CustomDropdown from "../../Utilities/CustomDropdown";
import CustomSelectInput from "../../Utilities/CustomSelectInput";
import { useLocation } from "react-router-dom";

//addOneMemberShip
export default function MemberCardComponent({
  main_card,
  offers,
  selectedOffer,
  setSelectedOffer,
  index,
  setIteratorArr,
  id,
  requestCardsArr,
  setRequestCardsArr,
  sendFrom,
  setSendFrom,
  selectedCountry,
  setSelectedCountry,
  selectedCity,
  setSelectedCity,
  isLoading,
  hasAdd,
  hasEdit,
  // setEmptyFlag,
  // emptyFlag
}) {
  const canvasRef = useRef(null);

  const location = useLocation();
  const dispatch = useDispatch();
  // id-1000

  const [name, setName] = useState(() =>
    location?.state ? location?.state?.membershipCards[id - 1000]?.name : ""
  );
  const [mobile, setMobile] = useState(() =>
    location?.state ? location?.state?.membershipCards[id - 1000]?.mobile : ""
  );
  const [passNum, setPassNum] = useState(() =>
    location?.state
      ? location?.state?.membershipCards[id - 1000]?.identity_number_passport
      : ""
  );

  // console.log('name.................',name);

  //console.log('location?.state?.membershipCards[id-1000].name',location?.state?.membershipCards[id-1000].name);

  //  const [isLoading, setIsLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  //console.log('liquidationUsers',liquidationUsers);

  const { cities, countries } = useSelector((state) => state.citiesReducer);
  const { mainCard } = useSelector((state) => state.membersReducer);
  const all_data = useSelector((state) => state.membersReducer);
  const { offers: userOffers } = useSelector((state) => state.offersReducer);

  const firstRender = useRef(true);

  console.log("cities", cities);
  // id - 1000
  function updateFieldsValues() {
    let obj = {
      name: name,
      identity_number_passport: passNum,
      mobile: mobile,
      uniqueKey: id,
      serial_number: location?.state?.membershipCards[id - 1000]?.serial_number,
    };

    // if(isLoading==false) return;

    //عشان الكارت الرئيسي
    if (main_card) obj.main_card = true;
    // console.log('uniqueKey',id);
    console.log("obj", obj);
    // let new_Arr=requestCardsArr.push(obj);
    // console.log('new_Arr',new_Arr);
    // setRequestCardsArr(new_Arr);
    let prev = requestCardsArr.find((el) => el.uniqueKey == id);
    if (prev) {
      console.log("prev");
      setRequestCardsArr((p) =>
        p.filter((el) => (el.uniqueKey == id ? "" : p))
      );
    }

    setRequestCardsArr((p) => [...p, obj]);
  }

  useEffect(() => {
    // if(location?.state){

    // }
    // else{
    //   updateFieldsValues();
    // }
    //  if(firstRender) return;

    // console.log('updaaaaate');
    updateFieldsValues();
  }, [name, mobile, passNum]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    // if(prevSelectedOffer.current===selectedOffer?._id) return;

    console.log("selectedOffer use effect");
    if (isLoading == true) return;

    setName("");
    setMobile("");
    setPassNum("");
    // setEmptyFlag(false)
    // setRequestCardsArr([]);
  }, [selectedOffer]);

  console.log("selectedOffer", selectedOffer);

  // console.log("offers in card",userOffers);

  // console.log("countries", countries);
  // console.log("_______1_________", all_data);

  // console.log('selectedCity',selectedCity);
  //(e) => setSelectedCountry(e.target.value)

  const handleCountryChange = async (e) => {
    // console.log('ooooooooooooooooooooo');
    setSelectedCountry(e.target.value);
    await dispatch(getAllCities(e.target.value));
  };

  // const handleAddMemberCard = async () => {
  //   if (mobile.length !== 10) {
  //     // setMobileError(true);
  //     return notify("يجب أن يتكون رقم الجوال من 10 أرقام", "error");
  //   }

  //   if ((name || mobile || passNum || sendFrom) == "")
  //     return notify("من فضلك اكمل البيانات", "error");
  //   if (selectedCity == null || selectedCountry == null)
  //     return notify("من فضلك اكمل البيانات", "error");

  //   let offerToSend = [];
  //   if (user?.isAdmin) {
  //     offerToSend = selectedOffer;
  //   } else {
  //     //console.log('selectedoffers',selectedOffer);
  //     offerToSend = userOffers?.find((el) => el?.offer?._id == selectedOffer);
  //     //console.log('offersToSend',offersToSend);
  //     offerToSend = offerToSend?._id;
  //   }

  //   //return;

  //   let data = {
  //     user: user?._id,
  //     sent_by: sendFrom,
  //     offer: offerToSend,
  //     name: name,
  //     identity_number_passport: passNum,
  //     mobile: mobile,
  //     country: selectedCountry,
  //     city: selectedCity,
  //   };

  //   console.log("index", index);

  //   if (index != undefined) data.index = index;
  //   if (main_card) {
  //     data.main_card = true;
  //   } else {
  //     data.main_card = false;
  //     data.membershipId = mainCard?._id;
  //   }

  //   setIsLoading(true);
  //   await dispatch(addOneMemberShip({ data }));
  //   setIsLoading(false);
  // };

  // const handleDownload = () => {
  //   if ((name || mobile || passNum || sendFrom) == "")
  //     return notify("من فضلك اكمل البيانات", "error");
  //   if (selectedCity == null || selectedCountry == null)
  //     return notify("من فضلك اكمل البيانات", "error");
  //   if (isLoading == false && all_data.mainCard != null) {
  //     console.log("1", all_data);

  //     console.log("2", all_data.mainCard);
  //     console.log("3", all_data.mainCard.start_date);

  //     const canvas = canvasRef.current;
  //     canvas.width = 800; // Set canvas width
  //     canvas.height = 500; // Set canvas height
  //     const ctx = canvas.getContext("2d");

  //     // Clear the canvas to ensure it's empty
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     ctx.font = "bold 20px Cairo"; // Set font family and size
  //     ctx.fillStyle = "black"; // Change text color to black

  //     // Define positions for each text item
  //     const positions = {
  //       name: { left: 107, top: 302 },
  //       idNo: { left: 109, top: 359 },
  //       date: { left: 172, top: 409 },
  //       membershipNo: { right: 85, top: 359 },
  //       expDate: { right: 153, top: 409 },
  //     };

  //     // Draw left-aligned text
  //     ctx.textAlign = "left";
  //     ctx.fillText(`${name}`, positions.name.left, positions.name.top);
  //     ctx.fillText(`${passNum}`, positions.idNo.left, positions.idNo.top);
  //     ctx.fillText(
  //       `${all_data.mainCard.start_date.split("T")[0]}`,
  //       positions.date.left,
  //       positions.date.top
  //     );

  //     // Draw right-aligned text
  //     ctx.textAlign = "right";
  //     ctx.fillText(
  //       `${all_data.mainCard.membership_number}`,
  //       canvas.width - positions.membershipNo.right,
  //       positions.membershipNo.top
  //     );
  //     ctx.fillText(
  //       `${all_data.mainCard.end_date.split("T")[0]}`,
  //       canvas.width - positions.expDate.right,
  //       positions.expDate.top
  //     );

  //     const link = document.createElement("a");
  //     link.download = "card.png";
  //     link.href = canvas.toDataURL("image/png");
  //     link.click();
  //   }
  // };

  // const handlePrint = () => {
  //   if ((name || mobile || passNum || sendFrom) == "")
  //     return notify("من فضلك اكمل البيانات", "error");
  //   if (selectedCity == null || selectedCountry == null)
  //     return notify("من فضلك اكمل البيانات", "error");
  //   if (isLoading == false && all_data.mainCard != null) {
  //     console.log("1", all_data);

  //     console.log("2", all_data.mainCard);
  //     console.log("3", all_data.mainCard.start_date);

  //     const printWindow = window.open("", "_blank");

  //     const canvas = document.createElement("canvas");
  //     canvas.width = 800; // Set canvas width
  //     canvas.height = 500; // Set canvas height
  //     const ctx = canvas.getContext("2d");

  //     // Clear the canvas to ensure it's empty and transparent
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     ctx.font = "bold 20px Cairo"; // Set font family and size
  //     ctx.fillStyle = "black"; // Change text color to black

  //     // Define positions for each text item
  //     const positions = {
  //       name: { left: 107, top: 302 },
  //       idNo: { left: 109, top: 359 },
  //       date: { left: 172, top: 409 },
  //       membershipNo: { right: 85, top: 359 },
  //       expDate: { right: 153, top: 409 },
  //     };

  //     // Draw left-aligned text
  //     ctx.textAlign = "left";
  //     ctx.fillText(`${name}`, positions.name.left, positions.name.top);
  //     ctx.fillText(`${passNum}`, positions.idNo.left, positions.idNo.top);
  //     ctx.fillText(
  //       `${all_data.mainCard.start_date.split("T")[0]}`,
  //       positions.date.left,
  //       positions.date.top
  //     );

  //     // Draw right-aligned text
  //     ctx.textAlign = "right";
  //     ctx.fillText(
  //       `${all_data.mainCard.membership_number}`,
  //       canvas.width - positions.membershipNo.right,
  //       positions.membershipNo.top
  //     );
  //     ctx.fillText(
  //       `${all_data.mainCard.end_date.split("T")[0]}`,
  //       canvas.width - positions.expDate.right,
  //       positions.expDate.top
  //     );

  //     // Add the canvas element to the print window or iframe
  //     printWindow.document.body.appendChild(canvas);

  //     // Print the window or iframe
  //     printWindow.print();
  //   }
  // };
  const oneUser = useSelector((state) => state.permissionsReducer.userScreens);

  console.log("sendFrom", sendFrom);

  const { liquidationUsers } = useSelector((state) => state.usersReducer);
  const oneUser2 = useSelector((state) => state.usersReducer.oneUser);

  console.log("oneUser", oneUser);

  let sendFromUsers ;

  console.log("liquidationUsers",liquidationUsers)

  if (user?.isAdmin) sendFromUsers = liquidationUsers;
  else sendFromUsers = oneUser2?.supervisors_list;
  //console.log("selectedOffer", selectedOffer);

  console.log("sendFromUsers", sendFromUsers);

  //--------------------------------------------
  //  let isDisabled=false;

  //   if(hasEdit==true) isDisabled= false;
  //   if(hasEdit==false) isDisabled=true;

  //--------------------------------------------
  console.log("oneUsercountry", oneUser?.country?.nameAr);

  return (
    <div
      className="firstContainer sacondBackground my-3"
      style={{
        padding: "20px",
        boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: "8px",
        // margin:"20px"
      }}
    >
      <>
        {isLoading && <Spinner />}

        {main_card && (
          <div
            className="my-3"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-flex label p-0 mx-auto">نوع العرض</div>
            <Select3
              name={"offer"}
              classnam={"inputsReduisAndPadding2"}
              onChange={(e) =>
                setSelectedOffer(() => {
                  if (e.target.value == 0) {
                    setIteratorArr([]);
                    return;
                  }
                  const offer = offers?.find((el) => el._id == e.target.value);

                  // console.log('offer',offer);

                  let arr = [];
                  for (let i = 0; i < offer.numberOfCards; i++) {
                    arr.push(i);
                  }
                  setIteratorArr([...arr]);
                  return offer;
                })
              }
              // onChange={(e) => {
              //   let isFound = user?.offers_banned.includes(e.target.value);
              //   console.log("isFound", isFound);
              //   setSelectedOffer(e.target.value);
              // }}
              value={selectedOffer?._id ? selectedOffer?._id : selectedOffer}
              options={offers}
              width={"100%"}
              labelSelect="offerNameAr"
              defaultOption="من فضلك قم ب اختيار البطاقة"
              // border={"1px solid rgba(132, 203, 0, 1)"}
              disabled={location?.state?._id ? true : false}
            />
          </div>
        )}

        {
          <div className="label d-flex justify-content-center">
            <label className="">البطاقة {id - 999}</label>
          </div>
        }
      </>

      <div
        className="inputContainer2"
        style={{
          display: "flex",
          // flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={"inputResponiveWidth"}>
          {" "}
          <Input
            value={name}
            setValue={setName}
            name={"name"}
            label={"اسم صاحب الطلب"}
            classnam={"inputsReduisAndPadding2"}
            // disabled={isDisabled}
          />
        </div>
        <div className={"inputResponiveWidth"}>
          <Input
            type="number"
            value={passNum}
            setValue={setPassNum}
            name={"identity_number_passport"}
            label={"رقم الهوية او الجواز"}
            classnam={"inputsReduisAndPadding2"}
            // disabled={isDisabled}
          />
        </div>
      </div>

      <div
        className="inputContainer2"
        style={{
          display: "flex",
          // flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={"inputResponiveWidth"}>
          <Input
            type="number"
            value={mobile}
            setValue={setMobile}
            name={"mobile"}
            label={"رقم الجوال"}
            classnam={"inputsReduisAndPadding2"}
            // disabled={isDisabled}

            // width={"42%"}
            // error={mobileError}
          />
        </div>
        {main_card && (
          <div className={"inputResponiveWidth"}>
            {/* <Select
              value={sendFrom}
              onChange={(e) => setSendFrom(e.target.value)}
              label={"مرسلة من"}
              options={sendFromUsers}
              defaultOption={"مرسلة من"}
              labelSelect="fullname"
              classnam={"inputsReduisAndPadding2"}

              // width={"42%"}
            /> */}
            {/* <CustomDropdown
              label={"مرسلة من"}
              value={sendFrom}
              onChange={(value) => setSendFrom(value)}
              options={sendFromUsers}
              placeholder="مرسلة من"
              labelKey="fullname"
              valueKey="_id"
              classnam={"inputsReduisAndPadding2"}
              width={"42%"}
            /> */}
            {
              // <CustomSelectInput
              //   options={sendFromUsers}
              //   value={sendFrom}
              //   onChange={setSendFrom}
              //   placeholder="مرسلة من"
              //   label="مرسلة من"
              //   labelKey="fullname"
              //   valueKey="_id"
              //   // disabled={isDisabled}
              // />
            }

            <Input
              type="text"
              value={sendFrom}
              setValue={setSendFrom}
              label={"مرسلة من"}
              classnam={"inputsReduisAndPadding2"}
            />
          </div>
        )}
      </div>

      {main_card && (
        <div
          className=" my-3"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "right",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <span className="d-flex label justify-content-end align-items-end p-0 mx-auto">
            {""}
            &nbsp;&nbsp;&nbsp;&nbsp; البلد
            {""}
          </span>
          {user?.isAdmin && (
            <Select3
              name={"country"}
              onChange={handleCountryChange}
              options={countries}
              value={
                selectedCountry?._id ? selectedCountry?._id : selectedCountry
              }
              width={"100%"}
              labelSelect="nameAr"
              defaultOption="من فضلك قم ب اختيار البلد"
              // border={"1px solid rgba(132, 203, 0, 1)"}
              classnam={"inputsReduisAndPadding2"}
            />
          )}
          {!user?.isAdmin && (
            <Select3
              name={"country"}
              onChange={handleCountryChange}
              value={
                selectedCountry?._id ? selectedCountry?._id : selectedCountry
              }
              options={countries}
              width={"100%"}
              defaultOption={oneUser?.country?.nameAr}
              labelSelect="nameAr"
              // defaultOption="من فضلك قم ب اختيار البلد"
              // border={"1px solid rgba(132, 203, 0, 1)"}
              classnam={"inputsReduisAndPadding2"}
              // disabled={isDisabled}
              disabled
            />
          )}
        </div>
      )}

      {main_card && (
        <div
          className="my-3"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "right",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div className="d-flex label justify-content-end align-items-end p-0 mx-auto">
            {" "}
            المدينة{" "}
          </div>

          <Select3
            name={"city"}
            onChange={(e) => setSelectedCity(e.target.value)}
            value={selectedCity?._id ? selectedCity?._id : selectedCity}
            options={cities}
            width={"100%"}
            labelSelect="nameAr"
            defaultOption="من فضلك قم ب اختيار المدينة"
            // border={"1px solid rgba(132, 203, 0, 1)"}
            classnam={"inputsReduisAndPadding2"}
            optionsHeight={"100px"}
            // disabled={isDisabled}
          />
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: "none" }} />

      {/* <div className="handelBtnsResponsive">
        <BtnGroupComponent
          handleAdd={handleAddMemberCard}
          handle_download={handleDownload}
          handle_print={handlePrint}
        />
      </div> */}
    </div>
  );
}
