import SideBar from "../../Components/Utilities/SideBar";
import MemberDetailsComponent from "../../Components/members/MemberDetails/MemberDetailsComponent";

export default function MemberDetailsPage() {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-3 w-100 dashboard">
                <MemberDetailsComponent />
            </div>
        </div>
    )
}
