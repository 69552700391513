import { Button, Modal, Spinner } from "react-bootstrap";
import Select4 from "../Utilities/Select4";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCities,
  getAllCountries,
  resetCities,
} from "../../redux/slices/cities/thunk";
import {
  getUsersByCountry,
  allSupervisorsByCountry,
  resetUsers,
} from "../../redux/slices/users/thunk";
import notify from "../../hook/useNotification";
import CustomDropdown from "../Utilities/CustomDropdown";

export default function TransferRequestModal({
  show,
  setShow,
  selectedOrder,
  updateRequest,
}) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  console.log("selectedOrder",selectedOrder?.user?.id)

  const oldUserId = selectedOrder?.user?.id;

  const dispatch = useDispatch();
  //const[searchParams,setSearchParams]=useSearchParams();

  useEffect(() => {
    //console.log('cccccccccccc');
    dispatch(resetUsers());
    dispatch(resetCities());
    dispatch(getAllCountries());
  }, [dispatch]);

  const handleSupervisorChange = (selectedOption) => {
    setSelectedSupervisor(selectedOption);
  };

  const handleChange = (selectedOption) => {
    setSelectedUser(selectedOption);  // Correct usage
  };

  const users = useSelector((state) => state.usersReducer.usersByCountry);
  const { supervisors } = useSelector((state) => state.usersReducer);
  console.log("users", users);
  const { cities, countries } = useSelector((state) => state.citiesReducer);

  console.log("supervisors", supervisors);

  const handleCountryChange = async (e) => {
    setSelectedCountry(e.target.value);
    setIsLoading(true);
    await dispatch(getUsersByCountry(e.target.value));
    await dispatch(allSupervisorsByCountry(e.target.value));
    await dispatch(getAllCities(e.target.value));
    setIsLoading(false);
  };

  // const handleCityChange=async(e)=>{
  //      setSelectedCity(e.target.value);
  //      setIsLoading(true);
  //     await dispatch(getUsersByCity(e.target.value));

  //     setIsLoading(false);
  // }

  // const updateRequest
  //console.log('selectedUser', selectedUser);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        {isLoading && <Spinner />}
        <h5 className="h5ModalTitle"> تحويل الطلب </h5>
        <div className="inputContainer2 justify-content-between my-2">
          <Select4
            name={"country"}
            onChange={handleCountryChange}
            value={selectedCountry?._id}
            options={countries}
            width={"100%"}
            label={"البلد"}
            labelSelect="nameAr"
            defaultOption="من فضلك قم ب اختيار البلد"
            height="40px"
          />
        </div>

        {/* <div className="inputContainer2 justify-content-between my-2">

                <Select
                    name={'city'}
                    onChange={handleCityChange}
                    value={selectedCity?._id}
                    options={cities}
                    width={'100%'}
                    label={'المدينة'}
                    labelSelect='nameAr'
                    defaultOption='من فضلك قم ب اختيار المدينة'
                />
            </div> */}

        <div className="inputContainer2 justify-content-between my-2">


<CustomDropdown
            label="اسم المندوب"
            options={users}
            value={selectedUser}
            onChange={handleChange}
            placeholder="اختر اسم المندوب"
            labelKey="fullname"
            valueKey="_id"
            width={'100%'}
            disabled={selectedSupervisor}
            
          />
        </div>

        <div
          className="inputContainer2 justify-content-between my-2"
          style={{ width: "100%" }}
        >
          <CustomDropdown
            label="اسم المشرف"
            options={supervisors}
            value={selectedSupervisor}
            onChange={handleSupervisorChange}
            placeholder="اختر اسم المشرف"
            labelKey="fullname"
            valueKey="_id"
            width={'100%'}
            disabled={selectedUser}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100">
          <Button
            variant="primary"
            className="mx-auto d-flex"
            style={{ background: "rgb(0, 128, 251)" }}
            onClick={() => {
              if (selectedUser == null)
                return notify("من فضلك قم ب اختيار المندوب", "error");

              const data = {
                user: selectedUser,
                user_take_comission: selectedUser,
                user_transfered_request: oldUserId,
                isTransfered: true,
                admin_request: false,
              };
              setIsSubmitLoading(true);
              updateRequest({
                id: selectedOrder?._id,
                data,
              });
              setIsSubmitLoading(false);
            }}
          >
            {isSubmitLoading && <Spinner />}
            تحويل
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
