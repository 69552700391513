import { Navigate } from "react-router-dom";
import MedicalSystemComponent from "../../Components/Medical-System/MedicalSystemComponent";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import SideBar from "../../Components/Utilities/SideBar";

export default function MedicalSystemPage() {
  const screenID="665f0e6070673dba321d1603";
  const loggedUser = JSON.parse(localStorage.getItem('user'));

    let access = false;
    if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
    else access=true;

    if (access == false) return <Navigate to='/setting/profile?section=Profile' />
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard">
        <MedicalSystemComponent />
      </div>

    </div>
  )
}
