import React, { useState } from 'react';
import { MdOutlineContentCopy } from "react-icons/md";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  EmailIcon
} from 'react-share';

const ShareButtons = ({ url }) => {
  const [tooltipText, setTooltipText] = useState('Copy to clipboard');
  const [showCopyMessage, setShowCopyMessage] = useState(false);


  const handleCopyLink = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        setTooltipText('تم النسخ في الحافظه');
        setShowCopyMessage(true);
        setTimeout(() => {
          setShowCopyMessage(false);
        }, 2000); // Hide message after 2 seconds
      },
      (err) => {
        console.error('Failed to copy link:', err);
        setTooltipText('فشل النسخ');
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
      <FacebookShareButton url={url} >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={url} >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} >
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <LinkedinShareButton url={url} >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <RedditShareButton url={url} >
        <RedditIcon size={32} round={true} />
      </RedditShareButton>
      <TelegramShareButton url={url} >
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
      <EmailShareButton url={url} body={url}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
      <MdOutlineContentCopy
        size={32}
        onClick={handleCopyLink}
        style={{ cursor: 'pointer', color: '#000', marginLeft: '10px' }}
        title="Copy link"
      />
      {showCopyMessage && (
        <div
          style={{
            position: 'absolute',
            top: '-30px',
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            zIndex: '1000',
            whiteSpace: 'nowrap'
          }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default ShareButtons;
